import styled, { css, keyframes } from "styled-components"

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0; 
  }
`

const ShimmerLine = styled.div<{ $panel?: boolean }>`
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;

  background: linear-gradient(
    to right,
    rgba(200, 200, 200, 0.1) 0%,
    rgba(180, 180, 180, 0.3) 50%,
    rgba(200, 200, 200, 0.1) 100%
  );
  background-size: 1000px 100%;
  position: relative;

  ${({ $panel }) =>
    $panel &&
    css`
       {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 20px;
        width: 320px;
        height: 450px;
        border-radius: 6px;
        box-sizing: border-box;
      }
    `}
`

export default ShimmerLine
