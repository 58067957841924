import React from 'react';
import './ExtensionPlaceholder.css'; // Make sure to create this CSS file

interface ExtensionPlaceholderProps {
    className?: string;
}

const ExtensionPlaceholder: React.FC<ExtensionPlaceholderProps> = ({ className }) => {
    return (
        <button className={`extensionPlaceholder ${className ?? ''}`.trim()}>
            <span>+</span>
        </button>
    );
}

export default ExtensionPlaceholder;
