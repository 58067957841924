import { Flex } from "@radix-ui/themes"
import DonateButton from "components/button/viewersupport/donate/DonateButton"
import FollowButton from "components/button/viewersupport/follow/FollowButton"
import { useStreamerPageProfile } from "components/contexts/StreamerProfileContext"
import { useToken } from "components/contexts/TokenContext"
import { useUserProfile } from "components/contexts/UserProfileContext"

function FollowButtonBox() {
  const { token } = useToken()
  const { profile } = useUserProfile()
  const { streamerProfile } = useStreamerPageProfile()

  const donateUrl = streamerProfile?.settings?.donateButton

  return (
    <Flex justify="end" gap="2">
      {token && profile && <FollowButton />}
      {donateUrl && <DonateButton url={donateUrl} />}
      {/* <SubscribeButton /> */}
    </Flex>
  )
}

export default FollowButtonBox
