import { Box, Text } from "@radix-ui/themes"
import { useChatBox } from "components/contexts/ChatBoxContext"
import { useUserProfile } from "components/contexts/UserProfileContext"
import { ForwardedRef, forwardRef } from "react"
import styled, { css } from "styled-components"
import ChatBadge from "./ChatBadge"
import ChatMessage from "./ChatMessage"
import ChatMessageModel from "./ChatMessageModel"
import ChatUsername from "./ChatUsername"

interface ChatLineProps {
  message: ChatMessageModel
  isEmbedded?: boolean
}

// TODO - do we need to memo this since we're using chatboxcontext?
// re: probably no, won't really matter anyway since this is a simple
// component that doesn't change much on its own
// if any, the chatboxcontext should be properly optimized instead
function ChatLine(
  { message, isEmbedded = false }: ChatLineProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { profile } = useUserProfile()
  const { selectUser } = useChatBox()

  const isMentioned = Boolean(
    profile?.chatrpg_username &&
      message.data.message
        .toLowerCase()
        .includes(profile.chatrpg_username.toLowerCase())
  )

  const handleClick = () => {
    const { data } = message

    console.log("HERE IS OUR DATA " + JSON.stringify(data));
    if (data.user) {
      selectUser(data.user, data.twitch_id, message.type, data.chatrpgId) // Pass username, userId, and platform
    }
  }

  const finalUsernameColor = message.data.color || "#ff0000"

  return (
    <Wrapper
      px="2"
      py="1"
      $mentioned={isMentioned}
      ref={ref}
      asChild
      style={{
        background: isEmbedded
          ? "transparent" // Make background transparent if in embedded mode
          : isMentioned
          ? undefined
          : message.options.background, // Regular background handling if not mentioned
      }}
    >
      <Text>
        <ChatBadge platform={message.type} badges={message.data.badges} />

        {message.data.user && (
          <span onClick={handleClick} style={{ cursor: "pointer" }}>
            <ChatUsername
              username={message.data.user}
              color={finalUsernameColor}
            />
          </span>
        )}

        <ChatMessage
          message={message.data.message}
          displayStyle={message.type === "System" ? "system" : "regular"}
        />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled(Box)<{ $mentioned: boolean }>`
  line-height: 1.25;

  ${({ $mentioned }) =>
    $mentioned &&
    css`
      background-color: rgba(225, 50, 50, 0.2);
    `}

  & > span {
    vertical-align: middle;
  }
`

export default forwardRef(ChatLine)
