// src/components/LoadingSpinner.tsx
import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner: React.FC = () => {
    return (
        <div className="loading-spinner-overlay">
            <div className="chat-bubble" id="bubble1"></div>
            <div className="chat-bubble" id="bubble2"></div>
            <div className="chat-bubble" id="bubble3"></div>
        </div>
    );
};

export default LoadingSpinner;
