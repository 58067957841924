import { Flex, Grid, Heading, Text, TextField } from "@radix-ui/themes"
import { useEffect, useState } from "react"
import { IoSadOutline } from "react-icons/io5"
import { RxMagnifyingGlass } from "react-icons/rx"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { setUserStreamerProfile } from "../../../../../../store/reducers/userStreamerProfileReducer"
import { useToken } from "../../../../../contexts/TokenContext"
import { installExtension } from "../../../../../extensions/api/Extensions"
import { fetchPopularExtensions } from "../api/fetchPopularExtensions"
import ExtensionCard, { ExtensionCardButton } from "../components/ExtensionCard"

import "./ExtensionStore.css"

interface StoreExtension {
  title: string
  author: string
  description: string
  clientId: string
}

const ExtensionStore = () => {
  const dispatch = useDispatch()

  const { token } = useToken()

  const [extensions, setExtensions] = useState<StoreExtension[] | undefined>([])

  useEffect(() => {
    fetchPopularExtensions(token, dispatch, setExtensions)
  }, [token, dispatch]) // Depend on token and dispatch

  const handleActivate = async (clientId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      const updatedUserStreamerProfile = await installExtension(clientId, token)

      dispatch(setUserStreamerProfile(updatedUserStreamerProfile))

      setExtensions((prev) => {
        const updated = prev?.filter(
          (extension) => extension.clientId !== clientId
        )

        return updated?.length === 0 ? undefined : updated
      })
    } catch (error) {
      console.error(error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <Wrapper>
      <Flex justify="between">
        <Heading as="h1" mb="5">
          Extension Store
        </Heading>

        <TextField.Root size="3" placeholder="Search extensions...">
          <TextField.Slot>
            <RxMagnifyingGlass height={16} width={16} />
          </TextField.Slot>
        </TextField.Root>
      </Flex>

      <div>
        <Heading as="h2" weight="medium" size="4">
          Popular Extensions
        </Heading>

        {!extensions ? (
          <Flex p="4" align="center" justify="center" gap="1" minHeight="80vh">
            <Text color="gray">No extensions found</Text>{" "}
            <IoSadOutline size="20" />
          </Flex>
        ) : (
          <Grid
            columns="repeat(auto-fill, minmax(16rem, 1fr))"
            rows="repeat(auto-fill, minmax(24rem, 1fr))"
            gap="3"
            // justify="start"
          >
            {extensions.map(({ clientId, author, title, description }) => (
              <ExtensionCard
                key={clientId}
                imageUrl="https://images.unsplash.com/photo-1617050318658-a9a3175e34cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&q=80"
                author={author}
                name={title}
                description={description}
                featured
                actionButtons={
                  <ExtensionCardButton
                    size="2"
                    color="grass"
                    onClick={() => handleActivate(clientId)}
                  >
                    Activate
                  </ExtensionCardButton>
                }
              />
            ))}
          </Grid>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* overflow-y: scroll; */
`

export default ExtensionStore
