import React, { useState } from 'react';
import './OverlayContainer.css';
import AddImageTextPanel from './AddImageTextPanel';

const OverlayContainer: React.FC = () => {

    const [showAddPanel, setShowAddPanel] = useState(false);

    const handleAddPanelClick = () => {
        setShowAddPanel(true);
    };

    return (
        <div className="overlay-container">
            {!showAddPanel && (
                <button className="overlay-top-button" onClick={handleAddPanelClick}>
                    Add a Text or Image
                </button>
            )}
            {showAddPanel && <AddImageTextPanel />}
        </div>
    );
};

export default OverlayContainer;
