import { Box, Button, Flex, Grid, Heading, Text } from "@radix-ui/themes"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { SERVER_URL } from "../../../../../env"
import { RootState } from "../../../../../store"
import {
  UserStreamerProfile,
  clearUserStreamerProfile,
} from "../../../../../store/reducers/userStreamerProfileReducer"
import { authHandler } from "../../../../auth/login/AuthHandler"
import PLATFORMS, { PlatformKey } from "../../../../config/platformConfig"
import { useToken } from "../../../../contexts/TokenContext"
import { useUserProfile } from "../../../../contexts/UserProfileContext"
import AlertDialogButton from "../../../../ui/AlertDialogButton"
import LinkablePlatform from "../../LinkablePlatform"
import { LinkContainer } from "../usersettings/accountlinking/AccountLinkingOptionsStyles"
import { useEffect, useState } from "react"

const streamerScopes = [
  'user_read', 'channel:bot', 'chat:edit', 'chat:read', 'channel:moderate',
  'moderator:manage:banned_users', 'moderator:read:chatters', 'channel:manage:vips',
  'channel:manage:moderators', 'channel:manage:redemptions', 'channel:read:redemptions',
  'channel:read:subscriptions', 'channel:read:vips', 'bits:read', 'channel:read:subscriptions', 'user:read:chat', 'channel:manage:broadcast', 'moderator:read:followers', 'channel:read:ads'
];


const linkablePlatforms = Object.entries(PLATFORMS).filter(
  ([_, details]) =>
    details.fakeLink || details.trueLink || details.streamerFakeLink
)

// TODO: Refactor
function StreamerAccountLinkingOptions() {
  const location = useLocation()
  const dispatch = useDispatch()

  const { token } = useToken()
  const { profile, setProfile } = useUserProfile()

  const streamerProfile = useSelector(
    (state: RootState) => state.userStreamerProfile
  )

  const isLinked = (platform: string) => {
    if (!streamerProfile) return false

    switch (platform) {
      case "Twitch":
        return !!streamerProfile.elevatedTwitch

      case "Youtube":
        return !!streamerProfile.youtubeChannelId

      case "Streamlabs":
        return !!profile?.linkedAccounts?.[platform];

      case "Streamelements":
        return !!profile?.linkedAccounts?.[platform];

      default:
        return !!streamerProfile[
          `${platform.toLowerCase()}_username` as keyof UserStreamerProfile
        ]
    }
  }

  const handleDeleteStreamerAccount = () => {
    if (!profile?.isStreamer) return

    dispatch({ type: "START_LOADING" })
    axios
      .post(`${SERVER_URL}/streamer/leave`, undefined, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Set streamer to null in our cache
        // Then remove from localstorage
        dispatch(clearUserStreamerProfile()) // This will set the streamer profile in Redux to null - if it didn't well then do it lol

        console.log("Streamer account successfully deleted.")
        // alert("Streamer account successfully deleted.")

        if (response.data?.existingUser) {
          setProfile(response.data.existingUser)
        }

        // CHANGE OUR MENU BACK TO ACCOUNT LINKING DEFUALT AREA - since we can't see this panel anymore.
        // Re: not possible for now, so reloading it instead.
        window.location.href = "/"
      })
      .catch((error) => {
        console.error(
          "There was a problem deleting the streamer account:",
          error
        )
      })
      .finally(() => dispatch({ type: "STOP_LOADING" }))
  }

  const getNonOauthLinkedValue = (platform: PlatformKey) => {
    if (!streamerProfile) return ""

    switch (platform) {
      case "Youtube":
        return streamerProfile.youtubeChannelId

      default:
        // FIXME
        return streamerProfile[
          `${platform.toLowerCase()}_username` as keyof UserStreamerProfile
        ] as string
    }
  }

  const getButtonLabel = (platform: PlatformKey) => {
    const text = isLinked(platform)
      ? `${platform} Streamer  is Linked!`
      : `Link your Streamer ${platform}`

    return (
      <Text
        size={{ initial: "2", sm: "3" }}
        weight="light"
        style={{ color: "white" }}
      >
        {text}
      </Text>
    )
  }

  const getInputPlaceholder = (platform: PlatformKey) => {
    switch (platform) {
      case "Youtube":
        return `Enter your ${platform} channel ID (e.g. UCiNe_gwQ...)`

      default:
        return `Enter your ${platform} username`
    }
  }

  const [missingScopes, setMissingScopes] = useState<string[]>([]);

  const checkMissingScopes = () => {
    if (profile?.isStreamer && Array.isArray(profile.twitchScopes)) {
      console.log("should be checking missing scopes");
      const missing = streamerScopes.filter(scope => !profile.twitchScopes.includes(scope));
      console.log(missing);
      setMissingScopes(missing); // Update state with missing scopes
    } else {
      setMissingScopes([]); // Default to an empty array if something is missing or not initialized
    }
  };

  useEffect(() => {
    checkMissingScopes();
    console.log("These are the missing scopes: " + missingScopes?.join(", "));
  }, [profile]);

  return (
    <Wrapper>
      <LinkContainer size="3">
        {missingScopes.length > 0 && (
          <Box mb="4" style={{ backgroundColor: 'red', padding: '10px', color: 'white', textAlign: 'center' }}>
            <Text size="2" weight="bold">Your Twitch Account is NOT up to date! Please relink your Twitch account below to access all features.</Text>
          </Box>
        )}

        <Box mb={{ initial: "4", md: "5" }}>
          <Heading as="h1" size="5" weight="medium" mb="2">
            Linked Streamer Accounts
          </Heading>

          <Text size="2" color="gray">
            Link your account with extended scopes to access more streamer
            features.
          </Text>
        </Box>

        <Grid columns={{ initial: "1", lg: "2" }} gap="4">
          {linkablePlatforms.map(([platform, details]) => (
            <LinkablePlatform
              key={platform}
              streamer
              platform={platform}
              details={details}
              isLinked={isLinked(platform)}
              value={getNonOauthLinkedValue(platform)}
              buttonLabel={getButtonLabel(platform)}
              inputPlaceholder={getInputPlaceholder(platform)}
              onOauthLink={() => {
                authHandler(platform, "streamer", token, location.pathname)
              }}
            />
          ))}
        </Grid>

        <Flex justify="center" mt={{ initial: "5", md: "6" }}>
          <AlertDialogButton
            title="Delete Streamer Account"
            description="Are you sure you want to permanently delete your streamer account?"
            warning="This action cannot be undone. It will delete ALL YOUR EXTENSIONS AND INFO! Remember your viewers!"
            trigger={<Button color="red">Delete Streamer Account</Button>}
            onConfirm={handleDeleteStreamerAccount}
          />
        </Flex>
      </LinkContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: white;
  display: flex;
  justify-content: center;
`

export default StreamerAccountLinkingOptions
