import { Flex } from "@radix-ui/themes"
import { PropsWithChildren } from "react"
import { styled } from "styled-components"
import { useMobileContext } from "../contexts/MobileContext"
import useDragLogic from "./drag/useDragLogic"

function convertRemToPixels(value: string | number) {
  const rem =
    typeof value === "number"
      ? value
      : Number.parseInt(value.replace("rem", ""), 10)

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

const css = getComputedStyle(document.body)

function ContentAreaContainer({ children }: PropsWithChildren) {
  const sidebarWidth = css.getPropertyValue("--sidebar-width")

  const { isMobile } = useMobileContext()
  const { dragPosition, handleDragStart, handleDragMove, handleDragEnd } =
    useDragLogic({
      maxDelta: convertRemToPixels(sidebarWidth),
      disable: !isMobile,
    })

  return (
    <Wrapper
      direction="column"
      flexGrow="1"
      style={{ left: isMobile ? `${dragPosition}px` : "0" }}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
      onMouseMove={handleDragMove}
      onTouchMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onTouchEnd={handleDragEnd}
      onMouseLeave={handleDragEnd}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: left 0.3s;
  }
`

export default ContentAreaContainer
