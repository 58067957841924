import { AspectRatio, Button, Flex, ScrollArea } from "@radix-ui/themes"
import { useEffect, useState } from "react"
import { RxEnterFullScreen, RxExitFullScreen, RxSquare } from "react-icons/rx"
import { useDispatch, useSelector } from "react-redux"
import { styled } from "styled-components"
import { RootState } from "../../../store"
import { toggleFullscreenMode } from "../../../store/reducers/fullScreenReducer"
import { toggleTheatreMode } from "../../../store/reducers/theatreModeReducer"
import { selectShowOverlay } from "../../../store/slices/EmbeddedPlayer"
import { useUserProfile } from "../../contexts/UserProfileContext"
import CustomPanel from "./custompanel/CustomPanel"
import InfoBox from "./description/InfoBox"
import ShimmerPlayer from "./embed/ShimmerPlayer"

function PlayerArea() {
  const dispatch = useDispatch()

  const { profile } = useUserProfile()

  const isTheatreMode = useSelector(
    ({ theatreMode }: RootState) => theatreMode.isTheatreMode
  )
  const isFullscreenMode = useSelector(
    ({ fullscreenMode }: RootState) => fullscreenMode.isFullscreenMode
  )

  const overlayShown = useSelector(selectShowOverlay)

  const getAspectRatio = () => {
    const playerWrapper = document.querySelector<HTMLElement>("#player")

    if (!playerWrapper) return 1

    let width = playerWrapper.offsetWidth
    let height = (width / 16) * 9

    const maxHeight = window.innerHeight - 215
    if (height > maxHeight) {
      height = maxHeight
    }

    const aspectRatio = width / height

    return aspectRatio
  }

  const shouldShowEmbed = !profile?.settings?.disableEmbeds

  const [aspectRatio, setAspectRatio] = useState(getAspectRatio)

  useEffect(() => {
    function handleResize() {
      setAspectRatio(getAspectRatio())
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const handleFullscreenToggle = () => {
    const playerWrapper = document.querySelector<HTMLElement>("#player")

    if (!playerWrapper) return

    let promise
    if (!document.fullscreenElement) {
      promise = playerWrapper.requestFullscreen()
    } else {
      promise = document.exitFullscreen()
    }

    promise.then(() => dispatch(toggleFullscreenMode()))
  }

  // TODO: Theatre and Fullscreen mode should never unmount other comps!
  return (
    <Wrapper m={isTheatreMode || isFullscreenMode ? "0" : "4"} mb="0">
      <Flex
        id="player"
        position="relative"
        // direction="column"
        // align="center"
        justify="center"
        height={isTheatreMode || isFullscreenMode ? "100%" : undefined}
      >
        <AspectRatio ratio={aspectRatio}>
          <ShimmerPlayer />
        </AspectRatio>

        <EmbedControllers justify="center" gapX="3" $hide={overlayShown}>
          <CustomButton onClick={() => dispatch(toggleTheatreMode())}>
            {isTheatreMode ? <ExitTheatreIcon /> : <RxSquare size={24} />}
          </CustomButton>

          <CustomButton onClick={handleFullscreenToggle}>
            {isFullscreenMode ? (
              <RxExitFullScreen size={24} />
            ) : (
              <RxEnterFullScreen size={24} />
            )}
          </CustomButton>
        </EmbedControllers>

        {/* {shouldShowEmbed && (
          <EmbedControllers bottom="4" $hide={overlayShown}>
            <EmbedPlatformSelector />
          </EmbedControllers>
        )} */}
      </Flex>

      {!isTheatreMode && !isFullscreenMode && (
        <>
          <InfoBox />
          <CustomPanel />
        </>
      )}
    </Wrapper>
  )
}

// const EmbedControllers = styled(Flex)<{ $hide?: boolean }>`
//   position: absolute;
//   transform: translateX(-50%);
//   left: 50%;
//   z-index: 99;
//   opacity: 0 ${({ $hide }) => ($hide ? "!important" : "")};
//   transition: opacity 0.3s ease, transform 0.3s ease;
// `

const EmbedControllers = styled(Flex)<{ $hide?: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: ${({ $hide }) => ($hide ? "-1" : "99")};
  opacity: 0 ${({ $hide }) => ($hide ? "!important" : "")}; /* Force hidden when $hide is true */
  transition: opacity 0.3s ease, transform 0.3s ease;
`

const CustomButton = styled(Button)`
  background-color: var(--panel-default-color); /* Use panel default color */
  /* border: 1px solid white; */
  color: white; /* White text */
  &:hover {
    opacity: 1;
  }
`

const Wrapper = styled(ScrollArea)`
  /* TODO: Why do we need this again? */
  height: unset;

  & #player:hover ${EmbedControllers} {
    opacity: 1;
  }

  .rt-ScrollAreaScrollbar {
    visibility: hidden;
  }
`
const ExitTheatreIcon = styled.div`
  width: 24px;
  height: 12px;
  border: 2px solid white;
  /* background-color: transparent; */
  border-radius: 4px;
  display: inline-block;
  color: inherit;
`

export default PlayerArea
