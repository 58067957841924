import { Skeleton } from "@radix-ui/themes"
import { useStreamerPageProfile } from "../../../contexts/StreamerProfileContext"
import EmbeddedPlayer from "./EmbeddedPlayer"

function ShimmerPlayer() {
  const { status, activePlayer } = useStreamerPageProfile()

  if (status === "loading") {
    return <Skeleton height="100%" />
  }

  if (activePlayer.platform && activePlayer.url) {
    return (
      <EmbeddedPlayer platform={activePlayer.platform} url={activePlayer.url} />
    )
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      No embedded player available.
    </div>
  )
}

export default ShimmerPlayer
