import { PiLayout, PiUserCircleGearFill } from "react-icons/pi"
// import { RiChatSettingsLine } from "react-icons/ri"
import { SettingsButton, SettingsCategory } from "../CategorySharedStyles"

const options = [
  { id: "My Account", label: "My Account", Icon: PiUserCircleGearFill },
  { id: "Layout", label: "Layout", Icon: PiLayout },
  // { id: "Chat", label: "Chat", Icon: RiChatSettingsLine },
]

function UserSettings() {
  return (
    <SettingsCategory>
      <h3>User Settings</h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default UserSettings
