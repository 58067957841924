// ButtonStyles.ts
import styled, { css } from 'styled-components';

export const BaseButton = styled.button`
  font-family: 'SF', sans-serif;
  color: #2c2c2c;
  background-color: var(--chatrpg-logo-color);
  font-size: 1rem;
  padding: 6px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  ${({ disabled }) => disabled && css`
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
  `}
`;

export const LoginButton = styled(BaseButton)`
  padding: 6px 30px;
  margin-right: 10px;

  &:hover {
    background-color: #d79e20;
  }
`;

export const SaveButtonStyle = styled(BaseButton)`
  padding: 10px 15px;
  margin-left: 10px;
`;

export const CloseButtonStyle = styled.button`
  width: 30px;
  height: 30px;
  position: relative;
  border: none;
  background: transparent url('/assets/UI/Buttons/close.png') no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;