import React, { useState, useEffect } from 'react';
import './UploadImageTextPanel.css'; // Import CSS for this component

type ImageUploaderProps = {
    onFileSelect: (file: File) => void;
    existingImageUrl?: string;
    selectedFile: File | null;
    setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({ onFileSelect, existingImageUrl, selectedFile, setSelectedFile }) => {
    const [preview, setPreview] = useState('');
    const fileInputRef = React.createRef<HTMLInputElement>();

    
    useEffect(() => {
        if (selectedFile) {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPreview(objectUrl);
            onFileSelect(selectedFile);

            // Free memory when component unmounts
            return () => URL.revokeObjectURL(objectUrl);
        } else if (existingImageUrl) {
            setPreview(existingImageUrl);
        } else {
            setPreview('');
        }
    }, [selectedFile, existingImageUrl]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // Check if file size is greater than 1MB
            if (file.size > 1048576) { // 1MB in bytes
                alert("File size should be less than 1MB");
                return;
            }
            setSelectedFile(file);
        }
    };
    

    const triggerFileSelectPopup = () => fileInputRef.current?.click();

    return (
        <div className="image-upload-section">
            <div className="button-and-preview">
                <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                />
                <button
                    className="add-image-button"
                    onClick={triggerFileSelectPopup}
                >
                    Add Image
                </button>
                {preview && (
                    <img
                        src={preview}
                        alt="Preview"
                        className="preview-image" 
                    />
                )}
            </div>
            <div className="image-size-text-notification">Images will be scaled to 320px wide</div>
        </div>
    );
};

export default ImageUploader;
