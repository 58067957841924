import { Avatar, Box, Flex, Text, Tooltip } from "@radix-ui/themes"
import { ForwardedRef, forwardRef } from "react"
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd"
import { Link, useParams } from "react-router-dom"
import styled, { css } from "styled-components"
import { Streamer, StreamerStatus } from "../../api/Sidebar"

interface SideBarBubbleProps
  extends DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps {
  streamer: Streamer
  status?: StreamerStatus
}

const formatNumber = Intl.NumberFormat("en", { notation: "compact" }).format

function resolveViewerCount(platforms: StreamerStatus["platforms"]) {
  return platforms.reduce((acc, { viewerCount }) => acc + (viewerCount ?? 0), 0)
}

function StreamerBubble(
  { streamer, status, ...props }: SideBarBubbleProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { username } = useParams()

  const { chatrpg_username, chatrpg_profilePicture, chatrpg_partner } = streamer

  const viewerCount = resolveViewerCount(status?.platforms ?? [])

  return (
    <Wrapper
      {...props}
      ref={ref}
      position="relative"
      justify="center"
      py="2"
      $online={!!status}
      $selected={username?.toLowerCase() === chatrpg_username.toLowerCase()}
      $partner={chatrpg_partner}
    >
      <StyledTooltip
        content={<Text size="3">{chatrpg_username}</Text>}
        side="right"
        sideOffset={10}
      >
        <Link to={`/${chatrpg_username.toLowerCase()}`}>
          <Avatar
            src={chatrpg_profilePicture}
            alt={chatrpg_username}
            fallback={chatrpg_username[0]}
            size="4"
            radius="full"
          />

          {!!status ? (
            <ViewerCount $isPartner={chatrpg_partner} px="1">
              <PersonIcon src="/assets/svg/person.svg" alt="Person Icon" />
              <StyledText size="1" weight="medium">
                {viewerCount > 0 ? `${formatNumber(viewerCount)}` : "Live"}
              </StyledText>
            </ViewerCount>
          ) : null}
        </Link>
      </StyledTooltip>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)<{
  $selected: boolean
  $online: boolean
  $partner: boolean
}>`
  background-color: ${({ $selected }) =>
    $selected ? "#a8b0cc28" : "transparent"};

  img,
  a > span {
    ${({ $online, $partner }) =>
      $online &&
      css`
        outline: 3px solid
          ${$partner ? "var(--chatrpg-logo-color)" : "var(--red-8)"};
        outline-offset: 0.5px;
      `}
  }
`

const StyledTooltip = styled(Tooltip)`
  font-family: "SF", sans-serif;
  background-color: var(--sidebar-color);
  padding: 0.5rem 1rem;

  & > span svg {
    fill: var(--sidebar-color);
  }

  p > span {
    color: white;
  }
`

const ViewerCount = styled(Box)<{ $isPartner: boolean }>`
  position: absolute;
  bottom: 0.125rem;
  right: 0.125rem;

  min-width: 1.125rem;
  min-height: 1.125rem;

  background-color: ${({ $isPartner }) =>
    $isPartner ? "var(--chatrpg-logo-color)" : "var(--red-9)"};
  color: ${({ $isPartner }) => ($isPartner ? "black" : "white")};
  font-size: 0.5rem;
  font-weight: bold;
  border: 1px solid var(--sidebar-color);
  border-radius: 0.25rem;

  display: flex; /* Ensure flex layout */
  align-items: center; /* Center items vertically */
`

const PersonIcon = styled.img`
  width: 12px; /* Adjust the size of the person icon */
  height: auto; /* Maintain aspect ratio */
  margin-right: 3px; /* Adjust spacing between icon and text */
  border: none !important; /* Ensure no border is applied, with !important */
  outline: none !important; /* Ensure no outline is applied, with !important */
`

const StyledText = styled(Text)`
  display: inline-block; /* Ensures block-level alignment behavior */
  vertical-align: middle; /* Vertically center the text */
`

export default forwardRef(StreamerBubble)
