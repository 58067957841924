// src/components/chat/auth/AuthModule.tsx
import React from 'react';
import './AuthModule.css';
import { authHandler } from './AuthHandler';
import { useLocation } from 'react-router-dom';

function AuthModule({ onClose }: { onClose: () => void }) {
  const location = useLocation();
  
  const onLoginClick = (platform: string) => {
    authHandler(platform, 'login', null, location.pathname);
  };

  const googlePolicyDisclosure = (
    <p className="google-policy-disclosure">
      ChatRPG's use of information received from Google APIs adheres to the <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.
    </p>
  );


  return (
    <div className="auth-module">
      <div className="login-box">
        <button onClick={() => onLoginClick('twitch')} className="twitch-login-button">Login with Twitch</button>
        <button onClick={() => onLoginClick('youtube')} className="youtube-login-button">Login with YouTube</button>
        {/* <button onClick={() => onLoginClick('tiktok')} className="tiktok-login-button">Login with Tiktok</button> */}
        <button onClick={onClose} className="close-button">Close</button>
        {googlePolicyDisclosure}
      </div>
    </div>
  );
}

export default AuthModule;
