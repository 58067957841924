import { ImEmbed2 } from "react-icons/im"
import { SettingsButton, SettingsCategory } from "../../CategorySharedStyles"

const options = [
  { id: "Developer Settings", label: "Settings", Icon: ImEmbed2 },
]

function DeveloperSettings() {
  return (
    <SettingsCategory>
      <h3>Developer Settings</h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default DeveloperSettings
