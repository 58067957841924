// InputFieldStyles.ts
import styled from 'styled-components';

export const InputTextField = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'SF', sans-serif;
`;

export const SearchInputTextField = styled(InputTextField)`
  padding-left: 35px; // Increase left padding to accommodate the search icon
`;