// SaveButton.tsx
import React from 'react';
import { SaveButtonStyle } from './ButtonStyles';

interface SaveButtonProps {
    onSave: () => void;
    isDisabled: boolean;
    label?: string;
}

const SaveButton: React.FC<SaveButtonProps> = ({ onSave, isDisabled, label = "Save Changes" }) => {

    const handleClick = () => {
        console.log('Save button clicked.');
        onSave();
    };

    return (
        <SaveButtonStyle onClick={handleClick} disabled={isDisabled}>
            {label}
        </SaveButtonStyle>
    );
};

export default SaveButton;
