// src/store/reducers/inputChatReducer.ts

const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';

// Action Creator
export const setChatMessage = (message: string) => ({
    type: SET_CHAT_MESSAGE,
    payload: message,
});

const initialState = {
    chatMessage: '',
};

// Reducer
const inputChatReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_CHAT_MESSAGE:
            return { ...state, chatMessage: action.payload };
        default:
            return state;
    }
};

export default inputChatReducer;
