// src/store/currentChatPlatformSlice.ts

// Action Types
const SET_CURRENT_CHAT_PLATFORM = 'SET_CURRENT_CHAT_PLATFORM';

// Action Creators
export const setCurrentChatPlatform = (platform: string) => ({
    type: SET_CURRENT_CHAT_PLATFORM,
    payload: platform,
});

// Initial State
const initialState = {
    currentPlatform: 'ChatRPG', // Default platform, change as needed
};

// Reducer
export const currentChatPlatformReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_CURRENT_CHAT_PLATFORM:
            const newState = { ...state, currentPlatform: action.payload };
            return newState;
        default:
            return state;
    }
};
