import Masonry from 'masonry-layout';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DISABLE_EDIT_MODE, ENABLE_EDIT_MODE } from '../../../../store/reducers/editModeReducer';
import { useStreamerPageProfile } from '../../../contexts/StreamerProfileContext';
import { useUserProfile } from '../../../contexts/UserProfileContext';
import ShimmerLine from '../../../shimmer/ShimmerLine';
import AddCustomPanel from './AddCustomPanel/AddCustomPanel';
import AddImageTextPanel from './AddCustomPanel/addpanelbutton/AddImageTextPanel';
import FinishedPanel from './AddCustomPanel/finishedpanel/FinishedPanel';

import './CustomPanel.css';
import './CustomPanelSwitch.css';

const CustomPanel = () => {
    const dispatch = useDispatch();
    const { streamerProfile } = useStreamerPageProfile();
    const { profile: userProfile } = useUserProfile();
    const isEditMode = useSelector((state: RootState) => state.editMode);
    const panelContainerRef = useRef(null);

    const toggleEditMode = () => {
        if (isEditMode) {
            dispatch({ type: DISABLE_EDIT_MODE });
        } else {
            dispatch({ type: ENABLE_EDIT_MODE });
        }
    };

    const isUserStreamer = userProfile && streamerProfile && userProfile.chatrpg_id === streamerProfile.chatrpg_id;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (panelContainerRef.current) {
                new Masonry(panelContainerRef.current, {
                    itemSelector: '.finished-panel',
                    columnWidth: 320,
                    gutter: 10
                });
            }
        }, 1000); // Delay for 2 seconds

        return () => clearTimeout(timeoutId); // Clear timeout on cleanup
    }, [streamerProfile]);


    return (
        <div className="custom-panel-container">
            {isUserStreamer && (
                <div className="editPanelsTextHolder">
                    <span className="editPanelsText">Edit your Page</span>
                    <label className="customPanel-switch">
                        <input
                            type="checkbox"
                            checked={isEditMode}
                            onChange={toggleEditMode}
                        />
                        <span className="customPanel-slider customPanel-round"></span>
                    </label>
                </div>
            )}
            <div className="panel-container">
                <div className="addCustomPanel-container">
                    {isEditMode && isUserStreamer && <AddCustomPanel />}
                </div>
                <div className="finished-panels-container" ref={panelContainerRef}>
                    {!streamerProfile ? (
                        <React.Fragment>
                            <ShimmerLine className="shimmer-panel" />
                            <ShimmerLine className="shimmer-panel" />
                            <ShimmerLine className="shimmer-panel" />
                        </React.Fragment>
                    ) : (
                        streamerProfile?.panels.map(panel => (
                            isEditMode ? <AddImageTextPanel key={panel.id} existingPanel={panel} />
                                :
                                <FinishedPanel
                                    key={panel.id}
                                    title={panel.title}
                                    imageUrl={panel.imageUrl}
                                    description={panel.description}
                                    htmlDescription={panel.htmlDescription}
                                    link={panel.linkUrl}
                                />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomPanel;
