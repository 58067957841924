import { Flex, IconButton } from "@radix-ui/themes"
import { LuFlipHorizontal2, LuFlipVertical2 } from "react-icons/lu"
import { makeAble, MoveableManagerInterface } from "react-moveable"
import styled from "styled-components"

const Flippable = makeAble("flippable", {
  name: "flippable",
  props: [],
  events: [],

  render(moveable: MoveableManagerInterface) {
    const element = moveable.getDragElement()
    if (!element) return null

    const rect = moveable.getRect()
    const { pos2, pos1, pos3, pos4, containerClientRect } = moveable.state

    const resolvePos = () => {
      if (rect.left > (containerClientRect.width - rect.width) * 0.95) {
        return {
          pos: pos3,
          translate: `translate(-30px, -55px)`,
          direction: "column",
        }
      }

      if (rect.top < containerClientRect.height * 0.05) {
        if (rect.left < (containerClientRect.width - rect.width) * 0.05) {
          return {
            pos: pos4,
            translate: `translate(5px, -55px)`,
            direction: "column",
          }
        }

        return {
          pos: pos1,
          translate: `translate(-30px)`,
          direction: "column",
        }
      }

      return {
        pos: pos2,
        translate: `translate(6px)`,
        direction: "column",
      }
    }

    const {
      pos: [x, y],
      translate,
      direction,
    } = resolvePos()

    return (
      <Wrapper
        key="flippable"
        className="moveable-flippable"
        // @ts-expect-error
        direction={direction}
        gap="1"
        style={{
          transform: `translate(${x}px, ${y}px) rotate(${rect.rotation}deg) ${translate}`,
        }}
      >
        <IconButton
          style={{ width: "24px", height: "24px" }}
          onClick={() => {
            const [scaleX, scaleY] = moveable.scale

            if (element.firstElementChild instanceof HTMLElement) {
              // We are flipping the CHILD of the target element, not the target
              // element itself, just so that we can see the effect and won't have
              // to worry about the control transform matrix.
              // The final scale values that will be sent will still be flipped.
              // Overall, I'm still not convinced of this solution -- chan_gelog.
              const { transform } = element.firstElementChild.style

              const oldTransform = transform.match(/scaleX\(([^)]+)\)/)

              const [, oldScaleX] = oldTransform ?? [, "1"]

              const newScaleX = `scaleX(calc(${oldScaleX} * -1))`

              element.firstElementChild.style.transform = oldTransform
                ? transform.replace(oldTransform[0], newScaleX)
                : `${transform} ${newScaleX}`

              moveable.scale = [scaleX * -1, scaleY]
              moveable.forceUpdate()
            }

            //! Plz don't remove these comments I might need them later
            // moveable.request("scalable", {
            //   direction: [1, 0],
            //   deltaWidth: -offsetWidth * 2,
            //   deltaHeight: 0,
            //   keepRatio: true,
            //   useSnap: true,
            //   isInstant: true,
            // })

            // moveable.request("flippable", { direction: "horizontal" })
          }}
        >
          <LuFlipHorizontal2 />
        </IconButton>

        <IconButton
          style={{ width: "24px", height: "24px" }}
          onClick={() => {
            const [scaleX, scaleY] = moveable.scale

            if (element.firstElementChild instanceof HTMLElement) {
              const { transform } = element.firstElementChild.style

              const oldTransform = transform.match(/scaleY\(([^)]+)\)/)

              const [, oldScaleY] = oldTransform ?? [, "1"]

              const newScaleY = `scaleY(calc(${oldScaleY} * -1))`

              element.firstElementChild.style.transform = oldTransform
                ? transform.replace(oldTransform[0], newScaleY)
                : `${transform} ${newScaleY}`

              moveable.scale = [scaleX, scaleY * -1]
              moveable.forceUpdate()
            }

            // moveable.request("flipVertical")

            // moveable.request("scalable", {
            //   direction: [0, 1],
            //   deltaHeight: -offsetHeight * 2,
            //   keepRatio: true,
            //   useSnap: true,
            //   isInstant: true,
            // })
          }}
        >
          <LuFlipVertical2 />
        </IconButton>
      </Wrapper>
    )
  },
})

const Wrapper = styled(Flex)`
  position: absolute;
  left: 0px;
  top: 0px;
  will-change: transform;
  transform-origin: 0px 0px;
`

export default Flippable
