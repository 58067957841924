// InputField.js or InputField.tsx
import React, { ChangeEvent } from 'react';
import InputText from '../../inputfield/InputText';
import { InputWidthInputBox, InputWidthOption, InputWidthOptionName } from './InputWidthStyles';

interface InputWidthProps {
    label: string;
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputWidth: React.FC<InputWidthProps> = ({ label, placeholder, value, onChange }) => (
    <InputWidthOption>
        <InputWidthOptionName>{label}</InputWidthOptionName>
        <InputWidthInputBox>
            <InputText
                placeholder="placeholder"
                value={value}
                onChange={onChange}
            />
        </InputWidthInputBox>
    </InputWidthOption>
);

export default InputWidth;
