import { Box, Text } from "@radix-ui/themes"
import { ReactNode } from "react"
import styled, { css } from "styled-components"
import { useEmotes } from "../../providers/EmoteProvider"
import ChatMessage from "./chatmessage/ChatMessageModel"

interface ChatNotificationBoxProps {
  message: ChatMessage;
  isEmbedded?: boolean;  // New prop to control embedded mode
}

function getDisplayDuration(months: number) {
  const years = Math.floor(months / 12)

  return years > 0 && months % 12 === 0
    ? `${years} year${years > 1 ? "s" : ""}`
    : `${months} month${months > 1 ? "s" : ""}`
}

const highlightUsername = (username: string) => (
  <Highlight $username weight="bold">
    {username}
  </Highlight>
)

const highlightReward = (reward: string) => (
  <Highlight $reward weight="bold">
    {reward}
  </Highlight>
)

const highlightPlatform = (platform: string) => (
  <Highlight $platform weight="bold">
    [{platform}]
  </Highlight>
);


function ChatNotificationBox({ message, isEmbedded = false }: ChatNotificationBoxProps) {
  const { parse: parseEmotes } = useEmotes();
  const { data, type } = message;

  let topBarContent: ReactNode = null;
  let mainContent: string = "";
  let customStyles: React.CSSProperties = {};  // Ensure customStyles is declared here

  function replaceTopBarPlaceholders(template: string, values: any): ReactNode {
    if (!template) {
      console.warn('Template in ChatNotificationBox is undefined or null');
      return <></>;
    }

    if (!values || typeof values !== 'object') {
      console.warn('Values in ChatNotificationBox are undefined or not an object');
      return template; // Return as simple text if values are not provided correctly
    }

    return template.split(/\$\{(\w+)\}/g).map((part, index) => {
      if (index % 2 === 1 && values.hasOwnProperty(part)) {
        return <Highlight $reward weight="bold" key={index}>{values[part]}</Highlight>;
      }
      return part;
    });
  }

  function replaceMainContentPlaceholders(template: string, values: any): string {
    if (!template) {
      console.warn('Template is undefined or null');
      return "";
    }

    if (!values || typeof values !== 'object') {
      console.warn('Values are undefined or not an object');
      return template;
    }

    return template.split(/\$\{(\w+)\}/g).reduce((acc, part, index) => {
      if (index % 2 === 1 && values.hasOwnProperty(part)) {
        return acc + `${values[part]}`; 
      }
      return acc + part;
    }, "");
  }

  if (type === "CustomEvent") {
    console.log("CUSTOM EVENT DETECTED " + JSON.stringify(data));
    customStyles = data.data.css as React.CSSProperties;
    const dynamicTextElements = replaceTopBarPlaceholders(data.data.text, data.data);
    const secondaryMessageElements = replaceMainContentPlaceholders(data.data.message, data.data);
    let textAlign: string = data.data.textAlign || 'left';

    <CustomImageContainer>
      {data.data.imageUrl && <img src={data.data.imageUrl} alt="Event Visual" />}
    </CustomImageContainer>

    topBarContent = (
      <>
        <span>{dynamicTextElements}</span>
      </>
    )
    mainContent = secondaryMessageElements;
    ;

    return (
      <CustomWrapper customStyles={customStyles}>
        {data.data.imageUrl && (
          <CustomImageContainer>
            <img src={data.data.imageUrl} alt="Event Visual" />
          </CustomImageContainer>
        )}
        <CustomContentContainer textAlign={textAlign}>
          <TopBar weight="bold" align="center" as="p">
            <span>{dynamicTextElements}</span>
          </TopBar>
          <Text align="center" mx="3" asChild>
            <MainContent>{parseEmotes(secondaryMessageElements)}</MainContent>
          </Text>
        </CustomContentContainer>
      </CustomWrapper>
    );
  }

  if (message.data) {
    switch (data.data.topic) {
      case "twitchChannelPoints":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} redeemed{" "}
            {highlightReward(data.data.data.title)}!
          </>
        )
        mainContent = data.data.data.prompt;
        break

      case "twitchRaid":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} raided with {" "}
            {highlightReward(data.data.data.amount)} viewers!
          </>
        )
        break

      case "kickRaid":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} raided with {" "}
            {highlightReward(data.data.data.amount)} viewers!
          </>
        )
        break

      case "twitchSubscriptionGift":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} gifted a sub to {highlightUsername(data.data.data.receiver)}!!
          </>
        );
        break;

      case "twitchSubscriptions":
        const months = data.data.data.amount ?? 0

        if (months > 0) {
          console.log("What is our username? " + JSON.stringify(data.data));
          topBarContent = (
            <>
              {highlightUsername(data.data.data.username)} just subscribed for{" "}
              {highlightReward(getDisplayDuration(months ?? 0))}!
            </>
          )
        } else {
          topBarContent = (
            <>{highlightUsername(data.data.data.username)} just subscribed!</>
          )
        }
        mainContent = data.data.data.message?.trim() ?? "No Message Provided <3"
        break

      case "twitchFollows":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just followed!
          </>
        )
        break

      case "twitchBits":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} gave{" "}
            {highlightReward(data.data.data.amount)} bits!
          </>
        )
        mainContent = data.data.data.message.replace(/\bCheer\d+\b/g, "").trim()
        break

      case "youtubeSuperChats":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just superchatted{" "}
            {highlightReward(data.data.data.amount)}!
          </>
        )
        mainContent = data.data.data.message.replace(/\bCheer\d+\b/g, "").trim()
        break

      case "youtubeSuperStickers":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just superstickered{" "}
            {highlightReward(data.data.data.amount)}!
          </>
        )
        break

      case "youtubeSponsors":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just became a{" "}
            {highlightUsername("member!")}!
          </>
        )
        break

      case "tiktokGifts":
        topBarContent = (
          <>
            <img src={data.data.data.giftPictureUrl} alt="Gift" />{" "}
            {highlightUsername(data.data.data.username)} gifts{" "}
            {highlightReward(data.data.data.giftName)} x{data.data.data.amount} (
            {data.data.data.amount * data.data.data.giftDiamondCount})
          </>
        )
        break;

      case "tiktokSubscriptions":
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just subscribed on {highlightReward("TikTok")}!
          </>
        )
        break;

        case "tiktokFollows":
          topBarContent = (
            <>
              {highlightUsername(data.data.data.username)} just followed on {highlightReward("TikTok")}!
            </>
          )
          break;

      case "streamlabsDonations":
        topBarContent = (
          <>
            {highlightPlatform("StreamLabs")}{" "}{highlightUsername(data.data.data.username)} just donated{" "}
            {highlightReward(data.data.data.formatted_amount)} {highlightReward(data.data.data.currency)}!
          </>
        )
        mainContent = data.data.data.message;
        break;

      case "streamelementsDonations":
        console.log(JSON.stringify(data.data.data));
        topBarContent = (
          <>
            {highlightPlatform("StreamElements")}{" "}{highlightUsername(data.data.data.username)} just donated{" "}
            {highlightReward(data.data.data.amount)} {highlightReward(data.data.data.currency)}!
          </>
        )
        mainContent = data.data.data.message;
        break;

      case "kickSubscriptions":
        const kickMonths = data.data.data.amount ?? 0

        if (kickMonths > 0) {
          topBarContent = (
            <>
              {highlightUsername(data.data.data.username)} just subscribed for{" "}
              {highlightReward(getDisplayDuration(kickMonths ?? 0))}!
            </>
          )
        } else {
          topBarContent = (
            <>{highlightUsername(data.data.data.username)} just subscribed!</>
          )
        }
        break

      case "kickFollowers":
        topBarContent = (
          <>{highlightUsername(data.data.data.username)} just followed!</>
        )
        break

      case "kickSubscriptionGifts":
        console.log("What is our Kick Gifts? " + JSON.stringify(data.data));
        console.log("Putting this here so I can console log it later: " + data.data.data.username + " " + data.data.data.receiver)
        topBarContent = (
          <>
            {highlightUsername(data.data.data.username)} just gifted a Kick Subscription to {highlightUsername(data.data.data.receiver)}!
          </>
        )
        break
      default:
        console.log("Unknown event Topic:", message)
    }
  }

  return (
    <Wrapper $embedded={isEmbedded}>
      <TopBar weight="bold" align="center" as="p">
        {topBarContent}
      </TopBar>

      {mainContent ? (
        <Text align="center" mx="3" asChild>
          <MainContent>{parseEmotes(mainContent)}</MainContent>
        </Text>
      ) : null}
    </Wrapper>
  )
}


const CustomWrapper = styled(Box) <{ customStyles?: React.CSSProperties }>`
  display: flex;
  position: relative;
  margin: 0.25rem 0px;
  ${props => props.customStyles ? (
    Object.keys(props.customStyles)
      .map(key => {
        const value = props.customStyles![key as keyof React.CSSProperties];
        return `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};`;
      })
      .join('')
  ) : ''}
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #87CEEB;
  }
`;

const CustomImageContainer = styled.div`
margin-left: 15px;
margin-top: 5px;
margin-bottom: 5px;
  width: 50px;
  height: 50px;
  margin-right: 5px;  // Adds some space between the image and text
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;  // Ensures the image covers the full area without distortion
  }
`;

const CustomContentContainer = styled.div<{ textAlign?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ textAlign }) => (textAlign === 'left' ? 'flex-start' : (textAlign === 'center' ? 'center' : 'flex-end'))};
`;

const Wrapper = styled(Box) <{ $embedded?: boolean }>`
  position: relative;
  margin: 0.25rem 0px;
  background: ${({ $embedded }) => ($embedded ? 'transparent' : '#28282c')}; /* Make background transparent if embedded */
  color: #fff;

  ${({ $embedded }) =>
    !$embedded &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: var(--chatrpg-logo-color);
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: var(--chatrpg-logo-color);
      }
    `}
`;

const TopBar = styled(Text)`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-left: 10px !important;
  margin-right: 10px !important;

  img {
    width: 1.75rem;
    height: 1.75rem;
  }
`

const MainContent = styled.p`
  padding-bottom: 0.5rem;
  margin-left: 10px !important;
  margin-right: 10px !important;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 0.813rem;

  /* TODO: Make this a generic component */
  & > div {
    vertical-align: -1px;
    display: inline-flex;
    max-height: 0.875rem;
    overflow: visible;
  }
  img {
    display: inline-flex;
    vertical-align: text-top;
    overflow: visible;
    object-fit: cover;
    max-height: 1.5rem;
  }
`

const Highlight = styled(Text) <{ $username?: boolean; $reward?: boolean; $platform?: boolean }>`
  display: inline;
  color: ${props => props.$platform ? '#4a90e2' : 'var(--chatrpg-logo-color)'}; // Example color for platforms
`;

export default ChatNotificationBox
