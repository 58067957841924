import { PlatformKey } from "components/config/platformConfig"
import { useStreamerPageProfile } from "components/contexts/StreamerProfileContext"
import { useToken } from "components/contexts/TokenContext"
import { useInventoryItem } from "hooks/useInventoryImage"
import { useRef } from "react"
import { flushSync } from "react-dom"
import Moveable from "react-moveable"
import { useSelector } from "react-redux"
import { useAppDispatch } from "store/hooks"
import {
  selectOverlayItem,
  selectOverlayItemCoords,
  setPlayerOverlayItemCoords,
} from "store/slices/EmbeddedPlayer"
import Actionable from "./CustomAbles/Actionable"
import DimensionViewable from "./CustomAbles/DimensionViewable"
import Flippable from "./CustomAbles/Flippable"

interface EmbeddedPlayerProps {
  platform: PlatformKey
  url: string
}

function EmbeddedPlayer({ platform, url }: EmbeddedPlayerProps) {
  const dispatch = useAppDispatch()

  const { token } = useToken()
  const { streamerProfile } = useStreamerPageProfile()

  useInventoryItem()

  const inventoryItem = useSelector(selectOverlayItem)
  const overlayItemCoords = useSelector(selectOverlayItemCoords)

  const target = useRef<HTMLDivElement>(null)
  const iframe = useRef<HTMLIFrameElement>(null)

  return (
    <>
      <iframe
        ref={iframe}
        src={url}
        title={`${platform} Stream Embed - ${streamerProfile?.chatrpg_username}`}
        width="100%"
        height="100%"
        scrolling="no"
        allowFullScreen
      />

      {iframe.current && inventoryItem && (
        <div style={{ position: "absolute", inset: 0 }}>
          <div
            key={inventoryItem.src}
            ref={target}
            style={{
              width: `${inventoryItem.width}px`,
              height: `${inventoryItem.height}px`,
              position: "absolute",
              top: iframe.current.offsetHeight / 2 - inventoryItem.height / 2,
              left: iframe.current.offsetWidth / 2 - inventoryItem.width / 2,
            }}
          >
            <video key={inventoryItem.src} autoPlay loop playsInline>
              <source
                src={inventoryItem.src}
                type="video/webm"
                style={{ width: "100%", height: "100%" }}
              />
              {/* <source src={inventoryImage.src} type="video/mp4" /> */}
            </video>
          </div>

          <Moveable
            token={token}
            item={inventoryItem}
            useMutationObserver
            useResizeObserver
            target={target}
            // container={container.current}
            ables={[Actionable, Flippable, DimensionViewable]}
            props={{
              actionable: true,
              flippable: true,
              dimensionViewable: true,
            }}
            origin={false}
            // Built-in Ables
            draggable
            scalable
            rotatable
            snappable
            pinchable
            // snapDirections={true}
            // snapThreshold={5}
            // elementGuidelines={[container]}
            // snapDirections={{
            //   top: true,
            //   left: true,
            //   bottom: true,
            //   right: true,
            //   center: true,
            //   middle: true,
            // }}
            // elementSnapDirections={{
            //   middle: true,
            //   center: true,
            //   top: true,
            //   left: true,
            //   bottom: true,
            //   right: true,
            // }}
            /* Resize event edges */
            // edge={false}
            // edgeDraggable={false}
            // startDragRotate={0}
            // throttleDragRotate={0}
            /* draggable */
            throttleDrag={0}
            onDrag={(e) => {
              const {
                target,
                // beforeDelta,
                // beforeDist,
                // left,
                // top,
                // right,
                // bottom,
                // delta,
                // dist,
                transform,
                // clientX,
                // clientY,
              } = e

              // console.log("onDrag left, top", left, top)
              // target.style.left = `${left}px`
              // target.style.top = `${top}px`
              // console.log("onDrag translate", dist)
              target.style.transform = transform
            }}
            onDragEnd={(e) => {
              const translate = e.target.style.transform.match(
                /translate\((-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px\)/
              )

              if (translate) {
                const [, x, y] = translate

                // I generally don't like this solution, but it works
                // and we might need the value somewhere ??
                // -- chan_gelog
                dispatch(
                  setPlayerOverlayItemCoords({
                    x: Number.parseFloat(x),
                    y: Number.parseFloat(y),
                  })
                )
              }
            }}
            /* When resize or scale, keeps a ratio of the width, height. */
            keepRatio
            renderDirections={["nw", "ne", "sw", "se"]}
            /* scalable */
            /* Only one of resizable, scalable, warpable can be used. */
            // throttleScale={1}
            onScaleStart={(e) => {
              const MIN_SCALE = 0.2

              const { width, height } = inventoryItem

              e.setMinScaleSize([width * MIN_SCALE, height * MIN_SCALE])
              e.setMaxScaleSize([width, height])
            }}
            onScale={(e) => {
              const {
                target,
                scale: [scaleX, scaleY],
                // dist,
                // delta,
                transform,
                // clientX,
                // clientY,
              } = e
              // console.log("onScale", { scale: [scaleX, scaleY], transform })
              target.style.transform = transform
              const [flipScaleX, flipScaleY] = e.moveable.scale

              const { abs, sign } = Math
              e.moveable.scale = [
                abs(scaleX) * sign(flipScaleX),
                abs(scaleY) * sign(flipScaleY),
              ]
            }}
            /* snappable */
            snapRotataionThreshold={5}
            snapRotationDegrees={[0, 90, 180, 270]}
            bounds={{ top: 0, bottom: 0, left: 0, right: 0, position: "css" }}
            /* rotatable */
            throttleRotate={0}
            onRotate={(e) => {
              const {
                target,
                // delta,
                // dist,
                transform,
                // clientX,
                // clientY,
              } = e
              // console.log("onRotate", dist)
              target.style.transform = transform
            }}
            // Enabling pinchable lets you use events that
            // can be used in draggable, resizable, scalable, and rotateable.
            onPinchStart={() => {
              // pinchStart event occur before dragStart, rotateStart, scaleStart, resizeStart
              console.log("onPinchStart")
            }}
            onPinch={() => {
              // pinch event occur before drag, rotate, scale, resize
              console.log("onPinch")
            }}
            onChangeTargets={(e) => {
              const [target] = e.targets
              const [scaleX, scaleY] = e.moveable.scale

              const { abs, sign } = Math

              const deltaWidth =
                inventoryItem.width - inventoryItem.width * abs(scaleX)

              const deltaHeight =
                inventoryItem.height - inventoryItem.height * abs(scaleY)

              e.moveable.request("scalable", {
                deltaWidth: abs(scaleX) > 1 ? deltaWidth : -deltaWidth,
                deltaHeight: abs(scaleY) > 1 ? deltaHeight : -deltaHeight,
                keepRatio: true,
                isInstant: true,
                useSnap: true,
              })

              if (overlayItemCoords) {
                const { x, y } = overlayItemCoords

                // FIXME
                // There's a bug here that shows itself when the item
                // is scaled and then changed to a different item
                e.moveable.request("draggable", {
                  deltaX: x + deltaWidth / 2,
                  deltaY: y + deltaHeight / 2,
                  isInstant: true,
                  useSnap: true,
                })
              }

              if (target.firstElementChild instanceof HTMLElement) {
                const scale = `scaleX(${sign(scaleX)}) scaleY(${sign(scaleY)})`
                target.firstElementChild.style.transform = scale
              }
            }}
            onRender={(e) => {
              e.target.style.cssText += e.cssText
            }}
            flushSync={flushSync}
          />
        </div>
      )}
    </>
  )
}

export default EmbeddedPlayer
