import { ScrollArea } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { RootState } from "../../store"
import { useToken } from "../contexts/TokenContext"
import { useUserProfile } from "../contexts/UserProfileContext"
import SidebarBubbles from "./Bubbles"

import logo from "../../assets/logo.png"

function Sidebar() {
  const { token } = useToken()
  const { profile } = useUserProfile()

  // TODO: Need to create a dedicated selector for this
  const isTheatreMode = useSelector(
    ({ theatreMode }: RootState) => theatreMode.isTheatreMode
  )
  const isFullscreenMode = useSelector(
    ({ fullscreenMode }: RootState) => fullscreenMode.isFullscreenMode
  )

  const isLoggedIn = !!token
  const showSidebar = !!profile && profile.followedStreamers.length > 0
  const sidebarDisabled = profile?.settings?.disableSidebar

  if (!isLoggedIn || sidebarDisabled) return null

  return (
    <Wrapper $hide={isTheatreMode || isFullscreenMode}>
      <BrandLink>
        <Link to="/browse">
          <Logo src={logo} alt="Logo" />
        </Link>
      </BrandLink>

      <Divider />

      <ScrollWrapper $show={showSidebar}>
        <SidebarBubbles />
      </ScrollWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $hide?: boolean }>`
  flex-shrink: 0;
  width: var(--sidebar-width);
  color: #ecf0f1;

  display: flex;
  flex-direction: column;

  ${({ $hide }) =>
    $hide
      ? css`
          position: absolute;
          left: calc(var(--sidebar-width) * -1);
        `
      : css`
          position: relative;
          left: 0;
        `}
`

// TODO: Get this off here and into the top bar
const BrandLink = styled.div`
  background-color: var(--sidebar-color);
  height: calc(var(--top-bar-height) + 0.125rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  max-height: 1.875rem;
  max-width: 1.875rem;
`

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: var(--top-bar-border-color);
  margin: 0;
`

const ScrollWrapper = styled(ScrollArea)<{ $show?: boolean }>`
  background-color: var(--sidebar-color);
  position: relative;
  left: calc(var(--sidebar-width) * ${({ $show }) => ($show ? 0 : -1)});
  transition: left 0.3s;

  .rt-ScrollAreaScrollbar {
    visibility: hidden;
  }
`

export default Sidebar
