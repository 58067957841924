// AddCustomPanel.tsx
import React, { useState } from 'react';
import './AddCustomPanel.css'; // Re-import if these styles are still needed here
import OverlayContainer from './addpanelbutton/OverlayContainer';
import AddButtonContent from './addpanelbutton/AddButtonContent';

const AddCustomPanel: React.FC = () => {
    const [showOverlay, setShowOverlay] = useState(false);

    const handleOverlayClick = () => {
        setShowOverlay(true);
        // Additional logic for handling the overlay click can be added here
    };

    return (
        <div className={`custom-panel-add-button-container ${showOverlay ? 'overlay-visible' : ''}`}
            onClick={!showOverlay ? handleOverlayClick : undefined}>
            {!showOverlay ? (
                <AddButtonContent />
            ) : (
                <OverlayContainer />
            )}
        </div>
    );
};

export default AddCustomPanel;
