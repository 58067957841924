// src/components/privacy/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  const headingStyle = {
    marginTop: '20px', // Adds space above each h2 element
    marginBottom: '10px', // Adds space below each h2 element
  };

  return (
    <div style={{ whiteSpace: "pre-wrap", backgroundColor: "white", color: 'black' }}>
      <h1>Privacy Policy</h1>

      <p><strong>Last updated: May 4th, 2024</strong></p>

      <p>Welcome to ChatRPG! This privacy policy outlines how we collect, use, and protect your information when you use our website and services.</p>

      <h2 style={headingStyle}>1. Information Collection and Use</h2>
      <p><strong>Data Collected Through External OAuth:</strong> When you log in through external platforms (like Twitch, Kik, YouTube, TikTok, etc.), we collect and store your access tokens. We do not store your passwords or any sensitive login details.</p>
      <p><strong>Usage Data:</strong> We may collect information on how our service is accessed and used. This data may include your computer's IP address, browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</p>
      <p><strong>Cookies:</strong> We use cookies and similar tracking technologies to track activity on our service and hold certain information.</p>

      <h2 style={headingStyle}>2. Use of Data</h2>
      <p>ChatRPG uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain our service</li>
        <li>To notify you about changes to our service</li>
        <li>To allow you to participate in interactive features of our service when you choose to do so</li>
        <li>To provide customer care and support</li>
        <li>To provide analysis or valuable information so that we can improve the service</li>
        <li>To monitor the usage of the service</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>

      <h2 style={headingStyle}>3. Data Sharing and Disclosure</h2>
      <p>We may share your data with third parties in the following situations:</p>
      <ul>
        <li><strong>With Service Providers:</strong> We may share your data with third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used.</li>
        <li><strong>For Legal Requirements:</strong> We may disclose your data if required to do so by law or in response to valid requests by public authorities.</li>
      </ul>

      <h2 style={headingStyle}>4. Data Security</h2>
      <p>The security of your data is important to us. While we strive to use commercially acceptable means to protect your data, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

      <h2 style={headingStyle}>5. Service Providers</h2>
      <p>We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used.</p>

      <h2 style={headingStyle}>6. Links to Other Sites</h2>
      <p>Our service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

      <h2 style={headingStyle}>7. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

      <h2 style={headingStyle}>8. Use of YouTube API Services</h2>
      <p>Our service uses YouTube API Services. By using our service, you agree to be bound by the <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>YouTube Terms of Service</a>. Information provided by YouTube is governed by Google's <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>Privacy Policy</a>.</p>
      <p>You can manage or revoke the application's access to your Google data via the <a href="https://myaccount.google.com/permissions" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>Google security settings page</a>.</p>

      <h2 style={headingStyle}>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at contact@cowsep.com</p>
    </div>
  );
};

export default PrivacyPolicy;