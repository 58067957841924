import { TabsList, TabsTrigger } from "@radix-ui/react-tabs"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { RootState } from "../../../store"
import { selectStreamerPageBottomBarExtensions } from "../../../store/slices/StreamerPage"
import { useUserProfile } from "../../contexts/UserProfileContext"

function ExtensionBottomBar() {
  const { profile } = useUserProfile()

  const extensions = useSelector(
    (state: RootState) =>
      // This selector will now handle the filtering/checking
      selectStreamerPageBottomBarExtensions(state)(profile?.administrator),
    { equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b) }
  )

  if (extensions.length === 0) return null

  // Render the BottomBarContainer only if there are valid bottom bar extensions
  return (
    <BottomBarContainer>
      <Button value="chat">
        <ButtonImage
          src="https://cdn.chatrpg.com/extensions/chat.png"
          alt="Back to Chat"
        />
      </Button>

      {extensions.map((extension) => (
        <Button key={extension.clientId} value={extension.clientId}>
          <ButtonImage
            src={extension.bottomBarImage}
            alt={`${extension.type} Icon`}
          />
        </Button>
      ))}
    </BottomBarContainer>
  )
}

const BottomBarContainer = styled(TabsList)`
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px; // Fixed height for the bar
  background-color: #333; // Background color for the bar
  color: white;
  width: 100%; // Take the full width of its parent
`

// Styled button to ensure they scale
const Button = styled(TabsTrigger)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none;
  background: linear-gradient(to bottom, #1f1f24, #151519);
  border-top: 1px solid #2a2a30;

  &[data-state="active"] {
    background: linear-gradient(to bottom, #292932, #19191f);
    cursor: default;

    &:hover {
      background: linear-gradient(to bottom, #292932, #19191f);
    }
  }

  &:hover {
    background: linear-gradient(
      to bottom,
      var(--chatrpg-logo-color),
      var(--chatrpg-logo-color-transparent)
    );
  }
`

// Styled image inside the button
const ButtonImage = styled.img`
  width: auto;
  height: 35px;
`

export default ExtensionBottomBar
