import { Box } from "@radix-ui/themes"
import ResizableInputForm from "./ResizableInputForm"

function ChatInputArea() {
  return (
    <Box px="2" pt="2">
      <ResizableInputForm />
    </Box>
  )
}

export default ChatInputArea
