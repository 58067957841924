import styled, { css } from "styled-components"
import { useMobileContext } from "../contexts/MobileContext"
import { RenderLeftSection } from "./LeftSection"
import RightSection from "./RightSection"

import "./TopBar.css"

function TopBar() {
  const { isMobile } = useMobileContext()

  return (
    <Wrapper>
      {!isMobile && <RenderLeftSection />}

      <RightSectionWrapper $mobile={isMobile}>
        <RightSection />
      </RightSectionWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: var(--top-bar-height);

  width: 100%;
  padding: 0;
  background-color: var(--sidebar-color);
  color: white;
  border-bottom: 1px solid var(--top-bar-border-color);
`

const RightSectionWrapper = styled.div<{ $mobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: var(--chatarea-width);  */
  overflow: visible;
  margin-left: auto;

  ${({ $mobile }) =>
    $mobile &&
    css`
      flex-grow: 1;
      width: auto;
      justify-content: flex-end;
      display: flex;
      align-items: center;
    `}
`

export default TopBar
