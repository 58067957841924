import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStreamerPageProfile } from '../contexts/StreamerProfileContext';
import { useToken } from '../contexts/TokenContext';
import { useUserProfile } from '../contexts/UserProfileContext';
// import { setActivePlayerIndex } from '../../store/reducers/embeddedPlayerReducer';

export const RenderLeftSection = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { streamerProfile } = useStreamerPageProfile();
    const { token } = useToken();
    const { profile } = useUserProfile();
    const toggleRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    // const activePlayerIndex = useSelector((state: RootState) => state.embeddedPlayer.activePlayerIndex);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    // const handlePlayerSelection = (index: any) => {
    //     dispatch(setActivePlayerIndex(index));
    //     setDropdownOpen(false); // Close the dropdown after selection
    // };


    // const menuItems = streamerProfile && streamerProfile.embeddedPlayers ? (
    //     <div className="dropdown-category">
    //         {streamerProfile.embeddedPlayers.map((player, index) => {
    //             const platformDetails = PLATFORMS[player.platform];
    //             const isSelected = index === activePlayerIndex;
    //             return platformDetails && platformDetails.embeddable ? (
    //                 <div key={index} className={`dropdown-item ${isSelected ? 'dropdown-selected-item' : ''}`} onClick={() => handlePlayerSelection(index)}>
    //                     <img src={platformDetails.badge || ''} alt={player.platform} className="dropdown-platform-badge" />
    //                     {player.username}
    //                 </div>
    //             ) : null;
    //         })}
    //     </div>
    // ) : null;


    // if (isStreamerPage) {
    //     if (streamerProfile) {
    //         return (
    //             <div className="left-section">
    //                 <div onClick={toggleDropdown} className="clickable-profile" ref={toggleRef}>
    //                     <img src={streamerProfile.chatrpg_profilePicture} alt={streamerProfile.chatrpg_username} className="streamer-profile-pic" />
    //                     {streamerProfile.chatrpg_username}
    //                 </div>
    //                 {token && profile ? <FollowButton /> : null}
    //                 <UserDropdownMenu isOpen={dropdownOpen} toggle={toggleDropdown} menuItems={menuItems} alignment="left" toggleRef={toggleRef} />
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className="left-section">
    //                 <div className="clickable-profile">
    //                     <ShimmerLine className="streamer-profile-pic" />
    //                     <ShimmerLine className="placeholder-line" />
    //                 </div>
    //             </div >
    //         );
    //     }
    // } else {
        return <div className="left-section">
            {/* <span className="chatrpg-title">ChatRPG</span> */}
        </div>;
    //}
};