import { ReactNode } from "react"
import replace from "react-string-replace"

const mdImageLinksRegex =
  /(!\[(?:[^\]]+)?\]\(https?:\/\/[^\s]+\s*(?:"(?:[^"]+)?")?\))/g

const knownEmoteUrlRegex =
  /https:\/\/(?:static-cdn.jtvnw.net\/emoticons\/|cdn.7tv.app\/emote\/|cdn.frankerfacez.com\/emoticon\/|cdn.betterttv.net\/emote\/|files.kick.com\/emotes\/)[-a-zA-Z0-9()@:%_+.~#?&/=]*/

const urlRegex =
  /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))/

export const parseLinks = (message: string | ReactNode[]): ReactNode[] => {
  let processed: string | ReactNode[] = message

  // replace emote md links with its image
  processed = replace(processed, mdImageLinksRegex, (match, i) => {
    // console.log("emoji:", { match })

    if (knownEmoteUrlRegex.test(match)) {
      const mdImageLinkCaptureRegex =
        /!\[([^\]]+)?\]\((https?:\/\/[^\s]+)\s*(?:"([^"]+)?")?\)/g

      const [alt, url, title] = mdImageLinkCaptureRegex.exec(match)!.slice(1)

      return (
        <div>
          <img
            key={`${match}${i}`}
            src={url}
            alt={alt}
            title={title}
            width={28}
            height={28}
          />
        </div>
      )
    }

    console.log("md links processing skipped:", match)
  })

  // replace urls with anchor links
  processed = replace(processed, urlRegex, (match) => {
    // console.log("url:", { match })

    return (
      <a key={match} href={match} target="_blank" rel="noopener noreferrer">
        {match}
      </a>
    )
  })

  return processed.filter(Boolean)
}
