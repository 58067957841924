import {
  Badge,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  Inset,
  Text,
} from "@radix-ui/themes"
import { ReactNode } from "react"
import { styled } from "styled-components"
import { ExtensionWithAuthor } from "../inventory/InstalledExtensions"
import ExtensionCardMetadataList from "./ExtensionCardMetadataList"

interface ExtensionCardProps {
  imageUrl: string
  name: string
  author: string
  description: string
  metadata?: ExtensionWithAuthor
  featured?: boolean
  queue?: boolean
  actionButtons?: ReactNode
}

function ExtensionCard({
  imageUrl,
  name,
  author,
  description,
  metadata,
  featured,
  queue,
  actionButtons,
}: ExtensionCardProps) {
  console.log(metadata)

  return (
    <Wrapper size={featured ? "2" : "1"}>
      {metadata && !queue && (
        <BadgeContainer>
          {metadata.isForStreamer && (
            <Badge
              color="blue"
              variant="solid"
              title="Can only be activated by streamers."
            >
              Streamer
            </Badge>
          )}

          {/* <Badge
            size="1"
            color={
              metadata.rejected
                ? "crimson"
                : metadata.approved
                ? "green"
                : "orange"
            }
            variant="solid"
          >
            {metadata.rejected
              ? "Rejected"
              : metadata.approved
              ? "Approved"
              : "Pending"}
          </Badge> */}
        </BadgeContainer>
      )}

      <Inset clip="padding-box" side="top" pb="current">
        <PreviewImage src={imageUrl} alt={`${name} Preview`} />
      </Inset>

      <Content my={queue ? "2" : "0"}>
        {queue && metadata ? (
          <ExtensionCardMetadataList {...metadata} />
        ) : (
          <>
            <Flex justify="between" align="center" mt="1" mb="2">
              <Heading as="h3" size="3">
                {name}
              </Heading>

              <Text as="p" size="2">
                by {author}
              </Text>
            </Flex>

            <Text size="2">{description}</Text>
          </>
        )}
      </Content>

      <Flex gap="3" align="center" justify="center" mt="3" width="100%">
        {actionButtons}
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
`

const PreviewImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 14rem;
  background-color: var(--gray-5);
`

const Content = styled(Container)`
  flex-grow: 1;
`

const BadgeContainer = styled(Flex)`
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
  width: fit-content;
  gap: 0.5rem;
  z-index: 99;
`

export const ExtensionCardButton = styled(Button)`
  flex: 1 1 0;
  /* cursor: pointer; */
`

export default ExtensionCard
