import { Box, Button, Text } from "@radix-ui/themes"
import { useState } from "react"
import AuthModule from "../../auth/login/AuthModule"

function LoginButtonWithAuth({ buttonText = "Login" }) {
  const [isAuthModuleOpen, setAuthModuleOpen] = useState(false)

  const handleLoginButtonClick = () => {
    setAuthModuleOpen(true)
  }

  const closeAuthModule = () => {
    setAuthModuleOpen(false)
  }

  return (
    <>
      <Button mx="2" onClick={handleLoginButtonClick}>
        <Box px="4">
          <Text weight="bold">{buttonText}</Text>
        </Box>
      </Button>

      {isAuthModuleOpen && <AuthModule onClose={closeAuthModule} />}
    </>
  )
}

export default LoginButtonWithAuth
