import React, { useState } from 'react';
import './PlatformBarIcons.css';
import PLATFORMS from '../../../../../config/platformConfig';

interface PlatformBarProps {
    initialPlatform: keyof typeof PLATFORMS;
    initialStreamerName: string;
    index: number;
    handleRemovePlatform: (index: number) => void;
    onUpdate: (index: number, platform: keyof typeof PLATFORMS, streamerName: string) => void;
}

const PlatformBar: React.FC<PlatformBarProps> = ({
    initialPlatform,
    initialStreamerName,
    index,
    handleRemovePlatform,
    onUpdate
}) => {
    const [platform, setPlatform] = useState(initialPlatform);
    const [streamerName, setStreamerName] = useState(initialStreamerName);

    const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPlatform = e.target.value as keyof typeof PLATFORMS;
        setPlatform(newPlatform);
        onUpdate(index, newPlatform, streamerName);
    };

    const handleStreamerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setStreamerName(newName);
        onUpdate(index, platform, newName);
    };

    return (
        <div className="platform-bar-icons" style={{ backgroundColor: PLATFORMS[platform].color }}>
            <div className="platform-icon-container">
                {PLATFORMS[platform].badge && (
                    <img src={PLATFORMS[platform].badge} alt={`${platform} icon`} className="platform-icon" />
                )}
            </div>
            <select
                value={platform}
                onChange={handlePlatformChange}
                className="platform-select"
            >
                {Object.keys(PLATFORMS).map((platformKey) => (
                    <option key={platformKey} value={platformKey}>{platformKey}</option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Platform Username"
                value={streamerName}
                onChange={handleStreamerNameChange}
                className="streamer-name-input global-focus-style"
            />
            <button onClick={() => handleRemovePlatform(index)} className="remove-platform-button">
                <svg viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 6h18v17a2 2 0 01-2 2H5a2 2 0 01-2-2V6z" fill="#808080" />
                    <path d="M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2" fill="#808080" />
                    <rect x="1" y="6" width="22" height="4" fill="#808080" />
                    <line x1="10" y1="11" x2="10" y2="17" stroke="black" />
                    <line x1="14" y1="11" x2="14" y2="17" stroke="black" />
                </svg>
            </button>
        </div>
    );
};

export default PlatformBar;
