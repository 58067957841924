import { FaTiktok, FaTwitch } from "react-icons/fa6"
import { RiKickFill } from "react-icons/ri"
import { styled } from "styled-components"
import { media } from "../../styled.utils"
import Container from "../Container.styled"

const integrations = [
  { Icon: FaTwitch, alt: "Twitch" },
  //{ Icon: FaYoutube, alt: "Youtube" },
  { Icon: FaTiktok, alt: "Tiktok" },
  { Icon: RiKickFill, alt: "Kick" },
]

function Integrations() {
  return (
    <Wrapper>
      <Container>
        <Header>Integrates with most stream providers</Header>

        <Body>
          {integrations.map(({ Icon, alt }) => (
            <Integration key={alt}>
              <Icon /> <span>{alt}</span>
            </Integration>
          ))}
        </Body>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: var(--space-l) 0;
  background-color: #eff7ff;

  ${media.xl`
    padding: var(--space-xl) 0;
  `}
`

const Header = styled.h2`
  text-align: center;
  font-size: var(--step-0);
  line-height: 2rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
`

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--space-l);
  width: fit-content;
  margin: 0 auto;

  ${media.md`
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
  `}

  ${media.lg`
    justify-items: center;
    gap: var(--space-3xl);
  `}
`

const Integration = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--space-s) * 0.9);

  font-size: var(--step-5);
  font-weight: 600;
  max-width: fit-content;

  ${media.lg`
    font-size: var(--step-4);
  `}

  span {
    font-size: calc(var(--step-0) * 1.1);
  }
`

export default Integrations
