import { Form } from "@radix-ui/react-form"
import { Box, Flex, Grid, Heading, Separator, Text } from "@radix-ui/themes"
import { useEffect, useMemo, useState } from "react"
import {
  UserSettings,
  useUserProfile,
} from "../../../../../contexts/UserProfileContext"
import ActionButton from "../../../../../ui/ActionButton"
import Toggle from "../../../../../ui/Toggle"
import { LinkContainer } from "../accountlinking/AccountLinkingOptionsStyles"
import { DialogClose } from "@radix-ui/react-dialog"; // Import DialogClose for the close button
import { Cross2Icon } from "@radix-ui/react-icons"; // Import a premade close (X) icon


type TUserChatSettings = Omit<
  UserSettings,
  "disablePlayer" | "disableSidebar" | "disableEmbeds"
>

function NewUserChatSettings() {
  const { profile, updateSettings } = useUserProfile()

  const platforms = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(profile?.settings ?? {}).filter(
          ([key]) =>
            key !== "disableRotatingChatBackground" &&
            key !== "enablePlatformChatBadges" &&
            /^(?!.*rotating).*chat.*$/i.test(key)  // Keep filtering for chat-related settings
        )
      ) as TUserChatSettings,
    [profile?.settings]
  )

  const [chatPlatforms, setChatPlatforms] = useState<
    Partial<TUserChatSettings>
  >({
    ...platforms,
    ...profile?.settings,  // Spread all settings dynamically
  })

  useEffect(() => {
    console.log("Initial chat platform settings:", chatPlatforms)
    console.log("Initial value for enablePlatformChatBadges:", chatPlatforms.enablePlatformChatBadges)
  }, [chatPlatforms])

  return (
    <>
      <LinkContainer size="3">
        <Flex justify="between" align="center" mb="4" style={{ position: 'relative' }}>
          {/* "Chat Settings" heading truly centered */}
          <Heading as="h1" size="4" weight="medium" mb="1" style={{ margin: '0 auto' }}>
            Chat Settings
          </Heading>

          {/* Close button on the right with bigger size and hover effect */}
          <DialogClose asChild>
            <button
              style={{
                position: 'absolute',
                right: 0,
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: '8px',
                borderRadius: '4px',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#333')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
            >
              <Cross2Icon width={24} height={24} />
            </button>
          </DialogClose>
        </Flex>
        <Separator size="4" my="4" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            updateSettings(chatPlatforms);
          }}
        >
          {/* Updated Grid for proper wrapping */}
          <Grid
            columns="repeat(auto-fit, minmax(100px, 1fr))" // Auto-fit and wrap if necessary
            gapX="4"
            gapY="4"
            mb="5"
          >
            {Object.entries(platforms).map(([key, val]) => (
              <Box key={key} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                <Toggle
                  label={key.replace(/disable|chat(?=$)/gi, "")}
                  checked={!chatPlatforms[key as keyof TUserChatSettings]}
                  radius="full"
                  size={{ initial: "1", md: "2" }}
                  gap="3"
                  onCheckedChange={(checked) => {
                    setChatPlatforms((prev) => ({ ...prev, [key]: !checked }));
                  }}
                />
              </Box>
            ))}
          </Grid>

          <Separator size="4" my="4" />

          {/* Accessibility Section */}
          <Box mb={{ initial: "2", md: "3", lg: "4" }}> {/* Reduced the margin-bottom */}
            <Flex justify="center">
              <Heading as="h1" size="4" weight="medium" mb="1">
                Accessibility
              </Heading>
            </Flex>
          </Box>

          <Separator size="4" my="4" />

          <Flex>
            <Toggle
              label="Alternating Background"
              checked={!chatPlatforms.disableRotatingChatBackground}
              radius="full"
              size={{ initial: "1", md: "2" }}
              gap="3"
              onCheckedChange={(checked) => {
                setChatPlatforms((prev) => ({
                  ...prev,
                  disableRotatingChatBackground: !checked,
                }));
              }}
            />
          </Flex>
          <br />
          <Flex>
            <Toggle
              label="Platform Chat Badge"
              checked={chatPlatforms.enablePlatformChatBadges ?? false} // Ensure boolean value
              radius="full"
              size={{ initial: "1", md: "2" }}
              gap="3"
              onCheckedChange={(checked) => {
                setChatPlatforms((prev) => ({
                  ...prev,
                  enablePlatformChatBadges: checked,
                }));
              }}
            />
          </Flex>


          <Flex mt="6" justify="center">
            <ActionButton size="2">Save</ActionButton>
          </Flex>


        </Form>
      </LinkContainer>
    </>
  )
}

export default NewUserChatSettings
