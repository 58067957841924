import UsernamePrompt from "components/auth/username/UsernamePromptBox"
import ChatArea from "components/chat/ChatArea"
import { GlobalBadgeProvider } from "components/contexts/GlobalBadgeContext"
import { useMobileContext } from "components/contexts/MobileContext"
import { useOrientation } from "components/contexts/OrientationContext"
import { useStreamerPageProfile } from "components/contexts/StreamerProfileContext"
import { useToken } from "components/contexts/TokenContext"
import { UserNotFound } from "components/misc/404/UserNotFound"
import Sidebar from "components/sidebar/Sidebar"
import TopBar from "components/topbar/TopBar"
import ContentAreaContainer from "components/username/ContentAreaContainer"
import PlayerArea from "components/username/player/PlayerArea"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RootState } from "store"
import { useAppDispatch } from "store/hooks"
import { setTheatreMode } from "store/reducers/theatreModeReducer"
import { clearAllExtensionJWT, fetchExtensionJWT } from "store/slices/Auth"
import { resetPlayer, setActivePlayer } from "store/slices/EmbeddedPlayer"
import { fetchStreamerPageProfile } from "store/slices/StreamerPage"
import { styled } from "styled-components"

function StreamerPage() {
  const dispatch = useAppDispatch()

  const { username } = useParams()

  const { isLandscape } = useOrientation()
  const { isMobile } = useMobileContext()

  const { token } = useToken()
  const { status, players, activePlayer } = useStreamerPageProfile()

  const isTheatreMode = useSelector(
    ({ theatreMode }: RootState) => theatreMode.isTheatreMode
  )
  const isFullscreenMode = useSelector(
    ({ fullscreenMode }: RootState) => fullscreenMode.isFullscreenMode
  )

  // Moved streamer page logic here temporarily until I can figure out where to put it
  useEffect(() => {
    if (!username) return

    dispatch(fetchStreamerPageProfile(username))
      .unwrap()
      .then((streamerPage) => {
        if (!token) return

        streamerPage.extensions.forEach((extension) => {
          dispatch(
            fetchExtensionJWT({
              token,
              clientId: extension.clientId,
              streamerId: streamerPage.chatrpg_id,
            })
          )
        })
      })

    return () => {
      dispatch(resetPlayer())
      dispatch(clearAllExtensionJWT())
    }
  }, [dispatch, username, token])

  useEffect(() => {
    const defaultPlayer = players[0]

    if (status === "loading" || activePlayer.url) return

    dispatch(setActivePlayer({ index: 0, ...defaultPlayer }))
  }, [activePlayer.url, dispatch, players, status])

  useEffect(() => {
    dispatch(setTheatreMode(isLandscape))
  }, [dispatch, isLandscape])

  return (
    <Wrapper>
      <UsernamePrompt />
      <Sidebar />
      <ContentAreaContainer>
        {!isTheatreMode && !isFullscreenMode && <TopBar />}

        {status === "error" ? (
          <UserNotFound />
        ) : (
          <MainContent>
            {!isMobile && <PlayerArea />}

            <GlobalBadgeProvider>
              <ChatArea />
            </GlobalBadgeProvider>
          </MainContent>
        )}
      </ContentAreaContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  min-width: 400px;
  height: 100vh;
  max-width: 100%;
  overflow: clip;
  background-color: #0e0e10;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1; /* Take remaining space after the top bar */
  height: calc(100vh - var(--top-bar-height));
  background-color: #0e0e10;
`

export default StreamerPage
