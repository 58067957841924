import { Theme } from "@radix-ui/themes"
import StreamerPage from "pages/StreamerPage"
import ActiveUserProvider from "providers/ActiveUserProvider"
import CommandProvider from "providers/CommandProvider"
import EmoteProvider from "providers/EmoteProvider"
import { lazy, PropsWithChildren, Suspense, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import { RootState } from "store"
import { useToken } from "./contexts/TokenContext"
import { UserOnStreamerProvider } from "./contexts/UserOnStreamerProfileContext"
import WebSocketProvider from "./contexts/WebSocketContext"
import LoadingSpinner from "./misc/loading/LoadingSpinner"
import LandingPage from "./newlanding/NewLanding"
import PrivacyPolicy from "./routes/privacypolicy/PrivacyPolicy"
import TermsOfService from "./routes/termsofservice/TermsOfService"
import AccountUnsuccessfulLinkDialog from "./services/AccountUnsuccessfulLinkDialog"
import SettingsOverlay from "./settings/SettingsOverlay"
import PrivacyPolicyMRPG from "./routes/privacypolicy/PrivacyPolicyMRPG"

const EmbeddableChatPage = lazy(() => import("pages/EmbeddableChatPage"))

function Content() {
  const navigate = useNavigate()
  const location = useLocation()

  const { token } = useToken()

  const isLoading = useSelector((state: RootState) => state.loading)
  const isSettingsOpen = useSelector(
    (state: RootState) => state.settings.isSettingsOpen
  )

  useEffect(() => {
    // Check for token directly from local storage

    // If there is a token and we're on the root path, navigate to /cowsep
    if (token && location.pathname === "/") {
      navigate("/cowsep")
    }
  }, [navigate, location.pathname, token])

  useEffect(() => {
    // Define the event listener
    const handleMessage = (event: any) => {
      // Implement origin check and message handling logic
      if (event.data && event.data.type === "pong") {
        return // Skip logging if the message type is 'pong'
      }

      if (event.data.type === "console_log") {
        console.log("Our Extension says:", event.data.message)
      }
    }

    // Add event listener
    window.addEventListener("message", handleMessage)

    // Cleanup listener on component unmount
    return () => window.removeEventListener("message", handleMessage)
  }, [])

  return (
    <Theme appearance="dark" accentColor="yellow" radius="small">
      <AccountUnsuccessfulLinkDialog />

      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route
          path="/:username"
          // This is a "layout route" that will render the child routes in the <Outlet />
          element={
            <Providers>
              <Outlet />

              {isSettingsOpen && <SettingsOverlay />}
              {isLoading && <LoadingSpinner />}
            </Providers>
          }
        >
          <Route index element={<StreamerPage />} />
          <Route
            path="chat"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <EmbeddableChatPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/minionrpg/privacy-policy" element={<PrivacyPolicyMRPG />} />
      </Routes>
    </Theme>
  )
}

function Providers({ children }: PropsWithChildren) {
  return (
    <UserOnStreamerProvider>
      <WebSocketProvider>
        <EmoteProvider>
          <CommandProvider>
            <ActiveUserProvider>{children}</ActiveUserProvider>
          </CommandProvider>
        </EmoteProvider>
      </WebSocketProvider>
    </UserOnStreamerProvider>
  )
}

export default Content
