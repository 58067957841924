import React, { createContext, ReactNode, useContext, useState } from "react";

interface ChatBoxContextProps {
  selectedUser: { username: string; twitch_id?: string; platform: string; chatrpgId: string } | null;
  selectUser: (username: string, twitch_id: string | undefined, platform: string, chatrpgId: string) => void;
  closeBox: () => void;
}

const ChatBoxContext = createContext<ChatBoxContextProps | undefined>(undefined);

type ChatBoxProviderProps = {
  children: ReactNode;
};

export const useChatBox = () => {
  const context = useContext(ChatBoxContext);
  if (!context) {
    throw new Error("useChatBox must be used within a ChatBoxProvider");
  }
  return context;
};

export const ChatBoxProvider: React.FC<ChatBoxProviderProps> = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState<{
    username: string;
    twitch_id?: string; // Make twitch_id optional
    platform: string;
    chatrpgId: string;
  } | null>(null);

  const selectUser = (username: string, twitch_id: string | undefined, platform: string, chatrpgId: string) => {
    setSelectedUser({ username, twitch_id, platform, chatrpgId });
  };

  const closeBox = () => setSelectedUser(null);

  return (
    <ChatBoxContext.Provider value={{ selectedUser, selectUser, closeBox }}>
      {children}
    </ChatBoxContext.Provider>
  );
};
