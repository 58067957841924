// src/store/reducers/settingsReducer.ts

// Action Types
const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';

// Action Creators
export const toggleSettings = () => ({
    type: TOGGLE_SETTINGS as typeof TOGGLE_SETTINGS,
});

// State Type
interface SettingsState {
    isSettingsOpen: boolean;
}

// Initial State
const initialState: SettingsState = {
    isSettingsOpen: false,
};

// Reducer with explicit types
const settingsReducer = (state: SettingsState = initialState, action: { type: string }): SettingsState => {
    switch (action.type) {
        case TOGGLE_SETTINGS:
            return { ...state, isSettingsOpen: !state.isSettingsOpen };
        default:
            return state;
    }
};

export default settingsReducer;
