import styled from "styled-components"
import PLATFORMS from "../../config/platformConfig"
import { useGlobalBadges } from "../../contexts/GlobalBadgeContext"
import { useStreamerPageProfile } from "../../contexts/StreamerProfileContext"
import "./styles/ChatBadge.css" // Import the CSS file
import { useUserProfile } from "components/contexts/UserProfileContext"

interface ChatBadgeProps {
  platform: string
  badges?: Record<string, any> // Add Twitch badges
}

function ChatBadge({ platform, badges }: ChatBadgeProps) {
  const { streamerProfile } = useStreamerPageProfile()
  const { globalBadges, chatRpgBadges, kickBadges, youtubeBadges } =
    useGlobalBadges()
  const { profile } = useUserProfile() // Access the user profile

  const shouldShowPlatformBadge = profile?.settings?.enablePlatformChatBadges ?? false

  const getTwitchBadgeUrl = (badgeVersion: string): string => {
    const badge = streamerProfile?.twitchBadges.find(
      (b) => b.badge_amount === badgeVersion
    )

    return badge?.badge_url || ""
  }

  const getKickBadgeUrl = (subscriberDuration: string): string => {
    const sortedBadges = (streamerProfile?.kickBadges ?? [])
      .filter((b) => parseInt(b.badge_amount) <= parseInt(subscriberDuration))
      .sort((a, b) => parseInt(b.badge_amount) - parseInt(a.badge_amount))

    return sortedBadges.length > 0 ? sortedBadges[0].badge_url : ""
  }

  const getBadgeImageUrl = (badgeType: string, badgeVersion: any): string => {
    if (
      (platform === "Twitch" || platform === "TwitchChatRPG") &&
      badgeType === "subscriber"
    ) {
      return getTwitchBadgeUrl(badgeVersion)
    }

    if (platform === "Youtube" || platform === "YoutubeChatRPG") {
      const youtubeGlobalBadge = youtubeBadges.find(
        (badge) => badge.badgeType === badgeVersion.badgeType // Changed from badgeVersion.type to badgeVersion.badgeType
      )

      if (youtubeGlobalBadge) {
        return youtubeGlobalBadge.image
      }

      return ""
    }

    if (platform === "Kick") {
      if (badgeVersion.type === "subscriber") {
        const subscriberDuration = badgeVersion.count.toString()

        return getKickBadgeUrl(subscriberDuration)
      }

      const kickGlobalBadge = kickBadges.find(
        (badge) => badge.badgeType === badgeVersion.type
      )

      if (kickGlobalBadge) {
        return kickGlobalBadge.image
      }

      return ""
    }

    //Twitch or anything Global Badges
    if (platform !== "ChatRPG") {
      const globalBadge = globalBadges.find(
        (badge) => badge.set_id === badgeType
      )

      if (globalBadge) {
        return globalBadge.image_url_1x
      }
    }

    // ChatRPG Global Badges
    const chatRpgBadge = chatRpgBadges.find(
      (badge) => badge.badgeType === badgeType
    )

    if (chatRpgBadge) {
      return chatRpgBadge.image
    }

    // Fallback to Twitch streamer specific badge
    const streamerBadge = streamerProfile?.twitchBadges.find(
      (b) => b.badge_amount === badgeVersion
    )

    return streamerBadge?.badge_url || ""
  }

  const badgeUrl = PLATFORMS[platform]?.badge || ""

  if (!badgeUrl && !badges) return null

  return (
    <Wrapper>
      <div>
        {badgeUrl && shouldShowPlatformBadge ? (
          <img src={badgeUrl} alt="" className="chat-badge" />
        ) : null}

        {badges
          ? Object.entries(badges).map(([badgeType, badgeVersion]) => {
            const src = getBadgeImageUrl(badgeType, badgeVersion)
            if (!src) return null
            return (
              <img key={badgeType} src={src} alt="" className="chat-badge" />
            )
          })
          : null}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.span`
  display: inline-block;
  align-items: center;
  max-width: max-content;
  margin-right: 0.125rem;

  div {
    display: flex;
  }
`

export default ChatBadge
