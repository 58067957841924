import { createAppSlice } from "../utils"

const slice = createAppSlice({
  name: "chat",
  initialState: {
    messages: [],
    buffer: [],
    paused: false,
  },
  reducers: (create) => ({
    setPaused: create.reducer<boolean>((state, action) => {
      state.paused = action.payload
    }),
  }),
  selectors: {
    selectAllMessages: (state) => state.messages,
    selectPaused: (state) => state.paused,
  },
})

export const { setPaused: setChatPaused } = slice.actions

export const {
  selectAllMessages: selectAllChatMessages,
  selectPaused: selectChatPaused,
} = slice.selectors

export default slice.reducer
