import { useSelector } from "react-redux"
import {
  EmbeddedPlayerState,
  selectActivePlayer,
} from "../../store/slices/EmbeddedPlayer"
import {
  selectAllStreamerPageEmbeds,
  selectStreamerPageProfile,
  selectStreamerPageStatus,
  StreamerPageProfile,
  StreamerPageStatus,
} from "../../store/slices/StreamerPage"

export interface Panel {
  id: string
  title: string
  position: string
  imageUrl?: string
  linkUrl?: string
  description?: string
  htmlDescription?: string
}

export interface EmbeddedPlayer {
  platform: string
  username: string
}

export interface Extension {
  clientId: string
  enabled: boolean
  type: string
}

interface Embed extends EmbeddedPlayer {
  url: string
}

interface StreamerProfileMeta {
  streamerProfile: StreamerPageProfile
  status: StreamerPageStatus
  players: Embed[]
  activePlayer: EmbeddedPlayerState
}

// TODO: This is a hook now and not a context, so probably move this to the hooks folder
export function useStreamerPageProfile(): StreamerProfileMeta {
  const status = useSelector(selectStreamerPageStatus)
  const profile = useSelector(selectStreamerPageProfile)
  const players = useSelector(selectAllStreamerPageEmbeds)
  const activePlayer = useSelector(selectActivePlayer)

  return { status, streamerProfile: profile, players, activePlayer }
}
