import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormMessage,
  FormSubmit,
} from "@radix-ui/react-form"
import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  SegmentedControl,
  TextArea,
  TextField,
} from "@radix-ui/themes"
import { useRef } from "react"
import { RxInfoCircled } from "react-icons/rx"
import { styled } from "styled-components"
import {
  Extension,
  ExtensionType,
} from "../../../../../store/reducers/userDeveloperProfileReducer" // Adjust the path as necessary
import { useDeveloperExtensions } from "../../../../extensions/DeveloperExtensionsProvider"
import AlertDialogButton from "../../../../ui/AlertDialogButton"
import InfoBar from "../../../../ui/Callout"

// TODO: Refactor
function ExtensionForm(props: Extension) {
  const {
    _id,
    extensionName,
    description,
    clientId,
    clientSecret,
    buildUrl,
    configUrl,
    approved,
    rejected,
    review,
    type,
    isForStreamer,
  } = props

  const {
    updateExtension,
    saveExtension,
    submitForReview,
    cancelReview,
    removeExtension,
    generateSecret,
    toggleSecret,
  } = useDeveloperExtensions()

  // Keep a previous extension reference so we can do checks
  const prevData = useRef<Extension>(props)

  const hasChanged =
    // Checks if any field has changed
    extensionName?.trim() !== prevData.current.extensionName?.trim() ||
    buildUrl?.trim() !== prevData.current.buildUrl?.trim() ||
    configUrl?.trim() !== prevData.current.configUrl?.trim() ||
    description?.trim() !== prevData.current.description?.trim() ||
    type !== prevData.current.type ||
    isForStreamer !== prevData.current.isForStreamer

  return (
    <Card size="2">
      <Box maxWidth="fit-content" asChild>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            saveExtension(props).then(() => {
              prevData.current = props
            })
          }}
        >
          <Field name="previewImg">
            <Flex align="baseline" justify="between">
              <FormLabel>Preview Image</FormLabel>
            </Flex>

            <FormControl asChild>
              <input
                type="file"
                // value={previewImgUrl}
                // onChange={(e) => {
                //   updateExtension(_id, { previewImgUrl: e.currentTarget.value })
                // }}
                disabled={review}
                // required
              />
            </FormControl>
          </Field>

          <Field name="name">
            <Flex align="baseline" justify="between">
              <FormLabel>Extension Name</FormLabel>
              <FormMessage match="valueMissing" />
            </Flex>

            <FormControl asChild>
              <TextField.Root
                type="text"
                placeholder="Enter name here..."
                value={extensionName}
                onChange={(e) => {
                  updateExtension(_id, { extensionName: e.currentTarget.value })
                }}
                disabled={review}
                required
              />
            </FormControl>
          </Field>

          <Field name="description">
            <Flex align="baseline" justify="between">
              <FormLabel>Description</FormLabel>
              <FormMessage match="valueMissing" />
              <FormMessage match={(value) => value.length < 50}>
                Too short
              </FormMessage>
            </Flex>

            <FormControl asChild>
              <TextArea
                rows={3}
                placeholder="What is this extension all about?"
                value={description}
                onChange={(e) => {
                  updateExtension(_id, { description: e.currentTarget.value })
                }}
                disabled={review}
                // TODO: Why is this not working???
                minLength={50}
                required
              />
            </FormControl>
          </Field>

          <Field name="buildUrl">
            <Flex align="baseline" justify="between">
              <FormLabel>Extension URL (The Embed)</FormLabel>
              <FormMessage match="valueMissing" />
              <FormMessage match="typeMismatch">
                Please enter a valid URL
              </FormMessage>
            </Flex>

            <FormControl asChild>
              <TextField.Root
                type="url"
                placeholder="https://ext.example.com/build"
                value={buildUrl}
                onChange={(e) => {
                  updateExtension(_id, { buildUrl: e.currentTarget.value })
                }}
                disabled={review}
                required
              />
            </FormControl>
          </Field>

          <Field name="configUrl">
            <Flex align="baseline" justify="between">
              <FormLabel>Config URL</FormLabel>
              <FormMessage match="valueMissing" />
              <FormMessage match="typeMismatch">
                Please enter a valid URL
              </FormMessage>
            </Flex>

            <FormControl asChild>
              <TextField.Root
                type="url"
                placeholder="https://ext.example.com/settings"
                value={configUrl}
                onChange={(e) => {
                  updateExtension(_id, { configUrl: e.currentTarget.value })
                }}
                disabled={review}
                required
              />
            </FormControl>
          </Field>

          <Field name="isForStreamer" id="streamer">
            <Flex align="center" gap="2">
              <FormLabel>For Streamer?</FormLabel>

              <FormControl asChild>
                <Checkbox
                  defaultChecked
                  checked={isForStreamer}
                  onCheckedChange={(state) => {
                    updateExtension(_id, {
                      isForStreamer: typeof state === "boolean" ? state : false,
                    })
                  }}
                  disabled={review}
                />
              </FormControl>
            </Flex>
          </Field>

          <Field name="type">
            <Box>
              <FormLabel>Extension Location</FormLabel>
            </Box>

            <FormControl disabled={review} asChild>
              <SegmentedControl.Root
                defaultValue={ExtensionType.Settings}
                value={type}
                onValueChange={(type) => {
                  // TODO
                  updateExtension(_id, {
                    type: type as ExtensionType,
                  })
                }}
                style={{ width: "100%" }}
              >
                {Object.values(ExtensionType).map((type) => (
                  <SegmentedControl.Item key={type} value={type}>
                    {type}
                  </SegmentedControl.Item>
                ))}
              </SegmentedControl.Root>
            </FormControl>
          </Field>

          {clientId && (
            <>
              <Field name="clientId">
                <FormLabel>Client ID</FormLabel>

                <FormControl asChild>
                  <TextField.Root
                    type="text"
                    placeholder="client_a1b2c3..."
                    value={clientId}
                    readOnly // Make it read-only
                    // disabled
                  />
                </FormControl>
              </Field>

              <Field name="clientSecret">
                <FormLabel>Client Secret</FormLabel>

                <Flex gap="2">
                  <FormControl asChild>
                    <TextField.Root
                      type={clientSecret ? "text" : "password"}
                      placeholder="Client Secret"
                      value={clientSecret || "••••••••"} // Use localSecret to display
                      readOnly // Make it read-only
                      style={{ width: "100%" }}
                    />
                  </FormControl>

                  {/* <Button
                    type="button"
                    onClick={() => toggleSecret(_id)}
                    disabled={!clientId}
                  >
                    {clientSecret ? "Hide" : "Show"}
                  </Button> */}

                  <Button
                    type="button"
                    onClick={() => generateSecret(_id, clientId)}
                    disabled={!clientId || review}
                  >
                    Generate
                  </Button>
                </Flex>
              </Field>
            </>
          )}

          <Flex direction="column" gap="2" pt="2">
            {review ? (
              <>
                <InfoBar
                  text="To edit, cancel the pending review."
                  size="1"
                  color="orange"
                />

                <Button onClick={() => cancelReview(clientId)}>
                  Cancel Review
                </Button>
              </>
            ) : (
              <>
                <Flex justify="center" gap="2">
                  <Flex flexGrow="1" flexShrink="1" flexBasis="0" asChild>
                    <FormSubmit asChild>
                      <Button
                        disabled={Boolean(clientId && (!hasChanged || review))}
                      >
                        Save
                      </Button>
                    </FormSubmit>
                  </Flex>

                  <Button
                    type="reset"
                    color="gray"
                    onClick={() => {
                      updateExtension(_id, {
                        extensionName: prevData.current.extensionName,
                        description: prevData.current.description,
                        buildUrl: prevData.current.buildUrl,
                        configUrl: prevData.current.configUrl,
                      })
                    }}
                    disabled={
                      review ||
                      !hasChanged ||
                      !(
                        extensionName?.trim() &&
                        description?.trim() &&
                        buildUrl?.trim() &&
                        configUrl?.trim()
                      )
                    }
                  >
                    {clientId ? "Revert" : "Clear"}
                  </Button>
                </Flex>

                {clientId && (
                  <Button
                    type="button"
                    color="grass"
                    onClick={() => submitForReview(clientId)}
                    disabled={Boolean(
                      approved ||
                        (rejected && !hasChanged) ||
                        // Ensures essential fields are not empty
                        !(
                          extensionName?.trim() &&
                          description?.trim() &&
                          buildUrl?.trim() &&
                          configUrl?.trim()
                        )
                    )}
                  >
                    Submit for Review
                  </Button>
                )}

                {approved ? (
                  <AlertDialogButton
                    title="Remove Extension"
                    description="Are you sure? This will permanently remove the extension
                      and it will no longer be available to all users."
                    warning="This action cannot be undone."
                    trigger={
                      <Button type="button" color="red" disabled={review}>
                        <RxInfoCircled /> Remove
                      </Button>
                    }
                    onConfirm={() => {
                      // TODO: What to do when confirmed?
                    }}
                  />
                ) : (
                  <Button
                    type="button"
                    color="red"
                    onClick={() => removeExtension(clientId)}
                    disabled={review}
                  >
                    Remove
                  </Button>
                )}
              </>
            )}
          </Flex>

          <Box position="absolute" top="2" right="2">
            {!review && clientId && !approved && !rejected && (
              <Badge color="gray">Draft</Badge>
            )}

            {approved && (
              <Badge title="Approved and Active!" color="grass">
                Approved
              </Badge>
            )}

            {review &&
              !approved && ( // Only show "Under Review" if not approved
                <Badge title="The application is under review">
                  Pending Review
                </Badge>
              )}

            {rejected &&
              !review && ( // Optionally, handle rejected state similarly
                <Badge
                  color="tomato"
                  title="This extension was rejected, try again or e-mail contact@cowsep.com"
                >
                  Rejected
                </Badge>
              )}
          </Box>
        </Form>
      </Box>
    </Card>
  )
}

const Field = styled(FormField)`
  margin-bottom: 0.5rem;

  label {
    text-align: center;
    font-weight: 500;
    line-height: 2.1;
  }

  &[id="streamer"] {
    padding-top: 0.375rem;
  }

  span {
    font-size: 0.875rem;
  }

  span[id] {
    color: var(--red-9);
  }
`

export default ExtensionForm
