import { createSelector } from "@reduxjs/toolkit"
import axios from "axios"
import { TExtensionType } from "../../components/extensions/IExtensions"
import { SERVER_URL } from "../../env"
import { ExtensionType } from "../reducers/userDeveloperProfileReducer"
import { createAppSlice } from "../utils"

interface StreamerSettings {
  donateButton?: string
  placeholder?: string
}

interface Panel {
  id: string
  title: string
  position: string
  imageUrl?: string
  linkUrl?: string
  description?: string
  htmlDescription?: string
}

interface TwitchBadge {
  badge_url: string
  badge_amount: string
}

interface EmbeddedPlayer {
  platform: string
  username: string
}

interface AboutMePlatformIcons {
  platform: string
  username: string
}

interface PointsConfiguration {
  pointsPerHour?: number
}

export interface IStreamerExtension {
  extensionName: string
  clientId: string
  enabled: boolean
  type: TExtensionType
  buildUrl: string
  configUrl: string
  bottomBarImage: string
  adminView: boolean
  buildLocalUrl?: string
}

export interface StreamerPageProfile {
  chatrpg_id: string
  chatrpg_username: string
  aboutMePlatformIcons: AboutMePlatformIcons[]
  embeddedPlayers: EmbeddedPlayer[]
  panels: Panel[]
  extensions: IStreamerExtension[]
  twitchBadges: TwitchBadge[]
  chatrpg_profilePicture?: string
  chatrpg_partner?: boolean // Whether or not they're a partner, for settings and showing up checkmark.
  channelInfo?: string
  twitch_username?: string
  twitchId?: string
  youtubeChannelId?: string
  tiktok_username?: string
  followers?: number
  settings?: StreamerSettings
  elevatedTwitch?: boolean
  kick_username?: string
  kickId?: string
  kickChatroomId?: string
  kickBadges?: TwitchBadge[]
  sevenTv?: boolean
  pointsConfiguration?: PointsConfiguration
  latestYoutubeVideoIds?: string[]

  /**
   * Handles showing `Twitch Only` badge, and removing ChatRPG chat badge.
   */
  premade?: boolean
}

export type StreamerPageStatus = "idle" | "loading" | "error"

interface Embed extends EmbeddedPlayer {
  url: string
}

interface StreamerPageSlice {
  status: StreamerPageStatus
  profile: StreamerPageProfile
}

const initialState: StreamerPageSlice = {
  status: "loading",
  profile: {
    chatrpg_id: "",
    chatrpg_username: "",
    aboutMePlatformIcons: [],
    embeddedPlayers: [],
    panels: [],
    extensions: [],
    twitchBadges: [],
  },
}

interface StreamerResponse {
  streamerProfile: StreamerPageProfile
  extensions: {
    // uhhh how did this happen lol
    extensions: IStreamerExtension
  }[]
}

const streamerPageSlice = createAppSlice({
  name: "streamerPage",
  initialState,
  reducers: (create) => ({
    // TODO: See if Redux `createApi` can handle this
    fetchStreamerProfile: create.asyncThunk(
      async (username: string, { signal }) => {
        try {
          const response = await axios.get<StreamerResponse>(
            `${SERVER_URL}/profile/streamer/${username}`,
            { signal }
          )
          // console.log("Fetch successful, received data:", response.data)

          const { extensions: fetchedExtensions, streamerProfile } =
            response.data

          const updatedExtensions = streamerProfile.extensions.map(
            (extension) => {
              const matchingExtension = fetchedExtensions.find(
                (e) => e.extensions.clientId === extension.clientId
              )

              // console.log("Searching for clientId:", extension.clientId)
              // console.log(
              //   "Matching extension for clientId " + extension.clientId + ":",
              //   matchingExtension
              // )

              if (!matchingExtension) return extension

              return { ...extension, ...matchingExtension.extensions }
            }
          )

          // Update streamerProfile with the new extensions data
          const updatedStreamerProfile = {
            ...streamerProfile,
            extensions: updatedExtensions,
          }

          return updatedStreamerProfile
        } catch (error) {
          console.error("fetchStreamerProfile failed:", error)
          throw error
        }
      },
      {
        pending: (state) => {
          state.status = "loading"
        },
        rejected: (state) => {
          state.status = "error"
          document.title = "ChatRPG"
        },
        fulfilled: (state, action) => {
          state.profile = action.payload
          state.status = "idle"
          document.title = `${action.payload.chatrpg_username} - ChatRPG`
        },
      }
    ),

    setStreamerProfile: create.reducer<StreamerPageProfile>((state, action) => {
      state.profile = action.payload
    }),

    updatePanels: create.reducer<Panel[]>((state, action) => {
      state.profile.panels = action.payload
    }),

    addYoutubeVideoId: create.reducer<string>((state, action) => {
      const { payload } = action

      if (!state.profile.latestYoutubeVideoIds) {
        state.profile.latestYoutubeVideoIds = [payload]
      }

      if (!state.profile.latestYoutubeVideoIds.includes(payload)) {
        state.profile.latestYoutubeVideoIds.push(payload)
      }
    }),

    removeYoutubeVideoId: create.reducer<string>((state, action) => {
      const { payload } = action

      if (state.profile.latestYoutubeVideoIds) {
        state.profile.latestYoutubeVideoIds =
          state.profile.latestYoutubeVideoIds.filter((id) => id !== payload)
      }
    }),
  }),

  selectors: {
    selectStreamerStatus: (state) => state.status,
    selectStreamerProfile: (state) => state.profile,
    selectAllStreamerEmbeds: createSelector(
      (state: StreamerPageSlice) => state.profile,
      (profile) => {
        const streamerEmbeddedPlayers = profile.embeddedPlayers.map<Embed>(
          (player) => ({
            ...player,
            url: getEmbeddedPlayerUrl(profile, player.platform),
          })
        )

        if (!profile.latestYoutubeVideoIds) {
          return streamerEmbeddedPlayers
        }

        if (profile.latestYoutubeVideoIds.length > 0) {
          return (
            streamerEmbeddedPlayers
              // Remove original Youtube embed
              .filter((player) => player.platform !== "Youtube")
              .concat(
                profile.latestYoutubeVideoIds.map<Embed>((id) => ({
                  platform: "Youtube",
                  username: profile.chatrpg_username,
                  url: getEmbeddedPlayerUrl(profile, "Youtube", id),
                }))
              )
          )
        }

        return streamerEmbeddedPlayers
      }
    ),
    selectStreamerExtensions: (state) => {
      return state.profile.extensions
    },
    selectStreamerBottomBarExtensions: createSelector(
      (state: StreamerPageSlice) => state.profile.extensions,
      (extensions) => (administrator?: boolean) =>
        extensions.filter(
          ({ type, enabled, adminView }) =>
            type === ExtensionType.BottomBar &&
            enabled &&
            (!adminView || (adminView && administrator))
        )
    ),
  },
})

function getEmbeddedPlayerUrl(
  profile: StreamerPageSlice["profile"],
  platform: string,
  youtubeVideoId?: string
) {
  const currentHost = window.location.hostname

  switch (platform) {
    case "Twitch":
      return `https://player.twitch.tv/?channel=${profile.twitch_username}&parent=${currentHost}`

    case "Trovo":
      return `https://player.trovo.live/embed/player?streamername=${profile.chatrpg_username}`

    case "Youtube":
      if (youtubeVideoId) {
        return `https://www.youtube.com/embed/${youtubeVideoId}`
      }

      return `https://www.youtube.com/embed/live_stream?channel=${profile.youtubeChannelId}`

    case "Kick":
      return `https://player.kick.com/${profile.kick_username}`

    default:
      throw new Error(`Invalid embedded platform: ${platform}`)
  }
}

export const {
  fetchStreamerProfile: fetchStreamerPageProfile,
  setStreamerProfile: setStreamerPageProfile,
  updatePanels: updateStreamerPagePanels,
  addYoutubeVideoId: addStreamerPageYoutubeVideoId,
  removeYoutubeVideoId: removeStreamerPageYoutubeVideoId,
} = streamerPageSlice.actions

export const {
  selectStreamerStatus: selectStreamerPageStatus,
  selectStreamerProfile: selectStreamerPageProfile,
  selectAllStreamerEmbeds: selectAllStreamerPageEmbeds,
  selectStreamerExtensions: selectStreamerPageExtensions,
  selectStreamerBottomBarExtensions: selectStreamerPageBottomBarExtensions,
} = streamerPageSlice.selectors

export default streamerPageSlice.reducer
