import { MdExtension, MdWidgets } from "react-icons/md"
import { RiStore3Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"
import { SettingsButton, SettingsCategory } from "../CategorySharedStyles"

const options = [
  { id: "Extension Store", label: "Store", Icon: RiStore3Fill },
  {
    id: "User Installed Extensions",
    label: "Installed Extensions",
    Icon: MdExtension,
  },
]

function ExtensionSettings() {
  const extensions = useSelector((state: RootState) => state.streamerExtensions)

  return (
    <SettingsCategory>
      <h3>Extensions</h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}

        {/* Dynamically render options for each extension */}
        {extensions.map((extension) => (
          <li key={extension.clientId}>
            <SettingsButton value={extension.clientId}>
              <MdWidgets /> <span>{extension.extensionName}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default ExtensionSettings
