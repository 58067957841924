import { PiArrowSquareRightBold } from "react-icons/pi"
import { styled } from "styled-components"
import { media } from "../../styled.utils"
import Container from "../Container.styled"

const benefits = [
  {
    header: "|Level up| your engagement",
    color: "#0cce6b",
    description:
      "Turn your stream into an interactive playground. Reward viewers, boost engagement, and build a community that thrives!",
  },
  {
    header: "Forge |deeper| connections",
    color: "#e71d36",
    description:
      "Create unforgettable moments with interactive challenges, loyalty systems, and more. Gamify your stream, build deeper connections, and watch your community flourish!",
    link: {
      label: "Check out this feature",
      url: "",
    },
  },
  {
    header: "Unlock |community loyalty|",
    color: "#39a0ed",
    description:
      "Streamers, this is your secret weapon to unlock community loyalty. Interactive challenges, rewards, and leaderboards – the ultimate recipe for a thriving gamer fanbase!",
  },
]

function parseHeader(header: string) {
  const matches = /(.*)\|(.*)\|(.*)/i.exec(header)

  if (!matches) return header

  const [textToStyle] = matches.slice(2)

  return (
    <>
      {matches[1]}
      <span>{textToStyle}</span>
      {matches[3]}
    </>
  )
}

function Benefits() {
  return (
    <Wrapper as="section">
      {benefits.map(({ header, color, description, link }) => (
        <Benefit key={header}>
          <BenefitImage>
            <img src="/assets/UI/Landing/placevideo.png" alt="video placeholder" />
          </BenefitImage>

          <BenefitBody $highlightColor={color}>
            <BenefitTitle>{parseHeader(header)}</BenefitTitle>
            <BenefitDescription>{description}</BenefitDescription>

            {link ? (
              <BenefitLink href={link.url}>
                {link.label}
                <span>
                  <PiArrowSquareRightBold />
                </span>
              </BenefitLink>
            ) : null}
          </BenefitBody>
        </Benefit>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 6rem;

  ${media.md`
    gap: 9rem;
  `}

  ${media.xl`
    gap: calc(var(--space-4xl) * 1.5);
  `}

  & > :nth-child(even) {
    flex-direction: column;

    ${media.lg`
      flex-direction: row-reverse;
    `}
  }
`

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;

  ${media.lg`
    flex-direction: row;
  `}
`

const BenefitImage = styled.div`
  & > img {
    min-width: 14rem;
  }
`

const BenefitTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: calc(var(--step-3) * 0.95);
  font-weight: 700;
`

const BenefitLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1.375rem;

  --font-size: var(--step-1);

  color: var(--highlight-color);
  font-size: var(--font-size);
  font-weight: 600;

  span > svg {
    fill: var(--highlight-color);
    font-size: calc(var(--font-size) + 0.5rem);
  }
`

const BenefitBody = styled.div<{ $highlightColor: string }>`
  max-width: 45ch;

  ${media.xl`
    max-width: 50ch;
  `}

  ${BenefitTitle} span {
    color: ${({ $highlightColor }) => $highlightColor};
  }

  ${BenefitLink} {
    --highlight-color: ${({ $highlightColor }) => $highlightColor};
  }
`

const BenefitDescription = styled.p`
  font-size: var(--step-0);
  font-weight: 500;
`

export default Benefits
