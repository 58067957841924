import { Box } from "@radix-ui/themes"
import React from "react"
import { useSelector } from "react-redux"
import { selectAllExtensionJWT } from "../../../../../../store/slices/Auth"
import { IExtension } from "../../../../../extensions/IExtensions"

interface EmbeddedExtensionPageProps {
  extension: IExtension
}

const EmbeddedExtensionPage: React.FC<EmbeddedExtensionPageProps> = ({
  extension,
}) => {
  const iframeJwt = useSelector(selectAllExtensionJWT)(extension.clientId)

  return (
    <Box width="100%" height="100%" p="4" asChild>
      {iframeJwt ? (
        <iframe
          src={`${extension.configUrl}?token=${iframeJwt}`}
          className="embeddedProject"
          title={extension.extensionName}
          style={{ width: "100%", height: "2000px", border: "none" }}
        />
      ) : (
        <div>Loading...</div>
      )}
    </Box>
  )
}

export default EmbeddedExtensionPage
