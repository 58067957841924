// src/store/reducers/editModeReducer.ts
export const ENABLE_EDIT_MODE = 'ENABLE_EDIT_MODE';
export const DISABLE_EDIT_MODE = 'DISABLE_EDIT_MODE';

type EditModeState = boolean;

const initialState: EditModeState = false;

const editModeReducer = (state = initialState, action: { type: string }): EditModeState => {
  switch (action.type) {
    case ENABLE_EDIT_MODE:
      return true;
    case DISABLE_EDIT_MODE:
      return false;
    default:
      return state;
  }
};

export default editModeReducer;
