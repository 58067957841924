import { TabsContent } from "@radix-ui/react-tabs";
import { Box, ScrollArea } from "@radix-ui/themes";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import DeveloperExtensionsProvider from "../../extensions/DeveloperExtensionsProvider";
import AdminExtensionQueue from "./panels/adminsettings/AdminExtensionQueue";
import AdminTestAlerts from "./panels/adminsettings/AdminTestAlerts";
import DeveloperApplicationOptions from "./panels/developersettings/DeveloperApplicationOptions";
import InstalledExtensions from "./panels/extensions/inventory/InstalledExtensions";
import ExtensionStore from "./panels/extensions/store/ExtensionsStore";
import EmbeddablePlayerOptions from "./panels/streamersettings/embeddedplayers/EmbeddablePlayerOptions";
import PlatformIcons from "./panels/streamersettings/platformiconsaboutme/PlatformIcons";
import StreamerAccountLinkingOptions from "./panels/streamersettings/StreamerAccountLinkingOptions";
import StreamerSettings from "./panels/streamersettings/streamersettings/StreamerSettings";
import AccountLinkingOptions from "./panels/usersettings/accountlinking/AccountLinkingOptions";
import UserChatSettings from "./panels/usersettings/chat/UserChatSettings";
import OptionSwitch from "./panels/usersettings/optionalitems/OptionalOptions";
import EmbeddedExtensionPage from "./panels/extensions/custom/EmbeddedExtensionPage";
import StreamerTestNotifications from "./panels/streamersettings/streamernotifications/StreamerTestNotifications";

// The IDs here HAS to match up against IDs in SettingsSidebar
// options arrays, otherwise nothing will show up
const tabContents = [
  // Admin
  { id: "Admin Settings", Component: Placeholder },
  { id: "Admin Extension Queue", Component: AdminExtensionQueue },
  { id: "Admin Test Alerts", Component: AdminTestAlerts },

  // User
  { id: "My Account", Component: AccountLinkingOptions },
  { id: "Layout", Component: OptionSwitch },
  { id: "Chat", Component: UserChatSettings },

  // UserOnStreamer
  { id: "User on Streamer General Settings", Component: Placeholder },
  { id: "Chat Badges", Component: Placeholder },

  // Streamer
  { id: "Streamer Settings", Component: StreamerSettings },
  { id: "Embedded Platforms", Component: EmbeddablePlayerOptions },
  { id: "Platform Icons", Component: PlatformIcons },
  { id: "Streamer Account Linking", Component: StreamerAccountLinkingOptions },
  { id: "Streamer Test Notifications", Component: StreamerTestNotifications },

  // Extensions
  { id: "Extension Store", Component: ExtensionStore },
  { id: "User Installed Extensions", Component: InstalledExtensions },

  // Developer
  { id: "Developer Settings", Component: DeveloperSettings },
];

function Placeholder() {
  return <div>Coming Soon!</div>;
}

function DeveloperSettings() {
  return (
    <DeveloperExtensionsProvider>
      <DeveloperApplicationOptions />
    </DeveloperExtensionsProvider>
  );
}

function SettingsMainContent() {
  const extensions = useSelector((state: RootState) => state.streamerExtensions);

  return (
    <ScrollArea>
      <Box p={{ initial: "4", md: "6" }} maxWidth="100vw">
        {tabContents.map(({ id, Component: SettingsContent }) => (
          <TabsContent key={id} value={id}>
            <SettingsContent />
          </TabsContent>
        ))}

        {extensions.map((extension) => (
          <TabsContent key={extension.clientId} value={extension.clientId}>
            <EmbeddedExtensionPage extension={extension} />
          </TabsContent>
        ))}
      </Box>
    </ScrollArea>
  );
}

export default SettingsMainContent;
