import { Flex, Grid, Heading, TextField } from "@radix-ui/themes"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Extension,
  setUserDeveloperProfile,
} from "../../../../../store/reducers/userDeveloperProfileReducer"
import { useToken } from "../../../../contexts/TokenContext"
import {
  approveExtension,
  getExtensionQueue,
  rejectExtension,
} from "../../../../extensions/api/Admin"
import AlertDialogButton from "../../../../ui/AlertDialogButton"
import ExtensionCard, {
  ExtensionCardButton,
} from "../extensions/components/ExtensionCard"

interface QueueExtensionDetails {
  chatrpg_id: string
  chatrpg_username: string
  extension: Extension
}

function AdminExtensionQueue() {
  const { token } = useToken()
  const dispatch = useDispatch()
  const [rejectionReason, setRejectionReason] = useState('');

  const [queueExtensions, setQueueExtensions] = useState<
    QueueExtensionDetails[]
  >([])

  useEffect(() => {
    const fetchExtensions = async () => {
      dispatch({ type: "START_LOADING" })
      const queue = await getExtensionQueue(token)
      dispatch({ type: "STOP_LOADING" })

      if (queue.length === 0) {
        console.log("No extensions to fetch")
        // Optionally set some state to indicate no extensions are available
      }
      console.log(queue)

      const transformedData = queue.map((item: any) => ({
        chatrpg_id: item.chatrpg_id,
        chatrpg_username: item.chatrpg_username,
        extension: item.extensions,
      }))

      setQueueExtensions(transformedData)
    }

    fetchExtensions()
  }, [dispatch, token])

  const handleApprove = async (clientId: string, chatrpg_id: string) => {
    dispatch({ type: "START_LOADING" })
    console.log("we're approving")
    try {
      const updatedProfile = await approveExtension(clientId, chatrpg_id, token)

      // Assuming the server responds with the updated queue minus the approved extension
      setQueueExtensions((prev) =>
        prev.filter((ext) => ext.extension.clientId !== clientId)
      )

      console.log("Approval response:", updatedProfile)
      dispatch(setUserDeveloperProfile(updatedProfile))
    } catch (error) {
      console.error("Error approving extension:", error)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  const handleReject = async (clientId: string, chatrpg_id: string, reason: string) => {
    dispatch({ type: "START_LOADING" });
    try {
      const updatedProfile = await rejectExtension(clientId, chatrpg_id, reason, token);
      setQueueExtensions(prev =>
        prev.filter(({ extension }) => extension.clientId !== clientId)
      );
      console.log("Rejection response:", updatedProfile);
      dispatch(setUserDeveloperProfile(updatedProfile));
    } catch (error) {
      console.error("Error rejecting extension:", error);
    } finally {
      dispatch({ type: "STOP_LOADING" });
    }
  }

  return (
    <div>
      <Flex justify="between">
        <Heading as="h1" mb="5">
          Extension Queue
        </Heading>
      </Flex>

      <Grid
        columns="repeat(auto-fill, minmax(24rem, 1fr))"
        rows="masonry"
        gap="3"
      >
        {queueExtensions.map(({ chatrpg_id, chatrpg_username, extension }) => (
          <ExtensionCard
            key={extension.clientId}
            {...extension}
            imageUrl="https://images.unsplash.com/photo-1617050318658-a9a3175e34cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&q=80"
            name={extension.extensionName}
            author={chatrpg_username}
            metadata={{ ...extension, chatrpg_id, chatrpg_username }}
            queue
            actionButtons={
              <>
                <ExtensionCardButton
                  size="2"
                  color="grass"
                  onClick={() => handleApprove(extension.clientId, chatrpg_id)}
                >
                  Approve
                </ExtensionCardButton>
                
                <AlertDialogButton
                  title="Rejection Reason"
                  description="If possible, please provide a reason."
                  trigger={<ExtensionCardButton size="2" color="red">Reject</ExtensionCardButton>}
                  onConfirm={() => handleReject(extension.clientId, chatrpg_id, rejectionReason)}
                >
                  <TextField.Root
                    value={rejectionReason}
                    onChange={(event) => setRejectionReason(event.target.value)}
                    mb="4"
                  />
                </AlertDialogButton>
              </>
            }
          />
        ))}
      </Grid>
    </div>
  )
}

export default AdminExtensionQueue
