// AddImageTextPanel.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, useStreamerPageProfile } from '../../../../../contexts/StreamerProfileContext';
import { useToken } from '../../../../../contexts/TokenContext';
import './AddImageTextPanel.css'; // Create and import the corresponding CSS file
import handlePanelRemove from './handlePanelRemove';
import handlePanelSubmit from './handlePanelSubmit';
import ImageUploader from './imageupload/UploadImageTextPanel';

type AddImageTextPanelProps = {
    existingPanel?: Panel; // Optional prop for an existing panel
};

const AddImageTextPanel: React.FC<AddImageTextPanelProps> = ({ existingPanel }) => {
    const [title, setTitle] = useState(existingPanel?.title || '');
    const [linkUrl, setLinkUrl] = useState(existingPanel?.linkUrl || '');
    const [description, setDescription] = useState(existingPanel?.description || '');
    const [panelId, setPanelId] = useState(existingPanel?.id || Date.now().toString());
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { token } = useToken();
    const dispatch = useDispatch();
    const { streamerProfile } = useStreamerPageProfile();

    useEffect(() => {
        setPanelId(existingPanel?.id || Date.now().toString());
    }, [existingPanel]);

    const onFileSelect = (file: File) => {
        setSelectedFile(file);
    };

    const panelExists = streamerProfile?.panels.some(panel => panel.id === panelId);

    const isContentUnchanged = () => {
        if (!existingPanel) return false;
        return existingPanel.title === title &&
               existingPanel.linkUrl === linkUrl &&
               existingPanel.description === description &&
               existingPanel.id === panelId; // Add more comparisons if needed
    };

    
    const handleSubmit = async () => {
        handlePanelSubmit(
            title,
            panelId,
            selectedFile,
            streamerProfile,
            // setStreamerProfile,
            token,
            dispatch,
            existingPanel, // Pass existingPanel
            setTitle, // Pass setTitle to reset title
            setLinkUrl, // Pass setLinkUrl to reset link URL
            setDescription, // Pass setDescription to reset description
            setPanelId,
            setSelectedFile,
            linkUrl,  // Pass linkUrl
            description  // Pass description
        );
    };

    const handleRemove = async () => {
        handlePanelRemove(
            panelId,
            token,
            dispatch,
            // setStreamerProfile
        );
    };

    return (
        <div className="add-image-text-panel">
            <label className="input-label">Panel Title</label>
            <input type="text" className="panel-title-input" placeholder="Enter title here" value={title} onChange={(e) => setTitle(e.target.value)} />

            <ImageUploader onFileSelect={onFileSelect} existingImageUrl={existingPanel?.imageUrl} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />

            <label className="input-label">Image Links To:</label>
            <input type="text" className="image-link-input" placeholder="Enter URL here" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />

            <label className="input-label">Description</label>
            <textarea className="panel-description-input" placeholder="Enter description here" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>

            <div className="panel-actions">
            <button className="submit-button" onClick={handleSubmit} disabled={panelExists && isContentUnchanged()}>
                Submit
            </button>
                {panelExists && (
                    <button className="remove-button" onClick={handleRemove}>Remove</button>
                )}
            </div>
        </div>
    );
};

export default AddImageTextPanel;
