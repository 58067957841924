import { Reducer } from "redux";

// src/store/reducers/userStreamerProfileReducer.ts
export enum ExtensionType {
    Settings = "Settings",
    BottomBar = "BottomBar",
    Chat = "Chat",
    Points = "Points",
}


export type Extension = {
    _id: string;
    extensionName: string;
    description: string;
    clientId: string;
    clientSecret: string;
    type: ExtensionType; // Add the type field
    buildUrl: string; 
    configUrl: string; 
    approved: boolean; 
    rejected: boolean;
    review: boolean;
    isForStreamer: boolean;
    default?: boolean;
};


export type UserDeveloperProfile = {
    chatrpg_username: string;
    chatrpg_id: string;
    extensions: Extension[];
    // Add other fields as needed
};

export type PartialUserDeveloperProfile = Partial<UserDeveloperProfile>

export const SET_USER_DEVELOPER_PROFILE = 'SET_USER_DEVELOPER_PROFILE';
export const CLEAR_USER_DEVELOPER_PROFILE = 'CLEAR_USER_DEVELOPER_PROFILE';
export const UPDATE_USER_DEVELOPER_PROFILE_FIELD = 'UPDATE_USER_DEVELOPER_PROFILE_FIELD';
export const ADD_USER_DEVELOPER_PROFILE_EXT = "ADD_USER_DEVELOPER_PROFILE_EXT"
export const UPDATE_USER_DEVELOPER_PROFILE_EXT = 'UPDATE_USER_DEVELOPER_PROFILE_EXT';
export const DELETE_USER_DEVELOPER_PROFILE_LOCAL_EXT = 'DELETE_USER_DEVELOPER_PROFILE_LOCAL_EXT';


export const setUserDeveloperProfile = (profile: UserDeveloperProfile) => ({
    type: SET_USER_DEVELOPER_PROFILE,
    payload: profile,
});

export const clearUserStreamerProfile = () => ({
    type: CLEAR_USER_DEVELOPER_PROFILE,
});

export const updateUserDeveloperProfileField = (partialProfile: PartialUserDeveloperProfile) => ({
    type: UPDATE_USER_DEVELOPER_PROFILE_FIELD,
    payload: partialProfile,
});

export const addUserDeveloperProfileExtension = (extension: Extension) => ({
    type: ADD_USER_DEVELOPER_PROFILE_EXT,
    payload: extension,
});

export const updateUserDeveloperProfileExtension = (extension: Partial<Extension>) => ({
    type: UPDATE_USER_DEVELOPER_PROFILE_EXT,
    payload: extension,
});

// We assume there is only one local (unsaved) extension
export const deleteUserDeveloperProfileLocalExtension = () => ({
    type: DELETE_USER_DEVELOPER_PROFILE_LOCAL_EXT,
    payload: null,
});

type UserDeveloperProfileState = UserDeveloperProfile | PartialUserDeveloperProfile | null;

const initialState: UserDeveloperProfileState = null;

const userDeveloperProfileReducer: Reducer<UserDeveloperProfileState> = (
    state = initialState,
    action: { type: string; payload?: UserDeveloperProfile | PartialUserDeveloperProfile }
): UserDeveloperProfileState => {
    switch (action.type) {
        case SET_USER_DEVELOPER_PROFILE:
            if (action.payload === undefined || action.payload === null) {
                return null;
            }
            return action.payload;
        case UPDATE_USER_DEVELOPER_PROFILE_FIELD:
            if (state) {
                return { ...state, ...(action.payload as PartialUserDeveloperProfile) };
            }
            return state;
        case ADD_USER_DEVELOPER_PROFILE_EXT:
            if (state) {
                if ((state.extensions ?? []).filter(ext => !ext.clientId).length > 0) {
                    // Do not add if there are at least one unsaved extension
                    return state
                }
                const payload = action.payload as Extension
                return {
                  ...state,
                  extensions: [...(state.extensions ?? []), payload],
                }
            }
            return state;
        case UPDATE_USER_DEVELOPER_PROFILE_EXT:
            if (state) {
                const payload = action.payload as Partial<Extension>
                return { ...state, extensions: state.extensions?.map(ext => {                    
                    if (ext._id === payload._id) {
                        return { ...ext, ...payload }
                    }
                    return ext
                }) };
            }
            return state;
        case DELETE_USER_DEVELOPER_PROFILE_LOCAL_EXT:
            if (state) {
                return { ...state, extensions: (state.extensions ?? []).filter(ext => !!ext.clientId) };
            }
            return state;
        case CLEAR_USER_DEVELOPER_PROFILE:
            return null;
        default:
            return state;
    }
};

export default userDeveloperProfileReducer;
