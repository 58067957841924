import { IoGameController } from "react-icons/io5"
import { RiCheckboxMultipleFill } from "react-icons/ri"
import { TbDeviceMobileMessage } from "react-icons/tb"
import { styled } from "styled-components"
import { media } from "../../styled.utils"
import Card from "../Card.styled"
import Container from "../Container.styled"

const features = [
  {
    Icon: IoGameController,
    title: "Chat Games",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ut delectus velit.",
  },
  {
    Icon: TbDeviceMobileMessage,
    title: "Mobile Friendly",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    Icon: RiCheckboxMultipleFill,
    title: "Multichat",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ut.",
  },
  {
    Icon: IoGameController,
    title: "Chat Games",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ut delectus velit.",
  },
  {
    Icon: TbDeviceMobileMessage,
    title: "Mobile Friendly",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    Icon: RiCheckboxMultipleFill,
    title: "Multichat",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ut.",
  },
]

function Features() {
  return (
    <Wrapper as="section">
      <Header>Features</Header>

      <div>
        {features.map(({ Icon, title, description }, i) => (
          <Feature key={`${title}${i}`}>
            <FeatureIconWrapper>
              <Icon />
            </FeatureIconWrapper>

            <FeatureTitle>{title}</FeatureTitle>
            <FeatureDescription>{description}</FeatureDescription>
          </Feature>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  padding-top: 0;

  & > div {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 3rem;
    width: fit-content;
    justify-items: center;
    align-content: space-evenly;
    justify-content: space-evenly;
    margin: 0 auto;

    ${media.sm`
      grid-template-columns: repeat(2, minmax(0, 1fr));
    `}

    ${media.lg`
      grid-template-columns: repeat(3, minmax(0, 1fr));
    `}
  }
`

const Header = styled.h2`
  margin-bottom: 3rem;
  text-align: center;
  font-size: var(--step-2);
  font-weight: 600;
`

const Feature = styled(Card)`
  max-width: 25ch;
`

const FeatureIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  color: #0cce6b;
  font-size: calc(var(--step-4) * 1.7);
`

const FeatureTitle = styled.h3`
  margin-bottom: 0.75rem;
  text-align: center;
  font-size: calc(var(--step-1) * 0.95);
  font-weight: 600;
`

const FeatureDescription = styled.div`
  font-size: var(--step--0);
`

export default Features
