import { css } from "styled-components"

type CSSArgs = Parameters<typeof css>

export const device = {
  xs: "400px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1536px",
} as const

export const media = {
  /** `@media (min-width: 400px) { ... }` */
  xs: (...args: CSSArgs) => css`
    @media (min-width: ${device.xs}) {
      ${css(...args)};
    }
  `,

  /** `@media (min-width: 640px) { ... }` */
  sm: (...args: CSSArgs) => css`
    @media (min-width: ${device.sm}) {
      ${css(...args)};
    }
  `,

  /** `@media (min-width: 768px) { ... }` */
  md: (...args: CSSArgs) => css`
    @media (min-width: ${device.md}) {
      ${css(...args)};
    }
  `,

  /** `@media (min-width: 1024px) { ... }` */
  lg: (...args: CSSArgs) => css`
    @media (min-width: ${device.lg}) {
      ${css(...args)};
    }
  `,

  /** `@media (min-width: 1280px) { ... }` */
  xl: (...args: CSSArgs) => css`
    @media (min-width: ${device.xl}) {
      ${css(...args)};
    }
  `,

  /** `@media (min-width: 1536px) { ... }` */
  xxl: (...args: CSSArgs) => css`
    @media (min-width: ${device.xxl}) {
      ${css(...args)};
    }
  `,
} as const
