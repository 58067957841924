import React, { useRef, useEffect, ReactNode } from 'react';
import './UserDropDownMenu.css';

type UserDropdownMenuProps = {
    isOpen: boolean;
    toggle: () => void;
    menuItems: ReactNode;
    alignment: 'left' | 'right';
    verticalAlignment?: 'up' | 'down'; // Add this prop for vertical alignment
    toggleRef: React.RefObject<HTMLDivElement>;
    additionalClass?: string; // New prop for additional class
};

const UserDropdownMenu: React.FC<UserDropdownMenuProps> = ({ isOpen, toggle, menuItems, alignment, verticalAlignment = 'down', toggleRef, additionalClass = '' }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
                toggleRef.current && !toggleRef.current.contains(event.target as Node)) {
                toggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [toggle, isOpen, toggleRef]);


    return (
        <div className={`user-dropdown ${alignment} ${verticalAlignment}`} ref={dropdownRef}>
            {isOpen && (
                <div className={`dropdown-menu ${additionalClass}`}>
                    {menuItems}
                </div>
            )}
        </div>
    );
};

export default UserDropdownMenu;
