import { Button } from "@radix-ui/themes"
import { ComponentPropsWithoutRef } from "react"
import { useDispatch } from "react-redux"
import { setUserStreamerProfile } from "../../../../../../store/reducers/userStreamerProfileReducer"
import { PlatformKey } from "../../../../../config/platformConfig"
import { useToken } from "../../../../../contexts/TokenContext"
import { useUserProfile } from "../../../../../contexts/UserProfileContext"
import { linkAccount } from "./LinkAccount"

interface SaveLinkButtonProps extends ComponentPropsWithoutRef<typeof Button> {
  platform: PlatformKey
  data: string
  streamer?: boolean
}

function SaveLinkButton({
  platform,
  data,
  streamer,
  ...props
}: SaveLinkButtonProps) {
  const dispatch = useDispatch()

  const { token } = useToken()
  const { setProfile } = useUserProfile()

  const handleSaveAccount = async () => {
    if (!data) return

    if (platform === "Youtube" && !data.startsWith("UC")) {
      alert(
        'Youtube channel ID, not username. Go to https://studio.youtube.com and copy it from the URL. It starts with "UC".'
      )
      return
    }
    
    dispatch({ type: "START_LOADING" })
    try {
      const response = await linkAccount(platform, data, token)
      console.log(`Successfully saved ${platform} username: ${data}`)
      console.log("Response from linkAccount:", response)

      console.log(`Successfully saved ${platform} username: ${data}`)

      if (streamer) {
        console.log("Setting user streamer profile:", response.streamer)
        dispatch(setUserStreamerProfile(response.streamer))
      } else {
        console.log("Setting user profile:", response.streamer)
        setProfile(response.streamer)
      }
    } catch (error) {
      
      console.error(`Error saving ${platform} username:`, error)
      alert(`Failed to save ${platform} username: ${(error as any).message}`)
    } finally {
      dispatch({ type: "STOP_LOADING" })
    }
  }

  return (
    <Button {...props} onClick={handleSaveAccount}>
      Save
    </Button>
  )
}

export default SaveLinkButton
