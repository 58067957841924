import { styled } from "styled-components"
import { media } from "../../styled.utils"
import BaseButton from "../Button.styled"
import Container from "../Container.styled"
import LoginButtonWithAuth from "../../../ui/button/LoginButton"

function Hero() {
  return (
    <Wrapper>
      <Container>
        <HeadlineContent>
          <Headline>
            Bring your viewers <br /> a little bit closer to you
          </Headline>

          <SubHeadline>
            Gamify your stream and build a more intimate connection with your
            audience!
          </SubHeadline>

          <div>
            <LoginButtonWithAuth buttonText="Sign Me Up!"/>
          </div>
        </HeadlineContent>

        <HeroImage />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  padding-top: calc(var(--space-3xl) * 0.7);
  padding-bottom: 20rem;
  background-color: #0d1f2d;
  overflow: hidden;

  ${media.md`
    padding-top: calc(var(--space-3xl) * 0.85);
    padding-bottom: 16rem;
  `}

  ${media.lg`
    padding-bottom: calc(var(--space-3xl) * 0.85);
  `}
`

const HeadlineContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

const Headline = styled.h1`
  margin-bottom: 0.25rem;
  z-index: 1;

  font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(var(--step-4) * 1.05);
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: 1.25;
  color: white;
`
const SubHeadline = styled.h2`
  z-index: 1;
  max-width: 45ch;
  font-size: var(--step-0);
  line-height: 1.55;
  padding-right: 5rem;
  margin-bottom: 1rem;
  color: white;
`

const CTAButton = styled(BaseButton)`
  border-radius: 0.5rem;
  font-size: calc(var(--step-0) * 1.1);
  font-weight: 600;
  font-style: normal;
  z-index: 9;

  ${media.xl` 
    font-size: calc(var(--step-0) * 0.9);
  `}
`

const HeroImage = styled.div`
  position: absolute;
  right: -20rem;
  top: 12rem;
  border: 2px solid #fff;
  overflow: hidden;
  height: 80rem;
  width: 50rem;

  --rotate: 70deg;
  transform: rotate(var(--rotate));

  &::after {
    content: "";
    position: absolute;
    background-image: url("/assets/UI/Landing/DSC01269.JPG");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    height: 100%;
    width: 100%;
    transform: rotate(calc(var(--rotate) * -1)) translate(-4.5%, -17%);

    ${media.md`
      width: 130%;
      transform: rotate(calc(var(--rotate) * -1)) translate(-18%, -20%);
    `}

    ${media.lg` 
      width: 126%;
      transform: rotate(calc(var(--rotate) * -1)) translate(-18%, -22%);
    `}
  }

  &::before {
    content: "";
    position: absolute;
    /* background-color: #000812; */
    height: 150%;
    width: 100%;
  }

  ${media.xs` 
    right: -16rem;
    top: 9rem;
  `}

  ${media.md`
    top: 3rem;
  `}

  ${media.lg` 
    --rotate: 40deg;
    top: -10rem;
  `}

  ${media.xl`
    right: 0rem;
  `}
`

export default Hero
