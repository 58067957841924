import { Box } from "@radix-ui/themes"
import { MoveableManagerInterface } from "react-moveable"
import styled from "styled-components"

const DimensionViewable = {
  name: "dimensionViewable",
  props: [],
  events: [],

  render(moveable: MoveableManagerInterface) {
    if (!import.meta.env.DEV) return null

    const {
      scale: [scaleX, scaleY],
    } = moveable

    const { top, left, width, height, rotation, origin } = moveable.getRect()
    const [x, y] = origin

    const { round } = Math
    const { format } = Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    })

    // Add key (required)
    // Add class prefix moveable-(required)
    return (
      <Wrapper
        key="dimension-viewer"
        className="moveable-dimension"
        position="absolute"
        top={`${height + 20}px`}
        left={`${width / 2}px`}
      >
        {round(width)} x {round(height)} ({format(scaleX)},{format(scaleY)}) |{" "}
        {round(left)},{round(top)} ({round(x)},{round(y)}) | {round(rotation)}
      </Wrapper>
    )
  },
} as const

const Wrapper = styled(Box)`
  background: #4af;
  border-radius: 2px;
  padding: 2px 4px;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  font-weight: bold;
  will-change: transform;
  transform: translate(-50%, 0px);
`

export default DimensionViewable
