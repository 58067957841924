// InputWidthStyles.ts
import styled from 'styled-components';

export const InputWidthOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const InputWidthOptionName = styled.span`
  margin-right: 10px;
  min-width: 160px;
  font-size: 16px;
  font-family: var(--default-font-family);
  color: white;
  align-self: center;
`;

export const InputWidthInputBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  flex-grow: 1;
  width: 100%;
`;
