import { Card } from "@radix-ui/themes"
import styled from "styled-components"
import { media } from "../../../../../newlanding/styled.utils"

export const LinkContainer = styled(Card)`
  --card-background-color: #242424;
  width: 100%;

  ${media.xl`
    flex: 1 1 0;
  `}
`

export const Platform = styled.div<{ $color: string }>`
  font-family: "SF", sans-serif;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 1.25rem;
  background-color: ${({ $color }) => $color};
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  /* box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5); */

  &:active {
    filter: unset;
  }
`

export const IconImg = styled.img`
  margin-left: 0.5rem;
  width: auto; // Set width to 100px
  height: 40px; // Set height to 100px
`

export const AccountLinkButtonText = styled.span`
  flex-grow: 1;
  text-align: left;
  text-transform: capitalize;
`

export const DeleteAccountButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: darkred;
  }

  &:disabled {
    background-color: grey; // Change to a muted color
    color: darkgray; // Dim the text color as well
    cursor: not-allowed; // Change cursor to indicate the action is not allowed
    opacity: 0.6; // Optionally make the button semi-transparent
    text-decoration: line-through; // Strike-through text to indicate it's unavailable
  }
`
