import { AlertDialog, Box, Button, Flex } from "@radix-ui/themes"
import { PropsWithChildren, ReactNode } from "react"
import InfoBar from "./Callout"

interface AlertDialogButtonProps {
  title: string
  description: string
  trigger: ReactNode
  warning?: string
  onConfirm: () => void
}

function AlertDialogButton({
  children,
  title,
  description,
  trigger,
  warning,
  onConfirm,
}: PropsWithChildren<AlertDialogButtonProps>) {
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        {typeof trigger === "string" ? <Button>{trigger}</Button> : trigger}
      </AlertDialog.Trigger>

      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>{title}</AlertDialog.Title>
        <AlertDialog.Description size="2" mb="2">
          {description}
        </AlertDialog.Description>

        {children && <Box>{children}</Box>}

        {warning && <InfoBar text={warning} size="1" color="red" mt="4" />}

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </AlertDialog.Cancel>

          <AlertDialog.Action>
            <Button variant="solid" color="red" onClick={onConfirm}>
              Confirm
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  )
}

export default AlertDialogButton
