import { Tabs } from "@radix-ui/react-tabs"
import { MouseEvent, TouchEvent, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useMobileContext } from "../contexts/MobileContext"
import SettingsLeftSidebar from "./leftsidebar/SettingsLeftSidebar"
import SettingsMainContent from "./middlemaincontent/SettingsMainContent"
import SettingsRightSidebar from "./rightsidebar/SettingsRightSidebar"

import "./SettingsOverlay.css"

function SettingsOverlay() {
  const [isDragging, setIsDragging] = useState(false)
  const [dragPosition, setDragPosition] = useState(0)
  const dragStartX = useRef(0)
  const { isMobile } = useMobileContext()

  useEffect(() => {
    if (isMobile) {
      // Set the default drag position to fully show the content
      // Adjust the value as needed to fully show the content
      setDragPosition(240)
    }
  }, [isMobile])

  const handleDragStart = (e: MouseEvent | TouchEvent) => {
    setIsDragging(true)
    dragStartX.current = "touches" in e ? e.touches[0].clientX : e.clientX
  }

  const handleDragMove = (e: MouseEvent | TouchEvent) => {
    if (!isDragging) return
    const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
    const deltaX = currentX - dragStartX.current
    const newPosition = Math.min(Math.max(deltaX, -dragPosition), 240)
    setDragPosition(newPosition)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
    setDragPosition(dragPosition > 120 ? 240 : 0)
  }

  return (
    <Wrapper>
      <TabContainer defaultValue="My Account" orientation="vertical">
        <SettingsLeftSidebar />

        <div
          className="settings-main-content"
          style={{ left: isMobile ? `${dragPosition}px` : "0" }}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onMouseMove={(e) => isDragging && handleDragMove(e)}
          onTouchMove={(e) => isDragging && handleDragMove(e)}
          onMouseUp={handleDragEnd}
          onTouchEnd={handleDragEnd}
          onMouseLeave={() => isDragging && handleDragEnd()}
        >
          <SettingsMainContent />
          <SettingsRightSidebar />
        </div>
      </TabContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  z-index: 999;
  background-color: #313338;
`

const TabContainer = styled(Tabs)`
  flex-grow: 1;
  display: flex;
`

export default SettingsOverlay
