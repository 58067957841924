import { MdAdminPanelSettings, MdQueuePlayNext, MdNotificationsActive } from "react-icons/md"
import { SettingsButton, SettingsCategory } from "../CategorySharedStyles"

const options = [
  { id: "Admin Settings", label: "Settings", Icon: MdAdminPanelSettings },
  {
    id: "Admin Extension Queue",
    label: "Extension Queue",
    Icon: MdQueuePlayNext,
  },
  {
    id: "Admin Test Alerts",
    label: "Test Alerts",
    Icon: MdNotificationsActive,
  }
]

function AdminSettings() {
  return (
    <SettingsCategory>
      <h3>Admin Settings</h3>

      <ul>
        {options.map(({ id, label, Icon }) => (
          <li key={id}>
            <SettingsButton value={id}>
              <Icon /> <span>{label}</span>
            </SettingsButton>
          </li>
        ))}
      </ul>
    </SettingsCategory>
  )
}

export default AdminSettings
