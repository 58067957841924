import { useDispatch } from 'react-redux';
import { useSendMessage } from '../chatHelpers/useSendMessage';
import { setChatMessage } from '../../../store/reducers/inputChatReducer';

const SendChatButton = () => {
    const dispatch = useDispatch();
    const sendMessage = useSendMessage();

    const handleClick = () => {
        sendMessage();
        dispatch(setChatMessage('')); // Clear the chat message in Redux after sending
    };

    return (
        <button className="chatButton" onClick={handleClick}>Chat</button>
    );
};

export default SendChatButton;
