import { CopyIcon, CheckIcon } from '@radix-ui/react-icons'; // Ensure these are installed
import { useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { useUserProfile } from '../../../../../contexts/UserProfileContext';
import { Flex, Button, Heading, Text, Card } from '@radix-ui/themes';

const StreamerUrlDisplay: React.FC = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { profile } = useUserProfile();

  const resetCopyStatus = useDebounceCallback(() => {
    setCopySuccess(false);
  }, 3000);

  const handleCopy = async () => {
    const urlToCopy = `https://chatrpg.com/${profile?.chatrpg_username.toLowerCase()}`;
    if (urlToCopy) {
      await navigator.clipboard.writeText(urlToCopy);
      setCopySuccess(true);
      resetCopyStatus();
    }
  };

  if (!profile || !profile.chatrpg_username) {
    return <Text color="red">Profile data unavailable.</Text>;
  }

  return (
    <Card variant="classic" style={{ padding: '20px', backgroundColor: '#1a1a1a', borderRadius: '8px' }}>
      <Heading as="h2" size="5" weight="medium" style={{ color: 'white', marginBottom: '10px' }}>
        Streamer URL
      </Heading>
      
      <Flex align="center" gap="3">
        {/* Using Radix Text to match style */}
        <Text
          as="span"
          size={{ initial: '2', sm: '3' }} // Matches size used in Linked Accounts
          weight="light"
          style={{
            flexGrow: 1,
            backgroundColor: '#333', // Dark background
            color: 'white', // White text
            padding: '10px',
            border: '1px solid #444', // Dark border
            borderRadius: '4px', // Keep the inner text element less rounded
            display: 'block',
          }}
        >
          {`https://chatrpg.com/${profile.chatrpg_username.toLowerCase()}`}
        </Text>
        
        <Button
          variant="solid"
          style={{
            backgroundColor: copySuccess ? '#4caf50' : '#1a1a1a', // Dark green on success, dark background otherwise
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px', // Square button
            height: '40px', // Square button, matching input height
            padding: '0', // No padding, just the icon
            borderRadius: '4px', // Keep the button less rounded
          }}
          onClick={handleCopy}
        >
          {copySuccess ? <CheckIcon /> : <CopyIcon />}
        </Button>
      </Flex>
      
      <Flex direction="column" gap="2" style={{ marginTop: '20px' }}>
        {/* <Text style={{ color: 'white' }}>1. Share this URL with your audience.</Text>
        <Text style={{ color: 'white' }}>2. They can also enjoy reading all chats and your installed extensions!</Text> */}
      </Flex>
    </Card>
  );
};

export default StreamerUrlDisplay;
