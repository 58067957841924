import PLATFORMS from "../../../../config/platformConfig"
import { useStreamerPageProfile } from "../../../../contexts/StreamerProfileContext"

import "./SocialsBox.css"

const SocialsBox = () => {
  const { streamerProfile } = useStreamerPageProfile()

  return (
    <div className="socialsBox">
      {streamerProfile?.aboutMePlatformIcons?.map((icon, index) => {
        const url = PLATFORMS[icon.platform]?.link
          ? PLATFORMS[icon.platform].link + icon.username
          : "#"

        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`socialIcon ${icon.platform}`}
          >
            <img
              src={PLATFORMS[icon.platform]?.badge || "defaultImage.png"}
              alt={icon.platform}
              width="30px"
              height="30px"
            />
          </a>
        )
      })}
    </div>
  )
}

export default SocialsBox
