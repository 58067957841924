import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useLocalStorage } from "usehooks-ts"

interface ITokenContext {
  token: string | null
  setToken: (token: string | null) => void
  clearToken: () => void
}

const TokenContext = createContext<ITokenContext | null>(null)

export function useToken() {
  const context = useContext(TokenContext)

  if (!context) {
    throw new Error("useToken must be used within a TokenProvider")
  }

  return context
}

const KEY = "token"

function TokenProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate()
  const location = useLocation()

  const [token, setToken] = useLocalStorage<string | null>(KEY, null)

  useEffect(() => {
    if (token == null) localStorage.removeItem(KEY)
  }, [token])

  // Detect and set token from hash
  useEffect(() => {
    const { hash, pathname } = location
    const tokenFromHash = hash.substring(1)

    // TODO: Use query params instead
    if (tokenFromHash) {
      console.log("Token found from hash, setting...", tokenFromHash)
      setToken(tokenFromHash)
      navigate(pathname, { replace: true })
    }
  }, [location, navigate, setToken, token])

  const clearToken = useCallback(() => setToken(null), [setToken])

  const value = useMemo(
    () => ({ token, setToken, clearToken }),
    [clearToken, setToken, token]
  )

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
}

export default TokenProvider
