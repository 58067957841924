import React from 'react';

const TermsOfService = () => {
  const headingStyle = {
    marginTop: '20px', // Adds space above each h2 element
    marginBottom: '10px', // Adds space below each h2 element
  };

  return (
    <div style={{ backgroundColor: 'white', color: 'black', whiteSpace: "pre-wrap" }}>
      <b><h1>Terms of Service for ChatRPG</h1></b>

      <b><h2 style={headingStyle}>1. Acceptance of Terms</h2></b>
      <p>By accessing and using ChatRPG, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you should not use or access ChatRPG.</p>

      <b><h2 style={headingStyle}>2. Account Registration</h2></b>
      <p>You may need to register an account to use certain features of ChatRPG. All account information must be accurate and truthful. You are responsible for maintaining the confidentiality of your account and password. ChatRPG uses external OAuth services for account registration and login. We do not store passwords but will store access tokens for service functionality.</p>

      <b><h2 style={headingStyle}>3. Service Description</h2></b>
      <p>ChatRPG is a platform that integrates various social media channels, including Twitch, Kick, YouTube, TikTok, etc., providing a unified chat experience and a point system. The service may evolve over time, including potential extensions for gaming experiences.</p>

      <b><h2 style={headingStyle}>4. User Conduct</h2></b>
      <p>You agree to use ChatRPG only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of ChatRPG. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within ChatRPG.</p>

      <b><h2 style={headingStyle}>5. Intellectual Property</h2></b>
      <p>All materials within ChatRPG, including design, text, graphics, and other files, and their selection and arrangement, are the proprietary property of ChatRPG. No material from ChatRPG may be copied, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any form without prior written permission from ChatRPG.</p>

      <b><h2 style={headingStyle}>6. User-Generated Content</h2></b>
      <p>You retain all rights in, and are solely responsible for, the User Generated Content you post to ChatRPG. By posting content, you grant ChatRPG a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display such content in connection with the service.</p>

      <b><h2 style={headingStyle}>7. Termination and Account Cancellation</h2></b>
      <p>ChatRPG reserves the right to terminate your account or access to the Service at any time, without notice, for any reason, including breach of these Terms. You may cancel your account at any time.</p>

      <b><h2 style={headingStyle}>8. Disclaimers and Limitations of Liability</h2></b>
      <p>ChatRPG provides the service on an "as is" and "as available" basis. We do not guarantee that the service will be uninterrupted or error-free. ChatRPG shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</p>

      <b><h2 style={headingStyle}>9. Changes to Terms</h2></b>
      <p>ChatRPG reserves the right to modify these terms at any time. It is your responsibility to review these Terms periodically for changes.</p>

      <b><h2 style={headingStyle}>10. Governing Law</h2></b>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which ChatRPG operates, without regard to its conflict of law provisions.</p>

      <b><h2 style={headingStyle}>11. YouTube Services</h2></b>
      <p>By using ChatRPG with YouTube integration, you agree to be bound by the <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>YouTube Terms of Service</a>. Additionally, information provided by YouTube is also subject to Google's <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>Privacy Policy</a>.</p>
      <p>You can review and manage your Google account's security settings by visiting the <a href="https://myaccount.google.com/security" target="_blank" rel="noopener noreferrer" style={{ color: '#89CFF0' }}>Google Security Settings</a> page.</p>
      <br></br>
      <p>Last Updated: May 4th, 2024</p>
    </div>
  );
};

export default TermsOfService;
