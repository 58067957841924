import { IconButton, Tooltip } from "@radix-ui/themes"
import { GrUnlink } from "react-icons/gr"
import { useDispatch } from "react-redux"
import { styled } from "styled-components"
import { setUserStreamerProfile } from "../../../../../../store/reducers/userStreamerProfileReducer"
import { PlatformKey } from "../../../../../config/platformConfig"
import { useToken } from "../../../../../contexts/TokenContext"
import {
  UserProfile,
  useUserProfile,
} from "../../../../../contexts/UserProfileContext"
import { unlinkAccount, unlinkStreamerAccount } from "./UnlinkAccount"

export interface UnlinkButtonProps {
  platform: PlatformKey
  isUnlinkable: boolean | string
  streamer?: boolean
  onSuccess?: () => void
}

const logLinkedPlatforms = (profile: UserProfile | null) => {
  if (profile?.linkedAccounts) {
    Object.keys(profile.linkedAccounts).forEach((platformKey) => {
      console.log(
        `Platform: ${platformKey}, Username: ${profile.linkedAccounts[platformKey].platformUsername}`
      )
    })
  }
}

function UnlinkButton({
  platform,
  isUnlinkable,
  streamer,
  onSuccess,
}: UnlinkButtonProps) {
  const dispatch = useDispatch()

  const { setProfile } = useUserProfile()
  const { token } = useToken()

  // logLinkedPlatforms(profile)

  const handleUnlinkAccount = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation()

    if (isUnlinkable) {
      dispatch({ type: "START_LOADING" })
      try {
        if (streamer) {
          const response = await unlinkStreamerAccount(platform, token)

          dispatch(setUserStreamerProfile(response.streamer))
        } else {
          const response = await unlinkAccount(platform, token)

          setProfile(response.user)
          if (response.streamer) {
            dispatch(setUserStreamerProfile(response.streamer))
          }
        }
        onSuccess?.()
      } catch (error) {
        console.error("Error during unlinking:", error)
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    }
  }

  const isDisabled = typeof isUnlinkable === "string"

  return (
    <Tooltip content={isDisabled ? isUnlinkable : "Unlink"}>
      <Wrapper
        size="3"
        variant="ghost"
        color="gray"
        onClick={handleUnlinkAccount}
        mx="2"
        disabled={isDisabled}
      >
        <GrUnlink size="20" color={isDisabled ? "var(--gray-9)" : "white"} />
      </Wrapper>
    </Tooltip>
  )
}

const Wrapper = styled(IconButton)`
  display: flex;
`

export default UnlinkButton
