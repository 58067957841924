import { Box, Flex, Text } from "@radix-ui/themes"
import { useSelector } from "react-redux"
import { getExtensionUrl } from "utils/getExtensionUrl"
import { RootState } from "../../../store"
import { selectAllExtensionJWT } from "../../../store/slices/Auth"
import { useStreamerPageProfile } from "../../contexts/StreamerProfileContext"
import { useToken } from "../../contexts/TokenContext"
import ExtensionPlaceholder from "../../extensions/ExtensionPlaceholder"

import "./ChatBottomBar.css"

interface BottomBarPointsOnlyProps {
  extensionName: string
}

function BottomBarPointsOnly({ extensionName }: BottomBarPointsOnlyProps) {
  const { token } = useToken()
  const { streamerProfile } = useStreamerPageProfile()
  const isEditMode = useSelector((state: RootState) => state.editMode) // Access the editMode state

  const getExtensionJWT = useSelector(selectAllExtensionJWT)

  const streamerPointsExtension = streamerProfile.extensions.find(
    ({ type, enabled }) => type === "Points" && enabled
  )

  const jwtToken = streamerPointsExtension
    ? getExtensionJWT(streamerPointsExtension.clientId)
    : null

  const shouldShowPoints = token && jwtToken && streamerPointsExtension

  const extensionUrl = getExtensionUrl(streamerPointsExtension)

  return (
    <Flex justify="between" align="center" px="1" py="2">
      <div style={{ flex: 1 }}>
        {shouldShowPoints ? (
          <Box maxHeight="2rem" pl="1" asChild>
            <iframe
              src={`${extensionUrl}?token=${getExtensionJWT(
                streamerPointsExtension.clientId
              )}`}
              className="embeddedProject"
              title="Embedded Page"
            />
          </Box>
        ) : null}

        {isEditMode && <ExtensionPlaceholder className="defaultSize" />}
      </div>

      <Box px="1" width="fit-content">
        <Text
          weight="bold"
          align="right"
          style={{ color: "var(--chatrpg-logo-color)" }}
        >
          {extensionName}
        </Text>
      </Box>
    </Flex>
  )
}

export default BottomBarPointsOnly
