import { Button, Flex, Heading, ScrollArea } from "@radix-ui/themes"
import { useDeveloperExtensions } from "../../../../extensions/DeveloperExtensionsProvider"
import ExtensionForm from "./ExtensionForm"

function DeveloperApplicationOptions() {
  const { extensions, addExtension } = useDeveloperExtensions()

  return (
    <>
      <Flex align="center" justify="between" height="4rem" mb="3">
        <Heading mb="-2">My Extensions</Heading>

        <div>
          <Button color="grass" onClick={addExtension}>
            Add New Extension
          </Button>
        </div>
      </Flex>

      <ScrollArea scrollbars="vertical">
        <Flex align="start" wrap="wrap" gap="4" height="85.5vh">
          {extensions.map((extension) => (
            <ExtensionForm key={extension._id} {...extension} />
          ))}
        </Flex>
      </ScrollArea>
    </>
  )
}

export default DeveloperApplicationOptions
