import { Form } from "@radix-ui/react-form"
import { Box, Flex, Grid, Heading, Separator, Text } from "@radix-ui/themes"
import { useEffect, useMemo, useState } from "react"
import {
  UserSettings,
  useUserProfile,
} from "../../../../../contexts/UserProfileContext"
import ActionButton from "../../../../../ui/ActionButton"
import Toggle from "../../../../../ui/Toggle"
import { LinkContainer } from "../accountlinking/AccountLinkingOptionsStyles"

type TUserChatSettings = Omit<
  UserSettings,
  "disablePlayer" | "disableSidebar" | "disableEmbeds"
>

function UserChatSettings() {
  const { profile, updateSettings } = useUserProfile()

  const platforms = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(profile?.settings ?? {}).filter(
          ([key]) =>
            key !== "disableRotatingChatBackground" &&
            key !== "enablePlatformChatBadges" &&
            /^(?!.*rotating).*chat.*$/i.test(key)  // Keep filtering for chat-related settings
        )
      ) as TUserChatSettings,
    [profile?.settings]
  )

  const [chatPlatforms, setChatPlatforms] = useState<
    Partial<TUserChatSettings>
  >({
    ...platforms,
    ...profile?.settings,  // Spread all settings dynamically
  })

  useEffect(() => {
    console.log("Initial chat platform settings:", chatPlatforms)
    console.log("Initial value for enablePlatformChatBadges:", chatPlatforms.enablePlatformChatBadges)
  }, [chatPlatforms])
  
  return (
    <>
      <LinkContainer size="3">
        <Box mb={{ initial: "3", md: "4", lg: "5" }}>
          <Heading as="h1" size="4" weight="medium" mb="1">
            Chat Platforms
          </Heading>

          <Text size="2" color="gray">
            If you don't want to see chats on certain platforms, you can disable
            them here.
          </Text>
        </Box>

        <Form
          onSubmit={(e) => {
            e.preventDefault()
            updateSettings(chatPlatforms)
          }}
        >
          <Grid columns={{ initial: "2", md: "3" }} gapX="8" gapY="2" mb="5">
            {Object.entries(platforms).map(([key, val]) => (
              <div key={key}>
                <Toggle
                  label={key.replace(/disable|chat(?=$)/gi, "")}
                  checked={!chatPlatforms[key as keyof TUserChatSettings]}
                  radius="full"
                  size={{ initial: "1", md: "2" }}
                  gap="3"
                  onCheckedChange={(checked) => {
                    setChatPlatforms((prev) => ({ ...prev, [key]: !checked }))
                  }}
                />
              </div>
            ))}
          </Grid>

          <Separator size="4" my="4" />

          <Box mb={{ initial: "3", md: "4", lg: "5" }}>
            <Heading as="h1" size="4" weight="medium" mb="1">
              Accessibility
            </Heading>

            <Text size="2" color="gray">
              Enable optional features to improve chat accessibility and
              readability.
            </Text>
          </Box>

          <Flex>
            <Toggle
              label="Alternating Background"
              sub="When enabled, the chat background will appear checkered to enhance the visibility of individual chat lines."
              checked={!chatPlatforms.disableRotatingChatBackground}
              radius="full"
              size={{ initial: "1", md: "2" }}
              gap="3"
              onCheckedChange={(checked) => {
                setChatPlatforms((prev) => ({
                  ...prev,
                  disableRotatingChatBackground: !checked,
                }))
              }}
            />
          </Flex>
          <br />
          <Flex>
            <Toggle
              label="Platform Chat Badge"
              sub="When enabled you can see which Platform a message comes from."
              checked={chatPlatforms.enablePlatformChatBadges}  // Use direct boolean fallback
              radius="full"
              size={{ initial: "1", md: "2" }}
              gap="3"
              onCheckedChange={(checked) => {
                console.log("Toggling Platform Chat Badge:", checked);
                setChatPlatforms((prev) => ({
                  ...prev,
                  enablePlatformChatBadges: checked,  // Set the state directly from the toggle
                }))
              }}
            />
          </Flex>

          <Flex mt="6">
            <ActionButton size="2">Save</ActionButton>
          </Flex>
        </Form>
      </LinkContainer>
    </>
  )
}

export default UserChatSettings
