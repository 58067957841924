import { Button, Flex } from "@radix-ui/themes"
import axios from "axios"
import { sendMessageToAllIframes } from "components/extensions/helper/SendMessageToIframe"
import { INVENTORY_CLIENT_ID, INVENTORY_SERVER_URL } from "env"
import { MoveableManagerInterface } from "react-moveable"
import store from "store"
import { selectAllExtensionJWT } from "store/slices/Auth"
import { InventoryItem, resetPlayerOverlay } from "store/slices/EmbeddedPlayer"
import styled from "styled-components"

function normalize(value: number, { min = 0, max = 100, multiplier = 1 }) {
  return ((value - min) / (max - min)) * multiplier
}

const Actionable = {
  name: "actionable",
  props: ["item"],
  events: [],

  render(moveable: MoveableManagerInterface<{ item: InventoryItem }>) {
    const rect = moveable.getRect()
    const { pos1, pos2, containerClientRect } = moveable.state

    const state = store.getState()
    // const profile = getStreamerPageProfile(state)
    const getToken = selectAllExtensionJWT(state)
    // Token isn't matching up here but its whatever
    const token = getToken(INVENTORY_CLIENT_ID)

    const { item } = moveable.props

    const handleRemove = () => {
      store.dispatch(resetPlayerOverlay())
    }

    const handleUse = async () => {
      store.dispatch(resetPlayerOverlay())

      try {
        sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_START_LOADING`, {
          loading: true,
        })

        const response = await axios.post(
          `${INVENTORY_SERVER_URL}/inventory/use`,
          {
            inventoryItemId: item.productId,
            positionX: normalize(rect.origin[0], {
              max: containerClientRect.width,
              multiplier: 1920,
            }),
            positionY: normalize(rect.origin[1], {
              max: containerClientRect.height,
              multiplier: 1080,
            }),
            scale: moveable.scale,
            rotation: rect.rotation,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )

        if (response.status === 200) {
          sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_STOP_LOADING`, {
            loading: false,
          })
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const reason =
            error.response?.data?.message ||
            error.message ||
            "Unknown error occurred"

          alert(`Failed to use item. Reason: ${reason}`)

          console.error("Failed to use item: ", reason, error.response?.data)
        } else {
          alert(
            "An unexpected error occurred. Please try again. This ain't good"
          )
          console.error("Unexpected error:", error)
        }
      } finally {
        sendMessageToAllIframes(`${INVENTORY_CLIENT_ID}_STOP_LOADING`, {
          loading: false,
        })
      }
    }

    const resolvePos = () => {
      if (rect.top < containerClientRect.height * 0.05) {
        if (rect.left > (containerClientRect.width - rect.width) * 0.95) {
          return {
            pos: pos1,
            translate: `translate(-30px)`,
            direction: "column",
          }
        }

        return { pos: pos2, translate: `translate(5px)`, direction: "column" }
      }

      return {
        pos: pos2,
        translate: `translate(-50px, -30px)`,
        direction: "row",
      }
    }

    const {
      pos: [x, y],
      translate,
      direction,
    } = resolvePos()

    // Add key (required)
    // Add class prefix moveable-(required)
    return (
      <Wrapper
        key="actionable"
        className="moveable-actionable"
        // @ts-expect-error
        direction={direction}
        gap="1"
        style={{
          transform: `translate(${x}px, ${y}px) rotate(${rect.rotation}deg) ${translate}`,
        }}
      >
        <Button
          onClick={handleUse}
          color="grass"
          style={{ width: "24px", height: "24px" }}
        >
          {String.fromCodePoint(0x2713)}
        </Button>

        <Button
          onClick={handleRemove}
          color="red"
          style={{ width: "24px", height: "24px" }}
        >
          &times;
        </Button>
      </Wrapper>
    )
  },
} as const

const Wrapper = styled(Flex)`
  position: absolute;
  left: 0px;
  top: 0px;
  will-change: transform;
  transform-origin: 0px 0px;
`

export default Actionable
