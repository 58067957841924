import { Tabs, TabsContent } from "@radix-ui/react-tabs"
import { AspectRatio } from "@radix-ui/themes"
import AuthModule from "components/auth/login/AuthModule"
import { useAuthModule } from "components/auth/login/UseAuthModule"
import { useMobileContext } from "components/contexts/MobileContext"
import { useOrientation } from "components/contexts/OrientationContext"
import { useStreamerPageProfile } from "components/contexts/StreamerProfileContext"
import { useToken } from "components/contexts/TokenContext"
import { useUserProfile } from "components/contexts/UserProfileContext"
import AlertList from "components/extensions/native/AlertList"
import EmbedPlatformSelector from "components/username/player/embed/EmbedPlatformSelector"
import ShimmerPlayer from "components/username/player/embed/ShimmerPlayer"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { selectAllExtensionJWT } from "store/slices/Auth"
import { selectStreamerPageBottomBarExtensions } from "store/slices/StreamerPage"
import { css, styled } from "styled-components"
import { getExtensionUrl } from "utils/getExtensionUrl"
import BottomBar from "./chatBottomBar/ChatBottomBar"
import BottomBarPointsOnly from "./chatBottomBar/ChatBottomBarPointsOnly"
import ChatDisplay from "./chatDisplay/ChatDisplay"
import ExtensionBottomBar from "./chatExtensionBar/ChatExtensionBar"
import ChatInput from "./chatinput/ChatInput"

function ChatArea() {
  const { isLandscape } = useOrientation()
  const { isMobile } = useMobileContext()
  const { token } = useToken()
  const { isAuthModuleOpen, closeAuthModule } = useAuthModule()

  const { profile, profileFetched } = useUserProfile()
  const { players } = useStreamerPageProfile() // Add players for EmbedPlatformSelector

  const extensions = useSelector(
    (state: RootState) =>
      // TODO: Move userProfile to the store so we can get rid of this garbage selector
      selectStreamerPageBottomBarExtensions(state)(profile?.administrator),
    { equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b) }
  )

  const getExtensionJWT = useSelector(selectAllExtensionJWT)

  // Only show player if:
  // - user is logged in, profile is fetched, and player is enabled
  // - user is not logged in
  const showPlayer = token
    ? profileFetched
      ? !profile?.settings.disablePlayer
      : false
    : true

  const isFullscreenMode = useSelector(
    (state: RootState) => state.fullscreenMode.isFullscreenMode
  )

  return (
    <Wrapper
      $landscape={isLandscape}
      $mobile={isMobile}
      $hide={isFullscreenMode}
    >
      {isMobile && showPlayer && (
        <>
          <div id="player" style={{ marginBottom: "8px" }}>
            <AspectRatio ratio={16 / 9}>
              <ShimmerPlayer />
            </AspectRatio>
          </div>

          {/* Embed Platform Selector Below the Player */}
          {players.length > 0 ? (
            <EmbedPlatformSelector />
          ) : (
            <p>No platforms available.</p>
          )}
        </>
      )}

      <ChatContainer defaultValue="chat">
        <ChatContent
          value="chat"
          forceMount
          $hasBottomBarExtensions={extensions.length > 0}
        >
          <ChatDisplay />
          <AlertList />
          <ChatInput />
          <BottomBar />
        </ChatContent>

        {extensions.map((extension) => (
          <ChatContent
            key={extension.clientId}
            value={extension.clientId}
            forceMount
            $hasBottomBarExtensions
          >
            <ExtensionEmbed
              src={`${getExtensionUrl(extension)}?token=${getExtensionJWT(
                extension.clientId
              )}`}
              title={`${extension.extensionName} Extension`}
              allowFullScreen
              onLoad={() => {
                console.log(
                  "BETA Iframe for extension rendered:",
                  extension.clientId
                )
              }}
            />

            <BottomBarPointsOnly extensionName={extension.extensionName} />
          </ChatContent>
        ))}

        <ExtensionBottomBar />
      </ChatContainer>

      {isAuthModuleOpen && <AuthModule onClose={closeAuthModule} />}
    </Wrapper>
  )
}

const ChatContent = styled(TabsContent)<{ $hasBottomBarExtensions: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ $hasBottomBarExtensions }) =>
    `calc(100% - ${$hasBottomBarExtensions ? "40px" : "0px"})`};

  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(100%);
  z-index: 5;

  transition: transform 150ms;

  &[data-state="active"] {
    transform: translateX(0);
  }
`

const ChatContainer = styled(Tabs)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: 100%;
`

const ExtensionEmbed = styled.iframe`
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div<{
  $landscape: boolean
  $mobile: boolean
  $hide?: boolean
}>`
  width: ${({ $landscape }) => ($landscape ? "35%" : "var(--chatarea-width)")};
  flex-shrink: 0;
  background-color: var(--sidebar-color);
  color: #ecf0f1;

  /* TODO: Add transition, like w/ the sidebar */
  display: ${({ $hide }) => ($hide ? "none" : "flex")};
  flex-direction: column;

  ${({ $mobile }) =>
    $mobile &&
    css`
      flex-grow: 1;
    `}

  @media (max-width: 400px) {
    width: 100%; /* Full width on mobile */
  }
`

export default ChatArea
