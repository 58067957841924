import { ObjectId } from "bson"
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import {
  addUserDeveloperProfileExtension,
  deleteUserDeveloperProfileLocalExtension,
  Extension,
  ExtensionType,
  setUserDeveloperProfile,
  updateUserDeveloperProfileExtension,
  UserDeveloperProfile,
} from "../../store/reducers/userDeveloperProfileReducer"
import { useToken } from "../contexts/TokenContext"
import {
  cancelExtensionReview,
  removeOwnExtension,
  saveExtension as saveExtensionToServer,
  submitExtensionForReview,
} from "./api/Extensions"
import {
  createExtensionClientSecret,
  getExtensionClientSecret,
} from "./api/Metadata"

interface DeveloperExtensionsMeta {
  extensions: Extension[]
  addExtension: () => void
  updateExtension: (_id: string, data: Partial<Extension>) => void
  saveExtension: (data: Extension) => Promise<void>
  removeExtension: (clientId: string) => Promise<void>
  submitForReview: (clientId: string) => Promise<void>
  cancelReview: (clientId: string) => Promise<void>
  generateSecret: (_id: string, clientId: string) => Promise<void>
  toggleSecret: (id: string) => Promise<void>
}

const DeveloperExtensionsContext =
  createContext<DeveloperExtensionsMeta | null>(null)

export function useDeveloperExtensions() {
  const context = useContext(DeveloperExtensionsContext)

  if (!context) {
    throw new Error(
      "useDeveloperExtensions should be used under DeveloperExtensionsProvider"
    )
  }

  return context
}

const INIT_EXT: Extension = {
  _id: new ObjectId().toHexString(),
  extensionName: "",
  description: "",
  clientId: "",
  clientSecret: "",
  type: ExtensionType.BottomBar,
  buildUrl: "",
  configUrl: "",
  isForStreamer: true,

  // triple `false` could be replaced by `status: "draft"`
  approved: false, // could be replaced by `status: "approved"`
  rejected: false, // could be replaced by `status: "rejected"`
  review: false, // could be replaced by `status: "pending"`
}

function DeveloperExtensionsProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch()
  const { token } = useToken()

  const { extensions } = useSelector<RootState, UserDeveloperProfile>(
    (state) => state.userDeveloperProfile as UserDeveloperProfile
  )

  const updateExtensions = useCallback(
    (_id: string, data: Partial<Omit<Extension, "_id">>) => {
      dispatch(updateUserDeveloperProfileExtension({ ...data, _id }))
    },
    [dispatch]
  )

  const addExtension = useCallback(() => {
    dispatch(addUserDeveloperProfileExtension(INIT_EXT))
  }, [dispatch])

  // TODO: Update CRUD
  const saveExtension = useCallback(
    async (data: Extension) => {
      try {
        dispatch({ type: "START_LOADING" })
        // FIXME: Not ideal, just send back the new extension with
        // the generated metadata (i.e. `clientId`) directly
        const updatedDeveloperProfile = await saveExtensionToServer(token, data)
        const updatedExtensionData = updatedDeveloperProfile.extensions.find(
          ({ _id }) => _id === data._id
        )

        if (!updatedExtensionData) {
          throw new Error(`Unable to find extension with ID: ${data._id}`)
        }

        updateExtensions(updatedExtensionData._id, updatedExtensionData)
      } catch (error) {
        console.log("Error saving extension:", error)
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    },
    [dispatch, token, updateExtensions]
  )

  const submitForReview = useCallback(
    async (clientId: string) => {
      dispatch({ type: "START_LOADING" })
      try {
        // FIXME: Not ideal, just send back a 200/40x response
        // with a body containing a `status: "pending"` property
        const updatedDeveloperProfile = await submitExtensionForReview(
          clientId,
          token
        )

        const updatedExtensionData = updatedDeveloperProfile.extensions.find(
          (extension) => extension.clientId === clientId
        )

        if (!updatedExtensionData) {
          throw new Error(
            `Unable to find extension with client ID: ${clientId}`
          )
        }

        updateExtensions(updatedExtensionData._id, updatedExtensionData)
      } catch (error) {
        console.error("Error submitting extension for review:", error)
        alert("Failed to submit extension for review.")
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    },
    [dispatch, token, updateExtensions]
  )

  const cancelReview = useCallback(
    async (clientId: string) => {
      dispatch({ type: "START_LOADING" })
      try {
        // FIXME: Not ideal, just send back a body containing a
        // `status: "draft"` property if it's a NEW one, OR
        // if it's an existing one, revert back to whatever status was before
        const updatedDeveloperProfile = await cancelExtensionReview(
          clientId,
          token
        )

        const updatedExtensionData = updatedDeveloperProfile.extensions.find(
          (extension) => extension.clientId === clientId
        )

        if (!updatedExtensionData) {
          throw new Error(
            `Unable to find extension with client ID: ${clientId}`
          )
        }

        updateExtensions(updatedExtensionData._id, updatedExtensionData)
      } catch (error) {
        console.error("Error canceling review:", error)
        alert("Failed to cancel review.")
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    },
    [dispatch, token, updateExtensions]
  )

  const removeExtension = useCallback(
    async (clientId: string) => {
      if (!clientId) {
        // If we have no `clientId`, it means we have un unsaved extension
        // Delete the local (unsaved) extension instead
        return void dispatch(deleteUserDeveloperProfileLocalExtension())
      }

      dispatch({ type: "START_LOADING" })
      try {
        const updatedDeveloperProfile = await removeOwnExtension(
          clientId,
          token
        )
        console.log("Updated developer profile:", updatedDeveloperProfile)

        // FIXME: Not ideal, just send back the
        // `_id` or `clientId` so we can filter it out
        dispatch(setUserDeveloperProfile(updatedDeveloperProfile))
      } catch (error) {
        console.error("Error removing extension:", error)
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    },
    [dispatch, token]
  )

  const generateSecret = useCallback(
    async (_id: string, clientId: string) => {
      try {
        dispatch({ type: "START_LOADING" })

        const newClientSecret = await createExtensionClientSecret(
          clientId,
          token
        )
        console.log("our new secret is " + newClientSecret)

        updateExtensions(_id, { clientSecret: newClientSecret })
      } catch (error) {
        console.error("Error generating a new client secret:", error)
      } finally {
        dispatch({ type: "STOP_LOADING" })
      }
    },
    [dispatch, token, updateExtensions]
  )

  const toggleSecret = useCallback(
    async (_id: string) => {
      const extension = extensions.find((ext) => ext._id === _id)

      if (!extension) {
        throw new Error(`Unable to find extension with ID: ${_id}`)
      }

      if (!extension.clientSecret) {
        dispatch({ type: "START_LOADING" })
        const secret = await getExtensionClientSecret(extension.clientId, token)
        dispatch({ type: "STOP_LOADING" })
        updateExtensions(_id, { clientSecret: secret })
      } else {
        // Clear the local secret
        updateExtensions(_id, { clientSecret: "" })
      }
    },
    [dispatch, extensions, token, updateExtensions]
  )

  const meta = useMemo<DeveloperExtensionsMeta>(
    () => ({
      extensions,
      addExtension,
      updateExtension: updateExtensions,
      saveExtension,
      removeExtension,
      submitForReview,
      cancelReview,
      generateSecret,
      toggleSecret,
    }),
    [
      extensions,
      addExtension,
      updateExtensions,
      saveExtension,
      removeExtension,
      submitForReview,
      cancelReview,
      generateSecret,
      toggleSecret,
    ]
  )

  return (
    <DeveloperExtensionsContext.Provider value={meta}>
      {children}
    </DeveloperExtensionsContext.Provider>
  )
}

export default DeveloperExtensionsProvider
