// src/hooks/useTestAlerts.js
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useToken } from '../../../../../../contexts/TokenContext';
import { RootState } from '../../../../../../../store';
import { UserStreamerProfile } from '../../../../../../../store/reducers/userStreamerProfileReducer';
import { useSelector } from 'react-redux';
import { WEBSOCKET_SERVER_URL } from '../../../../../../../env';

const useTestAlerts = () => {
    const dispatch = useDispatch();
    const { token } = useToken();
    const userStreamerProfile = useSelector((state: RootState) => state.userStreamerProfile) as UserStreamerProfile;

    const sendFakeTwitchSubscription = async () => {
        console.log("We're using these fields " + userStreamerProfile.twitchId + userStreamerProfile.chatrpg_id);
        dispatch({ type: "START_LOADING" });
        try {
            console.log("STARTING LOAD");
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketwitchsubscription`, {
                twitchId: userStreamerProfile.twitchId,
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("ENDING LOAD!");
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test subscription alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test subscription alert:", error);
            return { success: false, message: "Failed to send test subscription alert" };
        }
    };

    const sendFakeTwitchCommercial = async () => {
        console.log("We're using these fields " + userStreamerProfile.twitchId + userStreamerProfile.chatrpg_id);
        dispatch({ type: "START_LOADING" });
        try {
            console.log("STARTING LOAD");
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketwitchcommercial`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log("ENDING LOAD!");
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test commercial alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test commercial alert:", error);
            return { success: false, message: "Failed to send test commercial alert" };
        }
    };

    const sendFakeTwitchFollow = async () => {
        dispatch({ type: "START_LOADING" })
        try {
            await axios.post(
                `${WEBSOCKET_SERVER_URL}/admin/faketwitchfollow`,
                {
                    twitchId: userStreamerProfile.twitchId,
                    chatrpgId: userStreamerProfile.chatrpg_id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            dispatch({ type: "STOP_LOADING" })
            return { success: true, message: "Test bits alert sent successfully!" }
        } catch (error) {
            dispatch({ type: "STOP_LOADING" })
            console.error("Error sending test bits alert:", error)
            return { success: false, message: "Failed to send test bits alert" }
        }
    }

    const sendFakeTwitchBits = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketwitchbits`, {
                twitchId: userStreamerProfile.twitchId,
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test bits alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test bits alert:", error);
            return { success: false, message: "Failed to send test bits alert" };
        }
    };

    const sendFakeTwitchChannelPoints = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketwitchpoints`, {
                twitchId: userStreamerProfile.twitchId,
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test channel points alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test bits alert:", error);
            return { success: false, message: "Failed to send test bits alert" };
        }
    };

    const sendFakeYouTubeSuperChat = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/fakeyoutubesuperchat`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test YouTube Super Chat alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send YouTube Super Chat alert" };
        }
    };

    const sendFakeTikTokGift = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketiktokgift`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test TikTok gift alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send TikTok gift alert" };
        }
    };

    const sendFakeTikTokSubscriber = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketiktoksubscription`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test TikTok gift alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send TikTok gift alert" };
        }
    };

    const sendFakeTikTokFollow = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/faketiktokfollow`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test TikTok gift alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send TikTok gift alert" };
        }
    };


    const sendFakeKickSubscription = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/fakekicksubscription`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test Kick alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            return { success: false, message: "Failed to send Kick gift alert" };
        }
    };



    const sendFakeYouTubeSponsor = async () => {
        dispatch({ type: "START_LOADING" });
        try {
            await axios.post(`${WEBSOCKET_SERVER_URL}/admin/fakeyoutubesponsor`, {
                chatrpgId: userStreamerProfile.chatrpg_id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch({ type: "STOP_LOADING" });
            return { success: true, message: "Test YouTube sponsor alert sent successfully!" };
        } catch (error) {
            dispatch({ type: "STOP_LOADING" });
            console.error("Error sending test sponsor alert:", error);
            return { success: false, message: "Failed to send YouTube sponsor alert" };
        }
    };

    return {
        sendFakeTwitchSubscription,
        sendFakeTwitchBits,
        sendFakeYouTubeSuperChat,
        sendFakeTikTokGift,
        sendFakeYouTubeSponsor,
        sendFakeTikTokSubscriber,
        sendFakeTwitchChannelPoints,
        sendFakeKickSubscription,
        sendFakeTwitchCommercial,
        sendFakeTwitchFollow,
        sendFakeTikTokFollow
    };
};

export default useTestAlerts;
