// src/components/StreamerActiveDescriptionBox.js
import { verifiedBadge } from '../../../../config/imageConfig';
import PLATFORMS from '../../../../config/platformConfig';
import { useStreamerPageProfile } from '../../../../contexts/StreamerProfileContext';
import EmbedPlatformSelector from '../../embed/EmbedPlatformSelector';

import './ProfileSection.css'; // New CSS file for styling

const ProfileSection = () => {
    const { streamerProfile, players } = useStreamerPageProfile(); // Add players for EmbedPlatformSelector

    const twitchBadge = PLATFORMS['Twitch'].badge;

    return (
        <div className="profileSection">
            <img src={streamerProfile.chatrpg_profilePicture} alt="Profile" className="profilePicture" />
            <div className="userInfo">
                <div className="topSection">
                    <div className="username">{streamerProfile.chatrpg_username}</div>
                    {streamerProfile.chatrpg_partner && (
                        <img src={verifiedBadge} alt="Verified" className="infobox-verified-badge" />
                    )}
                    {streamerProfile.premade && (
                        <div className="twitch-badge">
                            <img src={twitchBadge} alt="Twitch" />
                            <span>Twitch Only</span>
                        </div>
                    )}
                </div>
                <div className="followerCount">{streamerProfile.followers?.toString() || '0'} followers</div>
                
                {/* Label and Embed Platform Selector in a row */}
                <div className="embedPlatformSelectorWrapper">
                    <p className="embedLabel">Embed Video:</p> {/* Added Label */}
                    {players.length > 0 ? (
                        <EmbedPlatformSelector />
                    ) : (
                        <p>No platforms available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileSection;