import { Button, Dialog, Flex, Text } from "@radix-ui/themes"
import { AccountLinkingStatus } from "components/contexts/UserProfileContext"
import { useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// TODO: Move this file somewhere more appropriate
function AccountUnsuccessfulLinkDialog() {
  const navigate = useNavigate()
  const location = useLocation()

  // Store the status so it can still be referenced after navigation
  const statusRef = useRef<AccountLinkingStatus>(location.state?.status)

  return (
    <Dialog.Root
      open={!!location.state?.status}
      onOpenChange={() => navigate(location.pathname, { replace: true })}
    >
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Account Link Unsuccesful</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          {statusRef.current === "accountOwned"
            ? "You already have a linked account."
            : "Someone else already linked the account."}
        </Dialog.Description>

        <Flex mt="4" justify="end">
          <Dialog.Close>
            <Button>
              <Text weight="bold">OK</Text>
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default AccountUnsuccessfulLinkDialog
