// src/config/platformConfig.ts
import chatRPGBadge from '../../assets/images/badges/chatrpg.png';
import discordBadge from '../../assets/images/badges/discord.png';
import facebookBadge from '../../assets/images/badges/facebook.png';
import instagramBadge from '../../assets/images/badges/instagram.png';
import kickBadge from '../../assets/images/badges/kick.png';
import onlyFansBadge from '../../assets/images/badges/onlyfans.png';
import rumbleBadge from '../../assets/images/badges/rumble.png';
import tiktokBadge from '../../assets/images/badges/tiktok.png';
import trovoBadge from '../../assets/images/badges/trovo.png';
import twitchBadge from '../../assets/images/badges/twitch.png';
import twitchChatRPGBadge from '../../assets/images/badges/twitchChatRPG.png';
import youtubeBadge from '../../assets/images/badges/youtube.png';
import youtubeChatRPGBadge from '../../assets/images/badges/youtubeChatRPG.png';
import streamlabsBadge from '../../assets/images/badges/streamlabs.png';
import streamElementsBadge from '../../assets/images/badges/streamelements.png';

//Update this on server
export interface PlatformDetails {
  color?: string;
  embeddable: boolean;
  badge: string;
  link?: string;
  system?: boolean; //Things that the server sends but users can't engage with like TwitchChatRPG
  trueLink?: boolean; //Guessing this is true oauth.
  fakeLink?: boolean;
  streamerFakeLink?: boolean;
  write?: boolean;
  refresh?: boolean;
  streamerOnly?: boolean; //Used for Platforms like streamlabs and streamelements
}

const PLATFORMS: Record<string, PlatformDetails> = {
  ChatRPG: { color: '#f8b426', embeddable: false, badge: chatRPGBadge, link: 'https://chatrpg.com/', write: true},
  Twitch: { color: '#6441A4', embeddable: true, badge: twitchBadge, link: 'https://www.twitch.tv/', trueLink: true, write: true },
  Youtube: { color: 'red', embeddable: true, badge: youtubeBadge, link: 'https://www.youtube.com/', trueLink: true, streamerFakeLink: true, write: true },
  // YoutubeStreamer: { color: 'red', embeddable: false, badge: youtubeBadge, link: 'https://www.youtube.com/', write: true },
  Discord: { color: '#7289da', embeddable: false, badge: discordBadge, link: 'https://discord.gg/' },
  Instagram: { color: '#E4405F', embeddable: false, badge: instagramBadge, link: 'https://www.instagram.com/' },
  Facebook: { color: '#1877F2', embeddable: false, badge: facebookBadge, link: 'https://www.facebook.com/' },
  Trovo: { color: '#30D158', embeddable: true, badge: trovoBadge, link: 'https://trovo.live/', },
  Kick: { color: '#008000', embeddable: true, badge: kickBadge, link: 'https://kick.com/', fakeLink: true},
  Rumble: { color: '#FFA500', embeddable: false, badge: rumbleBadge, link: 'https://www.rumble.com/' },
  Tiktok: { color: '#000000', embeddable: false, badge: tiktokBadge, link: 'https://www.tiktok.com/@', fakeLink: true, refresh: true },
  OnlyFans: { color: '#FF4FD8', embeddable: false, badge: onlyFansBadge, link: 'https://onlyfans.com/' },
  TwitchChatRPG: { embeddable: false, badge: twitchChatRPGBadge },
  YoutubeChatRPG: { embeddable: false, badge: youtubeChatRPGBadge },
  Streamlabs: { color: '#00D1B2', embeddable: false, badge: streamlabsBadge, link: 'https://streamlabs.com', trueLink: true, streamerOnly: true },
  Streamelements: { color: '#6441A4', embeddable: false, badge: streamElementsBadge, link: 'https://streamelements.com', trueLink: true, streamerOnly: true }
  // Add more platforms as needed
};

export type PlatformKey = keyof typeof PLATFORMS;
export default PLATFORMS;
