// utils/getExtensionUrl.ts
import { IStreamerExtension } from "store/slices/StreamerPage";

// Updated function to ensure return type is string | null
export function getExtensionUrl(extension: IStreamerExtension | null | undefined): string | null {
  if (!extension) return null;

  // Return buildLocalUrl in development mode or buildUrl in production mode
  return import.meta.env.MODE === "development"
    ? extension.buildLocalUrl ?? null // Convert undefined to null
    : extension.buildUrl ?? null; // Convert undefined to null
}
