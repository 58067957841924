import axios from "axios"
import { SERVER_URL } from "../../env"
import store from "../../store"
import {
  setUserDeveloperProfile,
  UserDeveloperProfile,
} from "../../store/reducers/userDeveloperProfileReducer"
import {
  setUserStreamerProfile,
  UserStreamerProfile,
} from "../../store/reducers/userStreamerProfileReducer"
import { UserProfile } from "../contexts/UserProfileContext"
import { IExtension } from "../extensions/IExtensions"
import { setStreamerExtensions } from "./../../store/reducers/streamerExtensionReducer"

interface StreamerResponse {
  userProfile: UserProfile
  extensions: Array<{ extensions: IExtension }>
}

interface StreamerResponse {
  streamerProfile: UserStreamerProfile
  extensions: Array<{ extensions: IExtension }>
}

export async function getUserProfileFromToken(token: string) {
  try {
    const response = await axios.get<UserProfile>(
      `${SERVER_URL}/profile/chatrpg`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    console.log(
      `Welcome to ChatRPG ${response.data.chatrpg_username}! Your userId is ${response.data.chatrpg_id}`
    )

    if (!response.data) throw new Error("No data received.")

    if (response.data.isStreamer) {
      const streamerResponse = await axios.get<StreamerResponse>(
        `${SERVER_URL}/profile/userstreamer`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const streamerData = streamerResponse.data.streamerProfile

      const streamerExtensions = streamerResponse.data.extensions.map(
        (ext) => ext.extensions
      )

      //store.dispatch(setUserExtensions(userExtensions));
      store.dispatch(setStreamerExtensions(streamerExtensions))
      store.dispatch(setUserStreamerProfile(streamerData))

      console.log(
        `You're a streamer Harry! Here are your basic stats: Your ID: ${streamerData.chatrpg_id}, your Username: ${streamerData.chatrpg_username} and your followers! ${streamerData.followers} extensions: ${JSON.stringify(streamerResponse.data.extensions)}`
      )
    }

    if (response.data.isDeveloper) {
      const developerResponse = await axios.get<UserDeveloperProfile>(
        `${SERVER_URL}/profile/userdeveloper`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const developerData = developerResponse.data

      // console.log("Here is our developer data " + JSON.stringify(developerData));

      store.dispatch(setUserDeveloperProfile(developerData))

      console.log(
        `You're a developer Harry! Here are your basic stats: Your ID: ${developerData.chatrpg_id}, your Username: ${developerData.chatrpg_username}`
      )
    }

    return response.data
  } catch (error) {
    console.error("Error fetching ChatRPG user profile:", error)
    throw new Error("Failed to fetch user profile.")
  }
}
