// src/hooks/useTestAlerts.js
import axios from "axios"
import { useDispatch } from "react-redux"
import { SERVER_URL, WEBSOCKET_SERVER_URL } from "../../../../../../env"
import { useToken } from "../../../../../contexts/TokenContext"

const useTestAlerts = () => {
  const dispatch = useDispatch()
  const { token } = useToken()

  //TODO MOVE TO OWN SCRIPT CALLED ADMIN UNLINKS OR SOMETHING
  const unlinkStreamerKick = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${SERVER_URL}/streamer/admin/kick/disconnect`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "UNLINKED SUCCESSFULLY! GOODNESS IM SO TIRED OF THESE MESSAGES",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error Unlinking Kick", error)
      return {
        success: false,
        message: "Failed to Unlink Kick",
      }
    }
  }


  const sendFakeTwitchSubscription = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketwitchsubscription`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test subscription alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test subscription alert:", error)
      return {
        success: false,
        message: "Failed to send test subscription alert",
      }
    }
  }

  

  const sendFakeTwitchCommercial = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketwitchcommercial`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return { success: true, message: "Test commercial sent successfully!" }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test commercial alert:", error)
      return { success: false, message: "Failed to send test commercial alert" }
    }
  }

  const sendFakeTwitchBits = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketwitchbits`,
        {
          twitchId: "48465437",
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return { success: true, message: "Test bits alert sent successfully!" }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test bits alert:", error)
      return { success: false, message: "Failed to send test bits alert" }
    }
  }

  const sendFakeTwitchFollow = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketwitchfollow`,
        {
          twitchId: "48465437",
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return { success: true, message: "Test bits alert sent successfully!" }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test bits alert:", error)
      return { success: false, message: "Failed to send test bits alert" }
    }
  }

  const sendFakeTwitchChannelPoints = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketwitchpoints`,
        {
          twitchId: "48465437",
          chatrpgId: chatRPGId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test channel points alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test bits alert:", error)
      return { success: false, message: "Failed to send test bits alert" }
    }
  }

  const sendFakeYouTubeSuperChat = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/fakeyoutubesuperchat`,
        {
          youtubeChannelId: "YC123456789", // Assuming you need a channel ID
          amount: "5", // Assuming amount in USD
          message: "This is a fake super chat",
          chatrpgId: chatRPGId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test YouTube Super Chat alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      return {
        success: false,
        message: "Failed to send YouTube Super Chat alert",
      }
    }
  }

  const sendFakeTikTokGift = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketiktokgift`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test TikTok gift alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      return { success: false, message: "Failed to send TikTok gift alert" }
    }
  }

  const sendFakeTikTokSubscriber = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketiktoksubscription`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test TikTok gift alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      return { success: false, message: "Failed to send TikTok gift alert" }
    }
  }

  const sendFakeTikTokFollow = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/faketiktokfollow`,
        {
          chatrpgId: chatRPGId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test TikTok gift alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      return { success: false, message: "Failed to send TikTok gift alert" }
    }
  }

  const sendFakeYouTubeSponsor = async (chatRPGId: string) => {
    dispatch({ type: "START_LOADING" })
    try {
      await axios.post(
        `${WEBSOCKET_SERVER_URL}/admin/fakeyoutubesponsor`,
        {
          youtubeChannelId: "YC123456789", // Assuming you need a channel ID
          sponsorLevel: "Gold", // Assuming levels of sponsorship
          duration: "1 month", // Assuming duration of sponsorship
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      dispatch({ type: "STOP_LOADING" })
      return {
        success: true,
        message: "Test YouTube sponsor alert sent successfully!",
      }
    } catch (error) {
      dispatch({ type: "STOP_LOADING" })
      console.error("Error sending test sponsor alert:", error)
      return { success: false, message: "Failed to send YouTube sponsor alert" }
    }
  }

  return {
    sendFakeTwitchSubscription,
    sendFakeTwitchBits,
    sendFakeYouTubeSuperChat,
    sendFakeTikTokGift,
    sendFakeYouTubeSponsor,
    sendFakeTikTokSubscriber,
    sendFakeTwitchChannelPoints,
    sendFakeTwitchCommercial,
    sendFakeTwitchFollow,
    unlinkStreamerKick,
    sendFakeTikTokFollow
  }
}

export default useTestAlerts
