// src/components/privacy/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  const headingStyle = {
    marginTop: '20px',
    marginBottom: '10px',
  };

  return (
    <div style={{ whiteSpace: "pre-wrap", backgroundColor: "white", color: 'black' }}>
      <h1>Privacy Policy</h1>

      <p><strong>Last updated: 11/13/2024</strong></p>

      <p>Welcome to MinionRPG! This privacy policy outlines how I collect, use, and protect your information when you use my services and applications. This extension is created by Cowsep.</p>

      <h2 style={headingStyle}>1. Information Collection and Use</h2>
      <p>I collect information to provide and improve my services. This includes:</p>
      <ul>
        <li>Information collected through external OAuth (like Twitch)</li>
        <li>General usage data, including IP address and login details</li>
        <li>Cookies and tracking technologies for functionality and analysis</li>
      </ul>

      <h2 style={headingStyle}>2. Use of Data</h2>
      <p>I use the collected data for purposes such as:</p>
      <ul>
        <li>Providing and maintaining my services</li>
        <li>Notifying you of service changes</li>
        <li>Improving user experience and service performance</li>
        <li>Monitoring service usage and detecting issues</li>
      </ul>

      <h2 style={headingStyle}>3. Data Sharing and Disclosure</h2>
      <p>I may share your data in specific scenarios, such as:</p>
      <ul>
        <li><strong>With Service Providers:</strong> To facilitate operations</li>
        <li><strong>For Legal Requirements:</strong> To comply with legal obligations or respond to lawful requests</li>
      </ul>

      <h2 style={headingStyle}>4. Data Security</h2>
      <p>I implement security measures to protect your data but cannot guarantee absolute security due to the nature of online services.</p>

      <h2 style={headingStyle}>5. Service Providers</h2>
      <p>I may use third-party providers to enhance the service, such as for hosting or analytics.</p>

      <h2 style={headingStyle}>6. Links to Other Sites</h2>
      <p>My services may include links to third-party sites, which have their own privacy policies. I encourage you to review them.</p>

      <h2 style={headingStyle}>7. Changes to This Privacy Policy</h2>
      <p>I may update this privacy policy. You are advised to review this page periodically for any updates.</p>

      <h2 style={headingStyle}>8. Use of External API Services</h2>
      <p>By using my services, you may be subject to terms of external APIs. Please refer to their respective privacy policies for more details.</p>

      <h2 style={headingStyle}>9. Contact Me</h2>
      <p>If you have any questions or concerns about this privacy policy, please contact me at Joseph@cowsep.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
