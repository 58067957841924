import InteractiveSection from "./interactive/InteractiveSection"
import ProfileSection from "./profile/ProfileSection"

import "./InfoBox.css"

function InfoBox() {
  return (
    <div className="infoBox">
      <ProfileSection />
      <InteractiveSection />
    </div>
  )
}

export default InfoBox
