import axios from "axios"
import { EmoteMeta } from "../../providers/EmoteProvider"

interface BaseSevenTVProps {
  id: string
}

interface SevenTVEmoteData extends BaseSevenTVProps {
  name: string
  animated: boolean
  listed: boolean
  lifecycle: number
  flags: number
  state: string[]
  owner: Omit<SevenTVUser, "connections" | "emote_sets" | "created_at">
  host: { url: string; files: any[] }
}

interface SevenTVEmote extends BaseSevenTVProps {
  name: string
  data: SevenTVEmoteData
  flags: number
  actor_id: string | null
  timestamp: number
}

interface SevenTVEmoteSet extends BaseSevenTVProps {
  emotes: SevenTVEmote[]
  name: string
  capacity: number
  emote_count: number
  flags: number
  immutable: boolean
  privileged: boolean
  tags: string[]
  owner: Omit<SevenTVUser, "connections" | "emote_sets" | "created_at">
}

interface SevenTVUserConnection extends BaseSevenTVProps {
  platform: string
  username: string
  display_name: string
  emote_capacity: number
  emote_set: SevenTVEmoteSet | null
  emote_set_id: string | null
  linked_at: number
}

interface SevenTVEditor extends BaseSevenTVProps {
  permissions: number
  visible: boolean
  added_at: number
}

interface SevenTVUser extends BaseSevenTVProps {
  username: string
  display_name: string
  biography: string
  avatar_url: string
  roles: string[]
  emote_sets: SevenTVEmoteSet[]
  editors: SevenTVEditor[]
  connections: SevenTVUserConnection[]
  style: { color: number }
  created_at: number
}

interface SevenTVResponse extends SevenTVUserConnection {
  user: SevenTVUser
}

export async function getSevenTvEmotes(userId: string): Promise<EmoteMeta[]> {
  const response = await axios.get<SevenTVResponse>(
    `https://7tv.io/v3/users/twitch/${userId}`
  )

  const { emote_set } = response.data

  if (!emote_set) return []

  return emote_set.emotes.map(({ id, name }) => ({
    source: "7TV",
    id,
    name,
    url: `https://cdn.7tv.app/emote/${id}/1x.webp`,
  }))
}
