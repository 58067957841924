import { Badge, Code, DataList, Flex, IconButton, Link } from "@radix-ui/themes"
import { RxCopy } from "react-icons/rx"
import { ExtensionWithAuthor } from "../inventory/InstalledExtensions"

function ExtensionCardMetadataList({
  clientId,
  chatrpg_id,
  chatrpg_username,
  isForStreamer,
  extensionName,
  description,
  buildUrl,
  configUrl,
}: ExtensionWithAuthor) {
  return (
    <DataList.Root orientation={{ initial: "vertical", sm: "horizontal" }}>
      <DataList.Item>
        <DataList.Label>Client ID</DataList.Label>
        <DataList.Value>{clientId}</DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Features</DataList.Label>
        <DataList.Value>
          <Flex gap="1">
            {isForStreamer && (
              <Badge color="blue" variant="soft" radius="full">
                Streamer
              </Badge>
            )}
          </Flex>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>ChatRPG ID</DataList.Label>
        <DataList.Value>
          <Flex align="center" gap="2">
            <Code variant="ghost">{chatrpg_id}</Code>
            <IconButton
              size="1"
              aria-label="Copy value"
              color="gray"
              variant="ghost"
            >
              <RxCopy />
            </IconButton>
          </Flex>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>User</DataList.Label>
        <DataList.Value>{chatrpg_username}</DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Name</DataList.Label>
        <DataList.Value>{extensionName}</DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Description</DataList.Label>
        <DataList.Value>{description}</DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Build URL</DataList.Label>
        <DataList.Value>
          <Link href={buildUrl} target="_blank">
            {buildUrl}
          </Link>
        </DataList.Value>
      </DataList.Item>

      <DataList.Item>
        <DataList.Label>Config URL</DataList.Label>
        <DataList.Value>
          <Link href={buildUrl} target="_blank">
            {configUrl}
          </Link>
        </DataList.Value>
      </DataList.Item>
    </DataList.Root>
  )
}

export default ExtensionCardMetadataList
