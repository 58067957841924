import axios from "axios"
import { getUserProfileFromToken } from "components/services/GetUserProfileFromToken"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { SERVER_URL } from "../../env"
import { useToken } from "./TokenContext"

type FollowedStreamer = {
  chatrpg_id: string // Unique identifier for the followed streamer
  chatrpg_username: string // Username of the followed streamer
  streamerObjectId: string
  // Add any other relevant fields here
}

export type LinkedAccount = {
  platformUsername: string
  isStreamer: boolean
}

export interface UserSettings {
  disablePlayer: boolean
  disableSidebar: boolean
  disableEmbeds: boolean
  disableTiktokChat: boolean
  disableYoutubeChat: boolean
  disableKickChat: boolean
  disableTwitchChat: boolean
  disableChatRPGChat: boolean
  disableRotatingChatBackground: boolean
  enablePlatformChatBadges: boolean
  // Add more settings as needed
}

export type UserProfile = {
  chatrpg_username: string
  chatrpg_id: string // Add the user's ID if needed
  chatrpg_profilePicture?: string // Add the profile picture URL
  twitchScopes: string[]
  isStreamer: boolean
  isDeveloper: boolean
  administrator: boolean
  version?: string
  followedStreamers: FollowedStreamer[] // Array of followed streamers
  linkedAccounts: Record<string, LinkedAccount>
  settings: UserSettings
  premium?: boolean
}

type UserProfileContextType = {
  profile: UserProfile | null
  setProfile: Dispatch<SetStateAction<UserProfile | null>>
  profileFetched: boolean
  setProfileFetched: Dispatch<SetStateAction<boolean>>
  updateSettings: (settings: Partial<UserSettings>) => Promise<void>
  reorderStreamerBubble: (from: number, to: number) => FollowedStreamer[]
}

export type AccountLinkingStatus = "alreadyLinked" | "accountOwned" | "success"

const UserProfileContext = createContext<UserProfileContextType | null>(null)

export function useUserProfile() {
  const context = useContext(UserProfileContext)

  if (!context) {
    throw new Error("useUserProfile must be used within a UserProfileProvider")
  }

  return context
}

function reorderArray(arr: any[], from: number, to: number) {
  const clonedArr = [...arr]

  const [streamer] = clonedArr.splice(from, 1)
  clonedArr.splice(to, 0, streamer)

  return clonedArr
}

function UserProfileProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useDispatch()

  const { token, clearToken } = useToken()

  const [profile, setProfile] = useState<UserProfile | null>(null)
  const [profileFetched, setProfileFetched] = useState(false)

  useEffect(() => {
    console.log("Profile updated:", profile)
  }, [profile])

  const logout = useCallback(() => {
    console.log("Logging out your user was deleted")
    clearToken()
    setProfile(null)
    setProfileFetched(false)
  }, [clearToken, setProfile, setProfileFetched])

  const fetchAndSetProfile = useCallback(
    async (token: string) => {
      console.log("Fetching profile...")
      try {
        const fetchedProfile = await getUserProfileFromToken(token)
        if (!fetchedProfile) {
          console.log("No profile found for token:", token)
          return clearToken()
        }

        setProfile(fetchedProfile)
        setProfileFetched(true)
      } catch (error) {
        console.error("Error fetching profile:", error)
        logout() // Handle logout on error
      }
    },
    [clearToken, logout, setProfile, setProfileFetched]
  )

  const handleProfileUpdate = useCallback(async () => {
    if (!token) return navigate(location.pathname, { replace: true })

    const queryParams = new URLSearchParams(location.search)
    const status = queryParams.get("profile_updated") as AccountLinkingStatus

    switch (status) {
      case "success":
        console.log("Profile Updated + Token - Fetching and Setting Profile")
        await fetchAndSetProfile(token)
        navigate(location.pathname, { replace: true })
        break

      case "accountOwned":
      case "alreadyLinked":
        navigate(location.pathname, { replace: true, state: { status } })
        break
    }
  }, [fetchAndSetProfile, location.pathname, location.search, navigate, token])

  const updateSettings = useCallback(
    async (settings: Partial<UserSettings>) => {
      dispatch({ type: "START_LOADING" })
      const response = await axios.post(
        `${SERVER_URL}/profile/options/settings`,
        settings,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      dispatch({ type: "STOP_LOADING" })

      console.log(response.data)

      setProfile(response.data)
    },
    [dispatch, token]
  )

  const reorderStreamerBubble = useCallback(
    (from: number, to: number) => {
      if (!profile) return []

      setProfile((prev) => {
        if (!prev) return null

        return {
          ...prev,
          followedStreamers: reorderArray(prev.followedStreamers, from, to),
        }
      })

      return reorderArray(profile.followedStreamers, from, to)
    },
    [profile]
  )

  // Fetch profile if not already
  useEffect(() => {
    if (token && !profile) {
      console.log(
        "No Profile and we have a Token - Fetching and Setting Profile"
      )

      fetchAndSetProfile(token)
    }
  }, [fetchAndSetProfile, profile, token])

  // Handle any profile updates (e.g. linking)
  useEffect(() => {
    handleProfileUpdate()
  }, [handleProfileUpdate])

  const value = useMemo(
    () => ({
      profile,
      setProfile,
      profileFetched,
      setProfileFetched,
      updateSettings,
      reorderStreamerBubble,
    }),
    [profile, profileFetched, reorderStreamerBubble, updateSettings]
  )

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  )
}

export default UserProfileProvider
