import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useChatBox } from "../../contexts/ChatBoxContext"; // Import the context

const ChatBox: React.FC = () => {
    const { selectedUser, closeBox } = useChatBox(); // Use the context to get selectedUser and closeBox
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    // Handle drag events
    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setOffset({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Effect for handling dragging and releasing
    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        } else {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging]);

    // If no user is selected, don't display the box
    if (!selectedUser) return null;

    return (
        <DraggableBox style={{ left: position.x, top: position.y }}>
            <Header onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                {/* Display username with platform in parentheses */}
                <span>{selectedUser.username} ({selectedUser.platform})</span>
                <CloseButton onClick={closeBox}>X</CloseButton> {/* Proper close button */}
            </Header>
            <Content>
                {/* Conditionally render IDs if they exist */}
                {selectedUser.chatrpgId && <p>User ID: {selectedUser.chatrpgId}</p>}
                {selectedUser.twitch_id && <p>Twitch ID: {selectedUser.twitch_id}</p>}
                {/* Additional content like profile info, etc. */}
            </Content>
        </DraggableBox>
    );
};

const DraggableBox = styled.div`
  position: absolute;
  width: 250px;
  background-color: #18181b; /* Twitch dark mode background */
  border: 1px solid #3a3a3d;
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
`;

const Header = styled.div`
  background-color: #2f2f33; /* Grey background for header */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-bottom: 1px solid #3a3a3d;
  border-radius: 8px 8px 0 0;
  cursor: grab;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #b9bbbe;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const Content = styled.div`
  padding: 15px;
  color: #e3e3e3; /* Lighter text color for contrast */
  font-size: 13px;

  p {
    margin: 0;
  }
`;

export default ChatBox;
