import React, { useState } from 'react';
import './EmbeddablePlayerOptions.css';
import PlatformBar from './PlatformBar';
import { useToken } from '../../../../../contexts/TokenContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { UserStreamerProfile, setUserStreamerProfile } from '../../../../../../store/reducers/userStreamerProfileReducer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SaveButton from '../../../../../ui/button/SaveButton';
import { arraysEqual } from '../../../../utils/arrayEquals';
import { usePlatformBars } from './usePlatformBars'; // Ensure path is correct
import { usePlatformBarHandlers } from './usePlatformBarHandlers';
import { apiSavePlatformConfiguration } from './apiSaveEmbeddedPlayers';

const EmbeddablePlayerOptions = () => {
    const userStreamerProfile = useSelector((state: RootState) => state.userStreamerProfile) as UserStreamerProfile;
    const { platformBars, setPlatformBars, initialPlatformBars } = usePlatformBars(userStreamerProfile);
    const { token } = useToken();
    const dispatch = useDispatch();
    const [isChanged, setIsChanged] = useState(false);

    const { handleAddPlatform, handleRemovePlatform, handlePlatformBarUpdate } = usePlatformBarHandlers(
        platformBars, setPlatformBars, initialPlatformBars, setIsChanged
    );  

    const handleSave = async () => {
        dispatch({ type: 'START_LOADING' });
        try {
            const updatedStreamerProfile = await apiSavePlatformConfiguration(platformBars, token);
            dispatch(setUserStreamerProfile(updatedStreamerProfile));
            setIsChanged(false);
        } catch (error) {
            console.error('Error:', error);
        }
        dispatch({ type: 'STOP_LOADING' });
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(platformBars);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Ensuring userStreamerProfile and embeddedPlayers are not null/undefined
        const embeddedPlayers = userStreamerProfile?.embeddedPlayers ?? [];
        setIsChanged(!arraysEqual(items, embeddedPlayers));

        setPlatformBars([...items]); // Deep clone the array
    };

    return (
        <div className="embeddable-player-container">
            <div className="add-platform-container">
                <button className="add-platform-button" onClick={handleAddPlatform}>
                    {/* Existing SVG for Add Video to Page button */}
                    <svg width="16" height="16" viewBox="0 -1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z" fill="white" />
                    </svg>
                    Add Video to Page
                </button>
                <SaveButton onSave={handleSave} isDisabled={!isChanged || platformBars.length === 0} />
            </div>
            <DragDropContext onDragEnd={onDragEnd} key={platformBars.length}>
                <Droppable droppableId="platformBars">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="platforms-area"
                        >
                            {platformBars.map((bar, index) => (
                                <Draggable key={bar.id} draggableId={bar.id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={index === 0 ? 'platform-bar-active' : ''}
                                        >
                                            <PlatformBar
                                                initialPlatform={bar.platform}
                                                initialStreamerName={bar.username}
                                                index={index}
                                                handleRemovePlatform={handleRemovePlatform}
                                                onUpdate={handlePlatformBarUpdate}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default EmbeddablePlayerOptions;
