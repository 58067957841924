import { useCallback, useRef, useState } from "react"

interface UseDragLogicProps {
  maxDelta: number
  disable?: boolean
}

function useDragLogic({ maxDelta, disable }: UseDragLogicProps) {
  const [isDragging, setIsDragging] = useState(false)
  const [dragPosition, setDragPosition] = useState(0)
  const dragStartX = useRef(0)

  const handleDragStart = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (disable) return

      setIsDragging(true)
      dragStartX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    },
    [disable]
  )

  const handleDragMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      if (!isDragging || disable) return

      const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
      const deltaX = currentX - dragStartX.current

      setDragPosition(Math.min(Math.max(deltaX, -dragPosition), maxDelta))
    },
    [disable, dragPosition, isDragging, maxDelta]
  )

  const handleDragEnd = useCallback(() => {
    setIsDragging(false)
    setDragPosition((currPosition) => (currPosition > 36 ? maxDelta : 0))
  }, [maxDelta])

  return {
    isDragging,
    dragPosition,
    handleDragStart,
    handleDragMove,
    handleDragEnd,
  }
}

export default useDragLogic
